import React from 'react';

import Popup from '../../Popup/Popup';
import { SalleDescription } from '../../Salle';

const ResourceViewItem = (props) => {

    const {
        itemSlotName,
        itemTextSize,
        itemTextColor,
        groupOnly,
        salleDetail,
        salleActivites,
        sallesComponents,
        configDatabase,
        idEntite,
    } = props;

    const {
        capaciteAccueilAssis,
        capaciteAccueil,
        identifiant,
        description,
        heureDebut,
        heureFin,
        superficie
    } = salleDetail || {};

    const [listeActivites, setListeActivites] = React.useState()
    const [isPopupDetailOpen, setIsPopupDetailOpen] = React.useState(false);

    React.useEffect(() => {
        // On récupère tous les libellés des activités
        const allListeActivites = salleActivites.length > 0
            ? salleActivites[0].listTypesReservations.map((typeReservation) => {
                return typeReservation.libelle;
            })
            : [];

        setListeActivites(allListeActivites)
    }, [salleActivites])

    const cursor = groupOnly ? 'auto' : 'help';

    // Callback pour la gestion de la popup
    const handleOnPopupDetailOpen = React.useCallback((visible) => {
        // On n'ouvre pas la popup si on sur un group (= type de salle)
        if (!groupOnly) {
            setIsPopupDetailOpen(visible !== null ? visible : false);
        }
    }, [groupOnly]);

    return (
        <Popup
            {...groupOnly && { open: false }}
            trigger={(
                <div
                    style={{
                        textAlign: 'right',
                        fontSize: `${itemTextSize}`,
                        fontWeight: '400',
                        color: `${itemTextColor}`,
                        cursor: `${cursor}`,
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        paddingRight: groupOnly ? 0 : 10,
                        paddingLeft: 10,
                    }}
                >
                    <span>
                        <span>
                            {itemSlotName}
                        </span>
                        {sallesComponents}
                    </span>
                </div>
            )}
            content={(
                <div
                    style={{
                        width: 600,
                    }}
                >
                    <SalleDescription
                        configDatabase={configDatabase}
                        identifiant={identifiant}
                        identifiantEntite={idEntite}
                        nom={itemSlotName}
                        description={description}
                        heureDebut={heureDebut}
                        heureFin={heureFin}
                        superficie={superficie}
                        placesTotales={capaciteAccueil}
                        placesAssises={capaciteAccueilAssis}
                    />
                </div>
            )}
            position='right center'
            hoverable
            resourceViewItem
        />
    );

}

export default ResourceViewItem;