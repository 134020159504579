import React, { Component } from 'react';
import {
    DownCircleOutlined,
    UpCircleOutlined,
} from '@ant-design/icons';
import {
    COULEUR_FOND_VIOLET,
} from '../../../config/colors';
import config from './config';
import ResourceViewItem from './ResourceViewItem';

class ResourceView extends Component {
    // static propTypes = {
    //     schedulerData: PropTypes.object.isRequired,
    //     contentScrollbarHeight: PropTypes.number.isRequired,
    //     toggleRender: PropTypes.func
    // }

    render() {
        const {
            schedulerData,
            sallesList,
            sallesTypesReservationsListInfos,
            contentScrollbarHeight,
            toggleRender,
            configDatabase,
            idEntite,
        } = this.props;
        const {
            renderData,
        } = schedulerData;
        // On récupère la taille de la div de scroll pour pousser l'affichage 
        const paddingBottom = contentScrollbarHeight;
        // On construit l'affichage des données
        const displayRenderData = renderData.filter(o => o.render);
        // On construit la liste des types de salles et salles
        const resourceList = displayRenderData.map((item) => {
            // On récupère les infos de la ligne (salle ou type de salle)
            const {
                slotName,
                expanded,
                identifiantSalle,
                indent,
                groupOnly,
                hasChildren,
            } = item;
            // On récupère la salle concerné par la ou les réservations
            const salles = sallesList?.filter((salle) => {
                return identifiantSalle === salle.identifiant;
            }, []);
            // Si on a bien un tableau non vide alors on prend la première salle
            const salle = salles?.length > 0
                ? salles[0]
                : null;
            // Si on a bien une salle
            // → on parcourt le tableau contenant les salles et leur type de réservations associées (= activités)
            const salleActivites = salle !== null
                ? 
                    sallesTypesReservationsListInfos.filter((salleTypeReservationInfo) => {
                        return salleTypeReservationInfo.idSalle === salle.identifiant;
                    }, [])
                : 
                    [];

            // On pré-construit l'arborescence
            let indents = [];
            let indentComponent = null;

            // Si le type de salle a des salles associées
            if (hasChildren) {
                // Gestion du bouton déplié/replié
                indentComponent = expanded
                    ? (
                        <DownCircleOutlined
                            key={`es${item.indent}`}
                            style={{
                                // fontSize: '1.4rem',
                                marginLeft: '1rem',
                                marginRight: '0.5rem',
                                marginTop: '0.2rem',
                                marginBottom: 'auto',
                            }}
                            onClick={() => {
                                item.expanded = false;
                                displayRenderData.forEach(salle => {
                                    if (salle.parentId === item.slotId) {
                                        salle.render = false;
                                    }
                                });
                                toggleRender();
                            }}
                        />) : (
                        <UpCircleOutlined
                            key={`es${item.indent}`}
                            style={{
                                // fontSize: '1.4rem',
                                marginLeft: '2rem',
                                marginRight: '0.5rem',
                                marginTop: '0.2rem',
                            }}
                            onClick={() => {
                                item.expanded = true;
                                renderData.forEach(salle => {
                                    if (salle.parentId === item.slotId) {
                                        salle.render = true;
                                    }
                                });
                                toggleRender();
                            }}
                        />
                    );
            }
            indents.push(indentComponent);
            /** On détermine la couleur du texte */
            // Si on est un groupe de salles (type de salle), sinon est est sur une salle
            const slotTextColor = groupOnly
                ? 'white'
                : COULEUR_FOND_VIOLET;
            /** On détermine la taille du texte */
            // Si on est un groupe de salles (type de salle), sinon est est sur une salle
            const slotTextSize = groupOnly
                ? '1.2rem'
                : '1.1rem';

            const slotItem = (
                <ResourceViewItem
                    configDatabase={configDatabase}
                    idEntite={idEntite}
                    itemSlotName={slotName}
                    itemTextSize={slotTextSize}
                    itemTextColor={slotTextColor}
                    groupOnly={groupOnly}
                    salleDetail={salle}
                    salleActivites={salleActivites}
                    sallesComponents={indents}
                />
            );
            // On prépare le style de la cellule
            let tdStyle = {
                //Permet d'adapter la taille en fonction du nombre d'event
                //height: item.rowHeight, 
                // → Désormais on souhaite 1 seul event peut importe le nombre
                height: config.resourceItemLineHeight
            };
            // Si on est sur un groupe (= type de salle)
            if (groupOnly) {
                tdStyle = {
                    ...tdStyle,
                    backgroundColor: item.color !== undefined && item.color !== ''
                        ? `${item.color}`
                        : schedulerData.config.groupOnlySlotColor,
                };
            }
            // On renvoie la ligne de type de salle ou de salle
            return (
                <tr
                    key={item.slotId}
                >
                    <td
                        data-resource-id={item.slotId}
                        style={tdStyle}
                    >
                        {slotItem}
                    </td>
                </tr>
            );
        });

        // On renvoie la liste des salles groupées par type de salle et pliables/dépliables
        return (
            <div
                style={{
                    paddingBottom: paddingBottom,
                }}
            >
                <table
                    className="resource-table"
                    style={{
                        tableLayout: 'fixed'
                    }}
                >
                    <tbody>
                        {resourceList}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ResourceView;