import _ from 'lodash';
import React from 'react';

import {
    Dimmer,
    Label,
    Loader,
    Ref,
    Table
} from 'semantic-ui-react';

import {
    COULEUR_VERT,
    COULEUR_VERT_CLAIR,
    COULEUR_BLEU,
    COULEUR_BLEU_FONCE,
    COULEUR_BLEU_CLAIR,
} from '../../config/colors';
import { SalleDescription } from '../Salle';
import Popup from '../Popup/Popup';

// Tableau contenant les couleurs du planning
const colors = [
    COULEUR_VERT_CLAIR,
    COULEUR_VERT,
    COULEUR_BLEU,
    COULEUR_BLEU_FONCE,
    COULEUR_BLEU_CLAIR,
];

const ModalDisponibiliteResultsTable = (props) => {
    const {
        configDatabase,
        identifiantEntite,
        loading,
        onSelect,
        salles,
        typesSalle,
    } = props;
    const { webdev } = window;

    const [typesSalleColors, setTypesSalleColors] = React.useState([]);
    const [contextPopup, setContextPopup] = React.useState(null);

    const handleRef = (node) => {
        setContextPopup(node);
    }

    const handleSalleClick = (id) => () => {
        if (onSelect) {
            onSelect(id);
        }
    }

    // Fonction qui permet d'associer les types de salles et les couleurs
    React.useEffect(() => {
        setTypesSalleColors(_.map(typesSalle, ({ identifiant: identifiantTypesSalle }, index) => ({
            identifiantTypesSalle,
            color: colors[index % colors.length],
        })));
    }, [typesSalle]);

    if (loading) {
        return (
            <>
                <div style={{ textAlign: 'right', paddingBottom: 10 }}>
                    <label>
                        {webdev
                            ? 'Sélectionner une salle pour ajouter une réservation'
                            : 'Sélectionner une salle pour ajouter une pré-réservation'
                        }
                    </label>
                </div>
                <div>
                    <Table
                        attached="top"
                        verticalAlign='middle'
                        size='small'
                        style={{ borderBottom: "none" }}
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={9}>
                                    Salles disponibles
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3}>
                                    Type de salle
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={1}>
                                    Capacité
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3}>
                                    Places assises
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                    </Table>
                    <Dimmer.Dimmable
                        as={Table}
                        attached="bottom"
                        verticalAlign='middle'
                        size='small'
                        selectable
                    >
                        <Table.Body>
                            <Table.Row verticalAlign='middle' style={{ height: '80px' }}>
                                <Table.Cell collapsing>
                                    <Dimmer inverted active>
                                        <Loader><label>Chargement...</label></Loader>
                                    </Dimmer>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Dimmer.Dimmable>
                </div>
            </>
        )
    }

    return (
        <>
            {!_.isEmpty(salles) && (
                <div style={{ textAlign: 'right', paddingBottom: 10 }}>
                    <label>
                        Sélectionner une salle pour ajouter une pré-réservation
                    </label>
                </div>
            )}
            <Ref innerRef={handleRef}>
                <div className='scrollableTable'>
                    <Table
                        verticalAlign='middle'
                        size='small'
                        selectable={!_.isEmpty(salles)}
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Salles disponibles
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3}>
                                    Type de salle
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={1}>
                                    Capacité
                                </Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' width={3}>
                                    Places assises
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {_.isEmpty(salles) && (
                                <Table.Row
                                    key={'emptyRow'}
                                    verticalAlign='middle'
                                    style={{ height: 30 }}
                                >
                                    <Table.Cell style={{ fontWeight: 'bold' }}>
                                        Aucune salle ne correspond aux critères sélectionnés
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            {_.map(salles, (salle) => {
                                const {
                                    capaciteAccueil,
                                    capaciteAccueilAssis,
                                    description,
                                    heureDebut,
                                    heureFin,
                                    identifiant,
                                    identifiantTypesSalle,
                                    nomSalle,
                                    superficie,
                                } = salle;

                                const type = _.find(typesSalle, ({ identifiant: id }) => id === identifiantTypesSalle)?.libelle;
                                const color = _.find(typesSalleColors, (typeColor) => typeColor.identifiantTypesSalle === identifiantTypesSalle)?.color;

                                const row = (
                                    <Table.Row
                                        onClick={handleSalleClick(identifiant)}
                                        key={'salle'+identifiant}
                                        verticalAlign='middle'
                                    >
                                        <Table.Cell style={{ fontWeight: 'bold' }}>
                                            {nomSalle}
                                        </Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            <Label
                                                className='typeSalleLabel'
                                                content={type}
                                                style={{
                                                    backgroundColor: `${color}`,
                                                }}
                                            />
                                        </Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            {capaciteAccueil || '-'}
                                        </Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            {capaciteAccueilAssis || '-'}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                                
                                return (
                                    <Popup
                                        style={{ width: 600 }}
                                        content={(
                                            <SalleDescription
                                                identifiant={identifiant}
                                                configDatabase={configDatabase}
                                                identifiantEntite={identifiantEntite}
                                                download={false}
                                                description={description}
                                                heureDebut={heureDebut}
                                                heureFin={heureFin}
                                                superficie={superficie}
                                                placesTotales={capaciteAccueil}
                                                placesAssises={capaciteAccueilAssis}
                                            />
                                        )}
                                        trigger={row}
                                        flowing
                                        hoverable
                                        pinned
                                        position='top center'
                                        basic
                                        mouseEnterDelay={200}
                                        mouseLeaveDelay={200}
                                        context={contextPopup}
                                    />
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </Ref>
        </>
    );
};

export default ModalDisponibiliteResultsTable;