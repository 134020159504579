//import './wdyr';  // Debug render react 
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import 'react-dates/initialize';

import { ToastProvider } from 'react-toast-notifications';

import App from './App';
// import ExempleListView from './ExempleListView';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

// import './styleWrapper.less';
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastProvider portalTargetSelector='#JVS_publication_ligne'>
        <App />
      </ToastProvider>
      {/* <ExempleListView /> */}
    </React.StrictMode>
  </Provider>,
  document.getElementById('globalContainer')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
