import Axios from 'axios';
import {
    GET_ALL_TYPES_RESERVATIONS
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getAllTypesReservations = (idEntite, config) => (dispatch) => {
    Axios.get(URL_API + '/api/typeReservation?perPage=50&idEntite='+idEntite)
    .then(( data ) => {
        dispatch({
            type: GET_ALL_TYPES_RESERVATIONS,
            typesReservationsList: data,
        });
    });
};