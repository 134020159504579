import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import Modal from '../Modal/Modal';
import Button from '../Button';
import ModalDisponibiliteContent from './ModalDisponibiliteContent';

const ModalDisponibilite = (props) => {
    const {
        callbackOpenModal,
        callbackAddNewEvent,
        onClose,
        schedulerData,
        ...rest
    } = props;
    

    const handleClose = () => {
        if (onClose) {
            onClose()
        }
    }

    const handleSalleSelect = (idSalle, debut, fin, sallesList) => {
        const { webdev } = window;

        if (webdev) {
            const salle = _.find(sallesList, ({ identifiant }) => identifiant === idSalle);

            vAjouteReservation( // eslint-disable-line
                moment(debut).format('YYYY-MM-DD HH:mm'),
                moment(fin).format('YYYY-MM-DD HH:mm'),
                salle,
                true
            );
        } else {
            callbackOpenModal(true) // Ouverture du modal
            callbackAddNewEvent(
                schedulerData,
                moment(debut).format('YYYY-MM-DD HH:mm'),
                moment(fin).format('YYYY-MM-DD HH:mm'),
                idSalle,
                true,
            );
        }

        if (onClose) {
            onClose()
        }
    }

    return (
        <Modal
            className='modalGDS'
            open
            onClose={handleClose}
            closeIcon={(
                <Button
                    className='modalExitButton'
                    size='tiny'
                    circular
                    icon='close'
                />
            )}
        >
            <ModalDisponibiliteContent
                onSalleSelect={handleSalleSelect}
                schedulerData={schedulerData}
                {...rest}
            />
        </Modal>
    );
};

ModalDisponibilite.defaultProps = {
    open: true,
}

export default ModalDisponibilite;
