import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import Types from '360-types';

import { Dropdown } from 'semantic-ui-react';

const CalendarDateSelector = (props) => {
    const {
        month,
        onYearSelect
    } = props;

    // cette année + 10 ans
    const optionsYears = _.times(11, (iterate) => {
        const year = moment().add(iterate, 'year');
        
        return {
            key: year.format('YYYY'),
            text: year.format('YYYY'),
            value: year.year(),
        }
    });

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: -26
        }}>
            <div style={{ width: '100%',}}>
                <div style={{ transform: 'translateX(50%)'}}>
                    <Dropdown
                        className='YearPicker'
                        inline
                        scrolling
                        options={!_.isNil(_.find(optionsYears, ({ value }) => value === month.year()))
                            ? optionsYears
                            : [
                                {
                                    key: 'null',
                                    value: month.year(),
                                    text: month.year(),
                                },
                                ...optionsYears
                            ]
                        }
                        onChange={(e, data) => { onYearSelect(month, data.value); }}
                        value={month.year()}
                        style={{ transform: 'translateX(25%)'}}
                    />
                </div>
                <label>{month.format('MMMM')}</label>
            </div>
            <div style={{ width: '100%' }}>
                <ul
                    className="DayPicker_weekHeader_ul"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        color: '#757575',
                    }}
                >
                    {moment.weekdaysMin(true).map(day => (
                        <li key={day} className="DayPicker_weekHeader_li">
                            {day}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

CalendarDateSelector.propTypes = {
    /** Moment object */
    month: Types.object,

    /** */
    onYearSelect: Types.func,
}

export default CalendarDateSelector;
