import Axios from 'axios';
import { GET_PARAM_ENTITE } from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getParamEntite = (idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/parametres/entite/' + idEntite).then(({ data }) => {
        dispatch({
            type: GET_PARAM_ENTITE,
            param: data[0],
        });
    });
};
