import React from 'react';

import Modal from '../Modal/Modal';
import Button from '../Button';
import DocumentsContent from './DocumentsContent';

const Documents = (props) => {
    const {
        openModalDocuments,
        setOpenModalDocuments,
        ...rest
    } = props;

    // Gestion du clic sur le bouton ferme ("X") et en dehors de la modal
    const handleModalClose = () => {
        setOpenModalDocuments(false);
    }

    if (!openModalDocuments) {
        return null;
    }

    return (
        <Modal
            open
            onClose={handleModalClose}
            className='modalGDS'
            size='tiny'
            closeIcon={
                <Button
                    className='modalExitButton'
                    size='tiny'
                    circular
                    icon='close'
                />
            }
        >
            <DocumentsContent {...rest} />
        </Modal>
    );
}

export default Documents;
