import {
    ADD_PLANNING,
    GET_RANGE_PLANNINGS,
} from '../constants/actionsTypes';

const initialState = {
    loadedPlannings: null,
    searchedPlannings: [],
    currentPage: 0,
    lastPage: 0,
};

export default function planningsReducer(state = initialState, action) {

    switch (action.type) {

        case ADD_PLANNING:
            return {
                ...state,
                loadedPlannings: [...state.loadedPlannings, action.addedReservation]
            };

        case GET_RANGE_PLANNINGS:
            let reservations = state.loadedPlannings;

            // Si on en a déjà de chargé, on rajoute uniquement ceux qui ne sont pas encore présent dans le state.
            // Sinon on renvoit ce qu'on vient de recevoir par API
            if (reservations) {
                const idLoadedPlannings = reservations.map(res => res.identifiant)

                reservations = [
                    ...reservations,
                    ...action.loadedPlannings.filter(({ identifiant }) => !idLoadedPlannings.includes(identifiant))
                ];
            } else {
                reservations = action.loadedPlannings
            }

            return {
                ...state,
                loadedPlannings: reservations,
                searchedPlannings: action.loadedPlannings
            };
            
        default:
            return state;
    }
}
