import {
    GET_PARAM_ENTITE,
} from '../constants/actionsTypes';

const initialState = {
    param: [],
    currentPage: 0,
    lastPage: 0,
};

export default function paramReducer(state = initialState, action) {
    
    switch (action.type) {
        case GET_PARAM_ENTITE:
            return {
                ...state,
                param: action.param,
            };
        default:
            return state;
    }
}
