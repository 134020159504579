import Axios from 'axios';
import {
    ADD_PLANNING,
    GET_RANGE_PLANNINGS
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const addPlanning = (reservation) => async (dispatch) => {
    const data = await Axios.post(URL_API + '/api/plannings', reservation);

    dispatch({
        type: ADD_PLANNING,
        addedReservation: data?.data, 
    });

    return data?.data;
};

export const getRangePlannings = (start, end, idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/plannings/range/start/' + start + '/end/' + end + '?idEntite=' + idEntite)
    .then(({data}) => {
        dispatch({
            type: GET_RANGE_PLANNINGS,
            loadedPlannings: data,
            searchedPlannings: data
        })
    })
}