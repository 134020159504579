import Axios from 'axios';
import {
    GET_STATUT_WEB_EN_ATTENTE
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getStatutWebEnAttente = (idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/statuts/webEnAttente?idEntite='+idEntite)
    .then(( { data } ) => {
        dispatch({
            type: GET_STATUT_WEB_EN_ATTENTE,
            statutWebEnAttente: data,
        });
    });
};