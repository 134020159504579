import _ from 'lodash';
import React, { Component } from 'react'
import moment from 'moment'

import { PropTypes } from 'prop-types'
import { isSameDay } from 'react-dates';

import ViewTypes from './ViewTypes';

class BodyView extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
    }

    render() {
        const {
            heureDebutDefaut,
            heureFinDefaut,
            schedulerData,
        } = this.props;
        
        const {
            renderData,
            headers,
            config,
            behaviors,
            resources,
            viewType,
        } = schedulerData || {};
        const {
            eventItemLineHeight,
            groupOnlySlotColor,
            nonWorkingTimeBodyBgColor,
        } = config || {};
        const { webdev } = window;

        const headersLength = headers?.length;
        const displayRenderData = renderData.filter(o => o.render);

        // On parcourt les lignes des données du planning
        const tableRows = displayRenderData.map((item, rowIndex) => {
            const {
                color,
                slotId,
                groupOnly,
            } = item || {};
            const rowCells = headers.map((header, cellIndex) => {
                const {
                    time,
                    nonWorkingTime,
                } = header;
                const key = `${slotId}_${time}`;
                let cellStyle = rowIndex + 1 === _.size(displayRenderData) && cellIndex + 1 === headersLength
                    ? { borderBottomRightRadius: 15 }
                    : {};

                // Si on est sur une date où on ne pas réserver (= cellule grisée)
                if (!webdev && !!nonWorkingTime) {
                    cellStyle = {
                        ...cellStyle,
                        backgroundColor: nonWorkingTimeBodyBgColor,
                    };
                }

                // Si on est sur une ligne de groupe (= type de salle)
                if (groupOnly) {
                    cellStyle = {
                        ...cellStyle,
                        backgroundColor: color !== undefined && color !== ''
                            ? color
                            : groupOnlySlotColor,
                    };
                }

                if (!!behaviors.getNonAgendaViewBodyCellBgColorFunc) {
                    const cellBgColor = behaviors.getNonAgendaViewBodyCellBgColorFunc(schedulerData, slotId, header);
                    if (!!cellBgColor)
                        cellStyle = {
                            ...cellStyle,
                            backgroundColor: cellBgColor,
                        };
                }

                resources.forEach(salle => {
                    if (salle.id === slotId) {
                        const {
                            heureDebut,
                            heureFin,
                            dateMaximum,
                            finDelai,
                            groupOnly,
                        } = salle;

                        if (!groupOnly) {
                            const hDeb = !_.isEmpty(heureDebut)
                                ? heureDebut
                                : !_.isEmpty(heureDebutDefaut)
                                    ? heureDebutDefaut
                                    : null;
                            const hFin = !_.isEmpty(heureFin)
                                ? heureFin
                                : !_.isEmpty(heureFinDefaut)
                                    ? heureFinDefaut
                                    : null;

                            let outOfBounds = false;
                            let isCreneauActuelDepasse = false;

                            switch (viewType) {
                                case ViewTypes.Week:
                                    // Dernier créneau dispo : 23/03/2021 de 12h à 0h
                                    isCreneauActuelDepasse = moment(time).add(12, 'hours').isBefore(finDelai);
                                    
                                    if (!_.isEmpty(hFin)) {
                                        const dateHeureFin = moment(moment().set({
                                            hour: _.toFinite(hFin.substring(0, 2)),
                                            minute: _.toFinite(hFin.substring(2)),
                                            second: 0,
                                        }).toDate());
                                        
                                        if (isSameDay(moment(), moment(time))) {
                                            outOfBounds = moment().isAfter(dateHeureFin, 'minute');
                                        }
                                    }

                                    break;
                                case ViewTypes.Day:
                                    // Dernier créneau dispo : 23/03/2021 de 15h30 à 16h
                                    isCreneauActuelDepasse = moment(time).add(30, 'minutes').isBefore(finDelai);
                                    
                                    if (!_.isEmpty(hDeb)) {
                                        let dateHeureDebut = moment(moment().set({
                                            hour: _.toFinite(hDeb.substring(0, 2)),
                                            minute: _.toFinite(hDeb.substring(2)),
                                            second: 0,
                                        }).toDate());

                                        if (!isSameDay(moment(time), dateHeureDebut)) {   
                                            dateHeureDebut = moment(dateHeureDebut.set({
                                                date: moment(time).date(),
                                                month: moment(time).get('month'),
                                                year: moment(time).get('year'),
                                            }).toDate());
                                        }

                                        outOfBounds = dateHeureDebut.isAfter(moment(time), 'minute');
                                    }
                                    
                                    if (!_.isEmpty(hFin)) {
                                        let dateHeureFin = moment(moment().set({
                                            hour: _.toFinite(hFin.substring(0, 2)),
                                            minute: _.toFinite(hFin.substring(2)),
                                            second: 0,
                                        }).toDate());
                                        
                                        // Si c'est pas aujourd'hui
                                        if (!isSameDay(moment(time), dateHeureFin)) {
                                            dateHeureFin = moment(dateHeureFin.set({
                                                date: moment(time).date(),
                                                month: moment(time).month(),
                                                year: moment(time).year(),
                                            }).toDate());
                                        }

                                        outOfBounds = outOfBounds || moment(time).isSameOrAfter(dateHeureFin, 'minute');
                                    }

                                    break;
                                case ViewTypes.Month:
                                    // Dernier créneau dispo : du 23/03/2021 au 24/03/2021
                                    isCreneauActuelDepasse = moment(time).add(1, 'day').isBefore(finDelai);
                                    
                                    if (!_.isEmpty(hFin)) {
                                        const dateHeureFin = moment(moment().set({
                                            hour: _.toFinite(hFin.substring(0, 2)),
                                            minute: _.toFinite(hFin.substring(2)),
                                            second: 0,
                                        }).toDate());
                                        
                                        if (isSameDay(moment(), moment(time))) {
                                            outOfBounds = moment().isAfter(dateHeureFin, 'minute');
                                        }
                                    }

                                    break;
                                default:
                                    break;
                            }

                            // Le créneau est valide si on est avant la fin de délai ET que le créneau actuel
                            const isCreneauValide = moment(time).isBefore(finDelai) && isCreneauActuelDepasse;

                            // Le créneau ne doit pas être visible s'il est supérieur/égal à la date max de réservations (si définie)
                            let isCreneauVisible = false;

                            if (dateMaximum !== undefined) {
                                isCreneauVisible = moment(time).isAfter(dateMaximum) || moment(time).isSame(dateMaximum, 'day');
                            }

                            const isCreneauNonReservable = isCreneauValide || isCreneauVisible;

                            // Si on est pas sur une ligne de type de salle et qu'il s'agit d'alors créneau non réservable
                            if (!webdev && (isCreneauNonReservable || outOfBounds)) {
                                // Alors on change grise le fond
                                cellStyle = {
                                    ...cellStyle,
                                    backgroundColor: '#C0C0C0',
                                };
                            }
                        }

                        item.finDelai = finDelai;
                    }
                });
                return (
                    <td
                        key={key}
                        style={cellStyle}
                    >
                        <div
                            className={'bodyCell'}
                        />
                    </td>
                );
            });
            return (
                <tr
                    key={slotId}
                    style={{
                        height: eventItemLineHeight + 2
                    }}
                >
                    {rowCells}
                </tr>
            );
        });

        return (
            <tbody>
                {tableRows}
            </tbody >
        );
    }
}

export default BodyView;
