import {
    GET_ALL_TYPE_RESERVANT
} from '../constants/actionsTypes';

const initialState = {
    typeReservantList: [],
    currentPage: 0,
    lastPage: 0,
};

export default function typeReservantReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_TYPE_RESERVANT:
            return {
                ...state,
                typeReservantList: action.typeReservantList.data,
            };
        default:
            return state;
    }
}
