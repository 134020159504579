import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import Axios from 'axios';

import {
    Container,
    Modal,
    Icon,
    Step,
    Form,
    Checkbox,
    Header,
    Divider,
} from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

import Button from '../Button';
import ModalPreReservationDemande from './ModalPreReservationDemande';
import ModalPreReservationCoordonnees from './ModalPreReservationCoordonnees';
import ModalPreReservationResume from './ModalPreReservationResume';
import ModalPreReservationFinalMessage from './ModalPreReservationFinalMessage';
import Captcha from '../Captcha';
import { ReactComponent as ReserverWhiteSvg } from '../../assets/reserverWhite.svg';
import { URL_API } from '../../config/url_api';

const optionsCivilite = [
    {
        value: 0,
        text: ''
    },
    {
        value: 1,
        text: 'Mademoiselle',
        short: 'Mlle'
    },
    {
        value: 2,
        text: 'Madame',
        short: 'Mme'
    },
    {
        value: 3,
        text: 'Monsieur',
        short: 'M.'
    }
];

const ModalPreReservationContent = (props) => {
    const {
        allTypeReservantSalle,
        schedulerData,
        configDatabase,
        endDate: endDateProps,
        getAllTypeReservant,
        getRangePlannings,
        getTypeReservantBySalle,
        getSalleByTypeReservant,
        getSalleById,
        idEntite,
        identifiantSalle: identifiantSalleProps,
        onValidate,
        salleByTypeReservant,
        sallesList,
        salleById,
        searchedPlannings,
        setOpenModal,
        startDate: startDateProps,
        typeReservantList,
        typeReservantBySalle,
    } = props;

    const { addToast } = useToasts();

    /** Récupération des informations du cookie */
    const cookieReservant = JSON.parse(localStorage.getItem('reservant'));
    const {
        rememberMe: rememberMeCookie,
        typeReservant: typeReservantCookie,
        isOrganisationSelected: isOrganisationSelectedCookie,
        nomOrganisation: nomOrganisationCookie,
        civilite: civiliteCookie,
        nom: nomCookie,
        prenom: prenomCookie,
        houseNumber: houseNumberCookie,
        lettre: lettreCookie,
        numComplement: numComplementCookie,
        street: streetCookie,
        postCode: postCodeCookie,
        city: cityCookie,
        phoneNumber: phoneNumberCookie,
        email: emailCookie,
    } = cookieReservant || {};

    /** champs de la modal */
    // étape 1
    const [prereservation, setPrereservation] = React.useState({
        // Etape 1 :
        startDate: null,
        endDate: null,
        resourceId: 0,
        typeReservant: typeReservantCookie || 0,
        // Etape 2 :
        nomOrganisation: nomOrganisationCookie || '',
        civilite: civiliteCookie || 0,
        nom: nomCookie || '',
        prenom: prenomCookie || '',
        adresse: (
            (houseNumberCookie ? houseNumberCookie + ' ' : '')
            + (lettreCookie ? lettreCookie + ' ' : '')
            + (numComplementCookie ? numComplementCookie + ' ' : '')
            + (streetCookie ? streetCookie + ' ' : '')
            + (postCodeCookie ? postCodeCookie + ' ' : '')
            + (cityCookie || '')
        ) || '',
        houseNumber: houseNumberCookie || '',
        lettre: lettreCookie || '',
        numComplement: numComplementCookie || '',
        street: streetCookie || '',
        postCode: postCodeCookie || '',
        city: cityCookie || '',
        phoneNumber: phoneNumberCookie || '',
        email: emailCookie || '',
        emailValidation: emailCookie || '',
        infosComplementaires: ''
    });
    // défini dans l'étape 1 mais sert dans la 2
    const [isOrganisationSelected, setIsOrganisationSelected] = React.useState(isOrganisationSelectedCookie);
    // Variable qui permet d'afficher ou non les actions en fin de modal
    const [etape, setEtape] = React.useState(1);
    /* Autres */
    const [captchaMatch, setCaptchaMatch] = React.useState(false);
    const [displayCreneauDispo, setDisplayCreneauDispo] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [actionsVisible, setActionsVisible] = React.useState(rememberMeCookie || false);
    const [rememberMe, setRememberMe] = React.useState(rememberMeCookie);
    // Variables pour la gestion de l'envoi de la réservation
    const [isPending, setIsPending] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isReservationError, setIsReservationError] = React.useState(false);

    const {
        startDate: debut,
        endDate: fin,
        resourceId: identifiantSalle,
        typeReservant,
        nomOrganisation,
        civilite,
        nom,
        prenom,
        adresse,
        houseNumber,
        lettre,
        numComplement,
        street,
        postCode,
        city,
        phoneNumber,
        email,
        emailValidation,
        infosComplementaires,
    } = prereservation;

    const { nomSalle } = sallesList && identifiantSalle && sallesList.length > 0 && identifiantSalle !== 0
        ? sallesList.find(({ identifiant }) => identifiant === identifiantSalle)
        : {};

    const handleChange = (name, value) => {
        if (error !== 'Ce créneau horaire est indisponible pour cette salle') {
            setError(null);
        }
        setActionsVisible(true);

        if (name === 'isOrganisation') {
            setIsOrganisationSelected(value);
        } else {
            let snowball = {};

            // si on modifie le type de reservant, on vide tous les champs du cookie
            // on part du principe que c'est quelque chose de complètement différent
            if (name === 'typeReservant') {
                snowball = {
                    civilite: '',
                    nom: '',
                    prenom: '',
                    nomOrganisation: '',
                    adresse: '',
                    houseNumber: '',
                    lettre: '',
                    numComplement: '',
                    street: '',
                    postCode: '',
                    city: '',
                    phoneNumber: '',
                    email: '',
                    emailValidation: '',
                }
            }

            // si on modifie le nom de l'organisation, on RAZ les champs d'un particulier
            if (name === 'nomOrganisation') {
                snowball = {
                    civilite: '',
                    nom: '',
                    prenom: '',
                }
            }

            // Si on modifie les champs d'un particulier, on RAZ le nom de l'organisation
            if (['civilite', 'nom', 'prenom'].includes(name)) {
                snowball = {
                    nomOrganisation: '',
                }
            }

            setPrereservation((prevState) => ({
                ...prevState,
                ...snowball,
                [name]: value,
            }));
        }
    }

    const handleEnvoyerClick = async () => {
        if (!captchaMatch) {
            setError('Le captcha est incorrect');

            return null;
        }

        if (onValidate) {
            setIsPending(true);
            setIsLoading(true);
            onValidate(prereservation, rememberMe, isOrganisationSelected)
                .then((data) => {
                    setIsLoading(false);
                    
                    if (data) {
                        // La création s'est bien passé, on envoi les accusés de réceptions
                        Axios.post(URL_API + '/api/plannings/'+data.identifiant+'/accuse-reception')
                            .then(() => {
                                addToast(
                                    <Header as='h4'>
                                        Nous avons bien réceptionné votre demande de réservation de la salle
                                        <Divider style={{ marginBottom: '0.5rem' }} />
                                        <Header.Subheader style={{ textAlign: 'justify' }}>
                                            Un mail précisant l'acceptation ou le refus de votre demande vous sera envoyé dès son traitement par nos services
                                        </Header.Subheader>
                                    </Header>,
                                    { appearance: 'success', autoDismiss: true, autoDismissTimeout: 15000 }
                                );

                                setIsReservationError(false);
                                setOpenModal(false);
                            }).catch(() => {
                                // Problème lors des AR
                                // On informe quand même que la création de la pré-réservation s'est bien passée
                                addToast(
                                    <Header as='h4'>
                                        Nous avons bien réceptionné votre demande de réservation de la salle
                                    </Header>,
                                    { appearance: 'success', autoDismiss: true, autoDismissTimeout: 15000 }
                                );

                                // Mail problème lors de l'envoi des AR
                                addToast(
                                    <Header as='h4'>
                                        Une erreur est survenue lors de l'envoi des accusés de réception
                                    </Header>,
                                    { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 15000 }
                                );
                                
                                setIsReservationError(false);
                                setOpenModal(false);
                            })                        
                    } else {
                        setIsReservationError(true);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setIsReservationError(true);
                });
        }
    }

    const handlePreviousClick = () => {
        setError(null);

        if (etape === 3) {
            setEtape(2)
        } else {
            if (etape === 2) {
                setEtape(1)
            }
        }
    }

    const handleNextClick = () => {
        switch (etape) {
            case 1:
                if (typeReservant === null || typeReservant === 0) {
                    setError('Le type de réservant doit être défini');
                    break;
                }

                if (_.isNil(debut)) {
                    setError('La date de début doit être renseignée');
                    break;
                }

                if (_.isNil(fin)) {
                    setError('La date de fin doit être renseignée');
                    break;
                }

                if (identifiantSalle === null || identifiantSalle === 0) {
                    setError('Une salle doit être définie');
                    break;
                }

                // si pas de problème, go etape 2
                setError(null);
                setEtape(2);
                break;

            case 2:
                /* champs obligatoires */
                if (isOrganisationSelected) {
                    if (nomOrganisation === null || nomOrganisation === '') {
                        setError("Le nom de l'organisation doit être défini");
                        break;
                    }
                } else {
                    if (civilite === null || civilite === 0) {
                        setError('La civilité doit être définie');
                        break;
                    }

                    if (nom === null || nom === '') {
                        setError('Le nom doit être défini');
                        break;
                    }

                    if (prenom === null || prenom === '') {
                        setError('Le prénom doit être défini');
                        break;
                    }
                }

                if (adresse === null || adresse === '') {
                    setError("L'adresse doit être définie");
                    break;
                }

                if (phoneNumber === null || phoneNumber === '') {
                    setError("Le numéro de téléphone doit être défini");
                    break;
                }

                if (email === null || email === '') {
                    setError("Le courriel doit être défini");
                    break;
                }

                if (isCourrielInvalid(email)) {
                    setError("Le courriel saisi est invalide");
                    break;
                }

                if (emailValidation === null || emailValidation === '') {
                    setError("La vérification du courriel a échoué");
                    break;
                }
                /* fin champs obligatoires */

                /* controles métiers */
                if (isNaN(houseNumber)) {
                    setError("Le numéro de l'adresse est incorrect");
                    break;
                }

                if (isNaN(postCode) || postCode.length > 5) {
                    setError('Le code postal est incorrect');
                    break;
                }

                if (isTelephoneInvalid(phoneNumber)) {
                    setError("Le numéro de téléphone est incorrect");
                    break;
                }

                if (email !== emailValidation) {
                    setError("Les deux courriels ne correspondent pas");
                    break;
                }

                // si pas de problème, go etape 3
                setError(null);
                setEtape(3);
                break;

            default:
                break;
        }
    }

    const handleCaptchaChange = React.useCallback((captcha, value) => {
        if (process.env.NODE_ENV === 'development') {
            setError(null);
            setCaptchaMatch(true);
        } else {
            setError(null);
            setCaptchaMatch(captcha === value);
        }

    }, []);

    /** USEEFFECT qui permet d'indiquer si le créneau sélectionné est diponible ou non */
    React.useEffect(() => {
        /** On récupère les informations de la salle */
        const { identifiant } = salleById || {};

        // On s'assure qu'on est bien synchro entre le formulaire et ce qu'on a en mémoire
        if (identifiant === identifiantSalle) {
            let salle = null;

            if (schedulerData && schedulerData.resources) {
                salle = schedulerData.resources.find(x => x.id === identifiant);
            }

            /** On récupère le delai de réservation */
            const finDelai = salle?.finDelai
            const dateMax = salle?.dateMaximum;

            let isDispo = true

            searchedPlannings.forEach(event => {
                const {
                    identifiantSalles,
                    dateDebut,
                    dateFin
                } = event

                const eventDebut = moment(dateDebut.split('Z')[0]);
                const eventFin = moment(dateFin.split('Z')[0]);
                const start = moment(debut);
                const end = moment(fin);
    
                if (identifiantSalles === identifiantSalle) {
                    // voir stopDrag() dans ResourceEvents pour détail
                    if ((start >= eventDebut && start < eventFin)
                        || (end > eventDebut && end <= eventFin)
                        || (eventDebut >= start && eventDebut < end)
                        || (eventFin > start && eventFin <= end)
                    ) {
                        isDispo = false;
                    }
                }
            })
    
            /** On indique non, si il y a déjà une résa ou si l'un des champs n'est pas renseigné */
            if(_.isNil(debut) || _.isNil(fin) || identifiantSalle === 0) {
                setDisplayCreneauDispo(false);
            } else {
                if (!isDispo || (!_.isNil(dateMax) && fin > dateMax) || (!_.isNil(finDelai) && debut < finDelai)) {
                    setDisplayCreneauDispo(false);
                    setError('Ce créneau horaire est indisponible pour cette salle');
                } else {
                    setDisplayCreneauDispo(true)
                    setError(null);
                }
            }
        }
    }, [
        salleById,
        searchedPlannings,
        schedulerData,
        debut,
        fin,
        identifiantSalle,
    ]);

    React.useEffect(() => {
        if (!_.isNil(startDateProps)) {
            setPrereservation(prev => ({
                ...prev,
                startDate: moment(startDateProps),
            }))
        }
    }, [startDateProps]);

    React.useEffect(() => {
        if (!_.isNil(endDateProps)) {
            setPrereservation(prev => ({
                ...prev,
                endDate: moment(endDateProps),
            }))
        }
    }, [endDateProps]);

    React.useEffect(() => {
        setPrereservation(prev => ({
            ...prev,
            resourceId: identifiantSalleProps,
        }))
    }, [identifiantSalleProps]);


    const regExpEmail = new RegExp('^[^@]+@[^@]+.[^@.]+$');
    const isCourrielInvalid = (emailValue) => {
        return emailValue !== undefined && emailValue !== '' && !regExpEmail.test(emailValue);
    }

    const regExpPhone = new RegExp('^(?:(?:\\+|00)33|0)\\s*[1-9]\\d{8}$');
    const isTelephoneInvalid = (phoneNumberValue) => {
        return phoneNumberValue !== undefined && phoneNumberValue !== '' && !regExpPhone.test(phoneNumberValue);
    }

    const handleEndClickReservation = () => {
        if (isReservationError) {
            setIsPending(false)
        }else{
            setOpenModal(false);
        }
    }

    return (
        <>
            <ModalPreReservationFinalMessage
                isPending={isPending}
                isLoading={isLoading}
                isReservationError={isReservationError}
                onEndReservationClick={handleEndClickReservation}
            />
            <Modal.Header>
                <ReserverWhiteSvg />
                <span style={{ marginLeft: '0.5rem' }} >
                    Pré-réservation de salle
                </span>
            </Modal.Header>
            <Modal.Content
                scrolling
                style={{ paddingBottom: actionsVisible ? 0 : '1.5rem' }}
            >
                <Modal.Description>
                    <Step.Group className='styled'>
                        <Step active={etape === 1} >
                            <Step.Content>
                                <Step.Title>Votre demande</Step.Title>
                            </Step.Content>
                            <Icon
                                circular
                                inverted
                                name='check'
                                size='small'
                            />
                        </Step>
                        <Step active={etape === 2} disabled={etape < 2}>
                            <Step.Content>
                                <Step.Title>Vos coordonnées</Step.Title>
                            </Step.Content>
                            <Icon
                                circular
                                inverted
                                name='check'
                                size='small'
                            />
                        </Step>
                        <Step active={etape === 3} disabled={etape < 3}>
                            <Step.Content>
                                <Step.Title>Pour résumer</Step.Title>
                            </Step.Content>
                            <Icon
                                circular
                                inverted
                                name='check'
                                size='small'
                            />
                        </Step>
                    </Step.Group>
                    <Form autoComplete="off" spellCheck="false">
                        {etape === 1 && (
                            <ModalPreReservationDemande
                                allTypeReservantSalle={allTypeReservantSalle}
                                configDatabase={configDatabase}
                                dateDebut={debut}
                                dateFin={fin}
                                defaultResource={identifiantSalle}
                                fromPlanning={identifiantSalleProps}
                                getAllTypeReservant={getAllTypeReservant}
                                getRangePlannings={getRangePlannings}
                                getTypeReservantBySalle={getTypeReservantBySalle}
                                getSalleByTypeReservant={getSalleByTypeReservant}
                                getSalleById={getSalleById}
                                idEntite={idEntite}
                                onChange={handleChange}
                                salleByTypeReservant={salleByTypeReservant}
                                sallesList={sallesList}
                                setDisplayCreneauDispo={setDisplayCreneauDispo}
                                typeReservant={typeReservant}
                                typeReservantList={typeReservantList}
                                typeReservantBySalle={typeReservantBySalle}
                            />
                        )}
                        {etape === 2 && (
                            <ModalPreReservationCoordonnees
                                adresse={adresse}
                                civilite={civilite}
                                city={city}
                                email={email}
                                emailValidation={emailValidation}
                                isCourrielInvalid={isCourrielInvalid}
                                houseNumber={houseNumber}
                                infosComplementaires={infosComplementaires}
                                nom={nom}
                                nomOrganisation={nomOrganisation}
                                lettre={lettre}
                                numComplement={numComplement}
                                onChange={handleChange}
                                optionsCivilite={optionsCivilite}
                                organisation={isOrganisationSelected}
                                phoneNumber={phoneNumber}
                                isTelephoneInvalid={isTelephoneInvalid}
                                postCode={postCode}
                                prenom={prenom}
                                street={street}
                            />
                        )}
                        {etape === 3 && (
                            <ModalPreReservationResume
                                debut={debut}
                                fin={fin}
                                salle={nomSalle}
                                infosComplementaires={infosComplementaires}
                                reservant={{
                                    nomComplet: isOrganisationSelected
                                        ? nomOrganisation ? nomOrganisation : ''
                                        : ((civilite
                                                ? !_.isNil(optionsCivilite.find(({ value }) => value === civilite))
                                                    ? optionsCivilite.find(({ value }) => value === civilite).short + " "
                                                    : ''
                                                : '')
                                            + (nom ? nom + " " : '')
                                            + (prenom ? prenom : '')),
                                    adresse,
                                    telephone: phoneNumber,
                                    email,
                                }}
                            />
                        )}
                    </Form>
                </Modal.Description>
            </Modal.Content>
            {actionsVisible && (
                <Modal.Actions>
                    <Container fluid style={{ display: 'flex', alignItems: 'center' }}>
                        {etape === 1 && displayCreneauDispo && (
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.2em',
                                    color: "#90c647",
                                    paddingLeft: 20,
                                    textAlign: 'left'
                                }}
                            >
                                Ce créneau horaire est disponible pour cette salle
                            </span>
                        )}
                        {etape < 3 && error && (
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '1.2em',
                                    color: '#e9453a',
                                    paddingLeft: 20,
                                    textAlign: 'left'
                                }}
                            >
                                {error}
                            </span>
                        )}
                        <Container style={{ flex: 1, paddingLeft: 20, textAlign: 'left', flexDirection: 'row' }}>
                            {etape === 3 && (
                                <Captcha
                                    onChange={handleCaptchaChange}
                                    onEnterPress={handleEnvoyerClick}
                                    error={error && error !== ''}
                                />
                            )}
                        </Container>
                        <div style={{ textAlign: 'left' }}>
                            {etape === 3 && (
                                <Checkbox
                                    style={{ marginBottom: 10 }}
                                    label='Se souvenir de moi ? (Utilisation de cookies)'
                                    checked={rememberMe}
                                    onChange={() => { setRememberMe(!rememberMe) }}
                                />
                            )}
                            <div>
                                {etape !== 1 && (<Button
                                    size='tiny'
                                    basic
                                    icon='angle double left'
                                    content='Précédent'
                                    onClick={handlePreviousClick}
                                />)
                                }
                                {etape < 3
                                    ? (
                                        <Button
                                            disabled={error !== null || !displayCreneauDispo}
                                            labelPosition='right'
                                            icon='angle double right'
                                            content='Poursuivre ma demande'
                                            positive
                                            onClick={handleNextClick}
                                        />
                                    ) : (
                                        <Button
                                            labelPosition='left'
                                            icon='send'
                                            content='Envoyer ma demande'
                                            positive
                                            onClick={handleEnvoyerClick}
                                        />
                                    )}
                            </div>
                        </div>
                    </Container>
                </Modal.Actions>
            )}
        </>
    );
};

export default ModalPreReservationContent;
