import React from 'react';
import moment from 'moment'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../Button';
import Modal from '../Modal/Modal';
import ModalPreReservationContent from './ModalPreReservationContent';
import { getAllTypeReservant } from '../../store/actions/typeReservantActions';
import { getTypeReservantBySalle, getSalleByTypeReservant } from '../../store/actions/typeReservantSalleActions';
import { getSalleById } from '../../store/actions/sallesActions';
import { getRangePlannings } from '../../store/actions/planningsActions';

const ModalPreReservation = (props) => {
    const {
        createEvent,
        open,
        schedulerData,
        setOpenModal,
        ...rest
    } = props;

    const handleClose = () => {
        setOpenModal(false);
    }

    const handleValidate = async (prereservation, rememberMe, isOrganisationSelected) => {
        const {
            adresse,
            city,
            civilite,
            email,
            endDate,
            infosComplementaires,
            nom,
            nomOrganisation,
            phoneNumber,
            postCode,
            prenom,
            startDate,
            typeReservant,
            resourceId,
            houseNumber,
            lettre,
            numComplement,
            street,
        } = prereservation || {};

        const debut = startDate !== undefined && startDate !== null && startDate !== ''
            ? moment(startDate).format("DD/MM/YYYY HH:mm")
            : moment().format("DD/MM/YYYY HH:mm");

        const fin = endDate && endDate !== ''
            ? moment(endDate).format("DD/MM/YYYY HH:mm")
            : moment().add(1, 'hour').format("DD/MM/YYYY HH:mm");

        const reservationCreated = await createEvent(
            moment(debut, "DD/MM/YYYY HH:mm").toDate(),
            moment(fin, "DD/MM/YYYY HH:mm").toDate(),
            resourceId,
            typeReservant,
            nomOrganisation,
            civilite,
            nom,
            prenom,
            houseNumber,
            numComplement, // A, C, ...
            lettre, // Bis, ter, ...
            street,
            postCode,
            city,
            adresse,
            phoneNumber,
            email,
            infosComplementaires
        );

        if (rememberMe) {
            localStorage.setItem('reservant', JSON.stringify(
                {
                    typeReservant,
                    nomOrganisation,
                    civilite,
                    nom,
                    prenom,
                    houseNumber,
                    lettre,
                    numComplement,
                    street,
                    postCode,
                    city,
                    phoneNumber,
                    email,
                    isOrganisationSelected,
                    rememberMe,
                }
            ));
        } else {
            localStorage.setItem('reservant', JSON.stringify(
                {
                    rememberMe: rememberMe
                }
            ));
        }

        return reservationCreated;
    }

    if (!open) {
        return null;
    }

    return (
        <Modal
            className='modalGDS'
            open
            onClose={handleClose}
            closeIcon={(
                <Button
                    className='modalExitButton'
                    size='tiny'
                    circular
                    icon='close'
                />
            )}
        >
            <ModalPreReservationContent
                onValidate={handleValidate}
                setOpenModal={setOpenModal}
                schedulerData={schedulerData}
                {...rest}
            />
        </Modal>
    );
};

ModalPreReservation.defaultProps = {
    open: true,
}

export default connect(
    (state) => ({
        typeReservantBySalle: state.typeReservantSalleReducer?.typeReservantBySalle,
        salleByTypeReservant: state.typeReservantSalleReducer?.salleByTypeReservant,
        typeReservantList: state.typeReservantReducer?.typeReservantList,
        salleById: state.sallesReducer?.salleById,
        sallesList: state.sallesReducer?.sallesList,
        allTypeReservantSalle: state.typeReservantSalleReducer?.allTypeReservantSalle,
        searchedPlannings: state.planningsReducer?.searchedPlannings,
    }),
    (dispatch) => bindActionCreators({
        getAllTypeReservant,
        getTypeReservantBySalle: getTypeReservantBySalle,
        getSalleByTypeReservant: getSalleByTypeReservant,
        getSalleById: getSalleById,
        getRangePlannings: getRangePlannings,
    }, dispatch),
)(ModalPreReservation);
