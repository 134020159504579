// Col, Row and Icon do not have their own less files for styling. They use 
// rules declared in antd's global css. If these styles are imported directly
// from within antd, they'll include, for instance, reset rules. These will
// affect everything on the page and in essence would leak antd's global styles
// into all projects using this library. Instead of doing that, we are using
// a hack which allows us to wrap all antd styles to target specific root. In
// this case the root id will be "RBS-Scheduler-root". This way the reset styles
// won't be applied to elements declared outside of <Scheduler /> component.
//
// You can get more context for the issue by reading:
// https://github.com/ant-design/ant-design/issues/4331
// The solution is based on:
// https://github.com/ant-design/ant-design/issues/4331#issuecomment-391066131
// 
// For development
// This fix is implemented with webpack's NormalModuleReplacementPlugin in
// webpack/webpack-dev.config.js.
//
// For library builds
// This fix is implemented by the build script in scripts/build.js
//
// The next components have their own specific stylesheets which we import
// separately here to avoid importing from files which have required the global
// antd styles.

import _ from 'lodash';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';

import AddMorePopover from './AddMorePopover';
import AgendaView from './AgendaView';
import BodyView from './BodyView';
import DemoData from './DemoData';
import CellUnits from './CellUnits';
import DnDSource from './DnDSource';
import DnDContext from './DnDContext';
import EventItem from './EventItem';
import HeaderView from './HeaderView';
import ResourceView from './ResourceView';
import ResourceEvents from './ResourceEvents';
import SummaryPos from './SummaryPos';
import SchedulerData from './SchedulerData';
import ViewTypes from './ViewTypes';
import {
    Grid,
    Icon,
    Divider,
    Ref,
    Dimmer,
    Header,
    Segment,
} from 'semantic-ui-react';
import {
    DateInput,
} from 'semantic-ui-calendar-react';

import Button from '../../Button';
import Popup from '../../Popup/Popup';
import MenuBarButton from '../../../components/MenuBarButton';
import {
    COULEUR_FOND_VIOLET,
    COULEUR_BACKGROUND,
} from '../../../config/colors';
import { ReactComponent as SuivantSvg } from '../../../assets/suivant.svg';
import { ReactComponent as PrecedentSvg } from '../../../assets/precedent.svg';
import { ReactComponent as CalendrierSvg } from '../../../assets/calendrier.svg';

class Scheduler extends Component {

    constructor(props) {
        super(props);
        const {
            schedulerData,
            dndSources,
        } = props;

        let sources = [];
        sources.push(new DnDSource((props) => {
            return props.eventItem;
        }, EventItem));
        if (dndSources !== undefined && dndSources.length > 0) {
            sources = [...sources, ...dndSources];
        }
        let dndContext = new DnDContext(sources, ResourceEvents);

        this.currentArea = -1;
        schedulerData._setDocumentWidth(document.documentElement.clientWidth);
        this.state = {
            visible: false,
            dndContext: dndContext,
            contentHeight: schedulerData.getSchedulerContentDesiredHeight(),
            contentScrollbarHeight: 17,
            contentScrollbarWidth: 17,
            resourceScrollbarHeight: 17,
            resourceScrollbarWidth: 17,
            scrollLeft: 0,
            scrollTop: 0,
            documentWidth: document.documentElement.clientWidth,
            documentHeight: document.documentElement.clientHeight,
            render: false /** MODIFICATION --- state pour rerender les composants pour plier deplier les salles */
        };

        if (schedulerData.isSchedulerResponsive())
            window.onresize = this.onWindowResize;

    }

    /** MODIFICATION --- Fonction qui permet de changer la valeur du state render */
    toggleRender = () => {
        this.setState({
            render: !this.state.render
        })
    }

    onWindowResize = (e) => {
        const { schedulerData } = this.props;
        schedulerData._setDocumentWidth(document.documentElement.clientWidth);
        this.setState({
            documentWidth: document.documentElement.clientWidth,
            documentHeight: document.documentElement.clientHeight,
        });
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        prevClick: PropTypes.func.isRequired,
        nextClick: PropTypes.func.isRequired,
        onViewChange: PropTypes.func.isRequired,
        onSelectDate: PropTypes.func.isRequired,
        onSetAddMoreState: PropTypes.func,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        movingEvent: PropTypes.func,
        leftCustomHeader: PropTypes.object,
        rightCustomHeader: PropTypes.object,
        newEvent: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        conflictOccurred: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
        dndSources: PropTypes.array,
        slotClickedFunc: PropTypes.func,
        toggleExpandFunc: PropTypes.func,
        slotItemTemplateResolver: PropTypes.func,
        nonAgendaCellHeaderTemplateResolver: PropTypes.func,
        onScrollLeft: PropTypes.func,
        onScrollRight: PropTypes.func,
        onScrollTop: PropTypes.func,
        onScrollBottom: PropTypes.func,
    }

    componentDidMount(props, state) {
        this.resolveScrollbarSize();
    }

    componentDidUpdate(props, state) {
        this.resolveScrollbarSize();

        const { schedulerData } = this.props;
        const { localeMoment, behaviors } = schedulerData;
        if (schedulerData.getScrollToSpecialMoment() && !!behaviors.getScrollSpecialMomentFunc) {
            if (!!this.schedulerContent && this.schedulerContent.scrollWidth > this.schedulerContent.clientWidth) {
                let start = localeMoment(schedulerData.startDate).startOf('day'),
                    end = localeMoment(schedulerData.endDate).endOf('day'),
                    specialMoment = behaviors.getScrollSpecialMomentFunc(schedulerData, start, end);
                if (specialMoment >= start && specialMoment <= end) {
                    let index = 0;
                    schedulerData.headers.forEach((item) => {
                        let header = localeMoment(item.time);
                        if (specialMoment >= header)
                            index++;
                    })
                    this.schedulerContent.scrollLeft = (index - 1) * schedulerData.getContentCellWidth();

                    schedulerData.setScrollToSpecialMoment(false);
                }
            }
        }
    }

    resolveScrollbarSize = () => {
        const { schedulerData } = this.props;
        let contentScrollbarHeight = 17;
        let contentScrollbarWidth = 17;
        let resourceScrollbarHeight = 17;
        let resourceScrollbarWidth = 17;
        let contentHeight = schedulerData.getSchedulerContentDesiredHeight();
        if (!!this.schedulerContent) {
            contentScrollbarHeight = this.schedulerContent.offsetHeight - this.schedulerContent.clientHeight;
            contentScrollbarWidth = this.schedulerContent.offsetWidth - this.schedulerContent.clientWidth;
        }
        if (!!this.schedulerResource) {
            resourceScrollbarHeight = this.schedulerResource.offsetHeight - this.schedulerResource.clientHeight;
            resourceScrollbarWidth = this.schedulerResource.offsetWidth - this.schedulerResource.clientWidth;
        }
        if (!!this.schedulerContentBgTable && !!this.schedulerContentBgTable.offsetHeight) {
            contentHeight = this.schedulerContentBgTable.offsetHeight;
        }

        let tmpState = {};
        let needSet = false;
        if (contentScrollbarHeight !== this.state.contentScrollbarHeight) {
            tmpState = { ...tmpState, contentScrollbarHeight: contentScrollbarHeight };
            needSet = true;
        }
        if (contentScrollbarWidth !== this.state.contentScrollbarWidth) {
            tmpState = { ...tmpState, contentScrollbarWidth: contentScrollbarWidth };
            needSet = true;
        }
        if (contentHeight !== this.state.contentHeight) {
            tmpState = { ...tmpState, contentHeight: contentHeight };
            needSet = true;
        }
        if (resourceScrollbarHeight !== this.state.resourceScrollbarHeight) {
            tmpState = { ...tmpState, resourceScrollbarHeight: resourceScrollbarHeight };
            needSet = true;
        }
        if (resourceScrollbarWidth !== this.state.resourceScrollbarWidth) {
            tmpState = { ...tmpState, resourceScrollbarWidth: resourceScrollbarWidth };
            needSet = true;
        }
        if (needSet)
            this.setState(tmpState);
    }

    schedulerHeadRef = (element) => {
        this.schedulerHead = element;
    }

    onSchedulerHeadMouseOver = () => {
        this.currentArea = 2;
    }

    onSchedulerHeadMouseOut = () => {
        this.currentArea = -1;
    }

    onSchedulerHeadScroll = (proxy, event) => {
        if ((this.currentArea === 2 || this.currentArea === -1) && this.schedulerContent.scrollLeft !== this.schedulerHead.scrollLeft) {
            this.schedulerContent.scrollLeft = this.schedulerHead.scrollLeft;
        }
    }

    schedulerResourceRef = (element) => {
        this.schedulerResource = element;
    }

    onSchedulerResourceMouseOver = () => {
        this.currentArea = 1;
    }

    onSchedulerResourceMouseOut = () => {
        this.currentArea = -1;
    }

    onSchedulerResourceScroll = (proxy, event) => {
        if ((this.currentArea === 1 || this.currentArea === -1) && this.schedulerContent.scrollTop !== this.schedulerResource.scrollTop) {
            this.schedulerContent.scrollTop = this.schedulerResource.scrollTop;
        }
    }

    schedulerContentRef = (element) => {
        this.schedulerContent = element;
    }

    schedulerContentBgTableRef = (element) => {
        this.schedulerContentBgTable = element;
    }

    onSchedulerContentMouseOver = () => {
        this.currentArea = 0;
    }

    onSchedulerContentMouseOut = () => {
        this.currentArea = -1;
    }

    onSchedulerContentScroll = (proxy, event) => {
        if (this.currentArea === 0 || this.currentArea === -1) {
            if (this.schedulerHead.scrollLeft !== this.schedulerContent.scrollLeft)
                this.schedulerHead.scrollLeft = this.schedulerContent.scrollLeft;
            if (this.schedulerResource.scrollTop !== this.schedulerContent.scrollTop)
                this.schedulerResource.scrollTop = this.schedulerContent.scrollTop;
        }

        const { schedulerData, onScrollLeft, onScrollRight, onScrollTop, onScrollBottom } = this.props;
        const { scrollLeft, scrollTop } = this.state;
        if (this.schedulerContent.scrollLeft !== scrollLeft) {
            if (this.schedulerContent.scrollLeft === 0 && onScrollLeft !== undefined) {
                onScrollLeft(schedulerData, this.schedulerContent, this.schedulerContent.scrollWidth - this.schedulerContent.clientWidth);
            }
            if (this.schedulerContent.scrollLeft === this.schedulerContent.scrollWidth - this.schedulerContent.clientWidth && onScrollRight !== undefined) {
                onScrollRight(schedulerData, this.schedulerContent, this.schedulerContent.scrollWidth - this.schedulerContent.clientWidth);
            }
        } else if (this.schedulerContent.scrollTop !== scrollTop) {
            if (this.schedulerContent.scrollTop === 0 && onScrollTop !== undefined) {
                onScrollTop(schedulerData, this.schedulerContent, this.schedulerContent.scrollHeight - this.schedulerContent.clientHeight);
            }
            if (this.schedulerContent.scrollTop === this.schedulerContent.scrollHeight - this.schedulerContent.clientHeight && onScrollBottom !== undefined) {
                onScrollBottom(schedulerData, this.schedulerContent, this.schedulerContent.scrollHeight - this.schedulerContent.clientHeight);
            }
        }
        this.setState({
            scrollLeft: this.schedulerContent.scrollLeft,
            scrollTop: this.schedulerContent.scrollTop
        });
    }

    onViewChange = (type) => () => {
        const { onViewChange, schedulerData } = this.props;

        /** On effectue le changement de vue */
        onViewChange(schedulerData, { viewType: type });
    }

    handleEventClick = (date, toDayView, eventId) => {
        const { onSelectDate, schedulerData } = this.props;
        const { webdev } = window;

        if (toDayView === true) {
            let d = date;
            
            // Dans certains cas on reçoit une date moment
            if (!_.isString(d)) {
                d = moment(d).format('YYYY-MM-DD HH:mm:ss');
            }
            
            d = d.split(' ')[0];

            /** On effectue le changement de vue */
            onSelectDate(schedulerData, d);
        } else {
            if (webdev) {
                vAfficheReservation(eventId); // eslint-disable-line
            }
        }
    }

    goNext = () => {
        const { nextClick, schedulerData } = this.props;
        nextClick(schedulerData);
    }

    goBack = () => {
        const { prevClick, schedulerData } = this.props;
        prevClick(schedulerData);
    }

    /** Modification : Fonction qui appelle la nouvelle Prop todayClick */
    goToday = () => {
        const { todayClick, schedulerData } = this.props;
        todayClick(schedulerData);
    }

    handleVisibleChange = (visible) => {
        this.setState({ visible });
    }

    onSelect = (e, data) => {
        this.setState({
            visible: false,
        });

        const { onSelectDate, schedulerData } = this.props;
        onSelectDate(schedulerData, moment(data.value, 'DD-MM-YYYY'));
    }

    render() {
        const {
            schedulerData,
            leftCustomHeader,
            rightCustomHeader,
            sallesList,
            sallesTypesReservationsListInfos,
            heureDebutDefaut,
            heureFinDefaut,
            configDatabase,
            idEntite,
        } = this.props;
        const {
            contentScrollbarHeight,
            contentScrollbarWidth,
            dndContext,
            resourceScrollbarHeight,
            contentHeight,
            render,
        } = this.state;

        const { webdev } = window;
        const {
            renderData,
            viewType,
            showAgenda,
            isEventPerspective,
            config,
        } = schedulerData;
        
        let atLeastOneSalle = true;

        /** MODIFICATION -- Récupération de la vue et application du style btnSelected au bouton selectionné */
        let btnId;
        switch (viewType) {
            case 0:
                btnId = 'Jour'
                break;
            case 1:
                btnId = 'Semaine'
                break;
            case 2:
                btnId = 'Mois'
                break;
            default:
                btnId = 'Semaine'
        }
        if (document.getElementById(btnId) !== null && document.getElementById(btnId) !== undefined) {
            document.getElementById(btnId).classList.add('btnSelected');
        }


        const width = schedulerData.getSchedulerWidth();
        const calendarPopoverEnabled = config.calendarPopoverEnabled;

        const dateLabel = schedulerData.getDateLabel();
        const defaultValue = `${viewType}${showAgenda ? 1 : 0}${isEventPerspective ? 1 : 0}`;

        let tbodyContent = <tr />;
        if (showAgenda) {
            tbodyContent = <AgendaView
                {...this.props}
            />
        }
        else {
            let resourceTableWidth = schedulerData.getResourceTableWidth();
            let schedulerContainerWidth = width - resourceTableWidth + 1;
            let schedulerWidth = schedulerData.getContentTableWidth() - 1;
            let DndResourceEvents = dndContext.getDropTarget();
            let eventDndSource = dndContext.getDndSource();

            let displayRenderData = renderData.filter(o => o.render);
            let resourceEventsList = displayRenderData.map((item) => {
                return <DndResourceEvents
                    {...this.props}
                    key={item.slotId}
                    resourceEvents={item}
                    dndSource={eventDndSource}
                    heureDebutDefaut={heureDebutDefaut}
                    heureFinDefaut={heureFinDefaut}
                    onEventClick={this.handleEventClick}
                />
            });

            if (_.head(displayRenderData)?.slotId === -1) {
                atLeastOneSalle = false
            }

            let resourcePaddingBottom = resourceScrollbarHeight === 0 ? contentScrollbarHeight : 0;
            let contentPaddingBottom = contentScrollbarHeight === 0 ? resourceScrollbarHeight : 0;
            let schedulerContentStyle = {
                overflow: 'auto',
                margin: "0px",
                position: "relative",
                paddingBottom: contentPaddingBottom,
            };
            let resourceContentStyle = {
                overflow: "hidden",
                width: '100%',
                margin: `0px -${contentScrollbarWidth}px 0px 0px`,
            };

            // Si on a défini une taille max. pour le planning dans le fichier config.js
            // → Alors on l'applique à la table de liste des salles mais aussi au planning (pour qu'ils aient la même hauteurs)
            if (config.schedulerMaxHeight !== undefined && config.schedulerMaxHeight !== '') {
                schedulerContentStyle = {
                    ...schedulerContentStyle,
                    maxHeight: config.schedulerMaxHeight,
                };
                resourceContentStyle = {
                    ...resourceContentStyle,
                    maxHeight: config.schedulerMaxHeight,
                };
            }

            const resourceName = schedulerData.isEventPerspective
                ? config.taskName
                : config.resourceName;

            tbodyContent = (
                <tr>
                    {/* Affichage des ressources dans le planning */}
                    <td
                        style={{
                            width: resourceTableWidth,
                            verticalAlign: 'top',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#F4F4F5',
                                border: '1px solid #E8EAEF',
                                borderTopLeftRadius: '1rem',
                                borderBottomLeftRadius: '1rem',
                                overflow: 'hidden',
                            }}
                        >
                            <div
                                style={{
                                    overflow: "hidden",
                                    // borderBottom: "1px solid #e9e9e9",
                                    height: config.tableHeaderHeight,
                                }}
                            >
                                <div
                                    style={{
                                        overflowX: "scroll",
                                        overflowY: "hidden",
                                        margin: `0px 0px -${contentScrollbarHeight}px`,
                                    }}
                                >
                                    <table
                                        className="resource-table"
                                    >
                                        {/* Entête "Salles" */}
                                        <thead>
                                            <tr
                                                style={{
                                                    height: config.tableHeaderHeight
                                                }}
                                            >
                                                <th
                                                    className="header3-text"
                                                    style={{
                                                        paddingLeft: '20%',
                                                        fontSize: '1.7rem',
                                                        fontWeight: 'bold',
                                                        color: '#5775AB',
                                                    }}
                                                >
                                                    {resourceName}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div
                                style={resourceContentStyle}
                                ref={this.schedulerResourceRef}
                                onMouseOver={this.onSchedulerResourceMouseOver}
                                onMouseOut={this.onSchedulerResourceMouseOut}
                                onScroll={this.onSchedulerResourceScroll}
                            >
                                <ResourceView
                                    configDatabase={configDatabase}
                                    idEntite={idEntite}
                                    schedulerData={schedulerData}
                                    sallesList={sallesList}
                                    sallesTypesReservationsListInfos={sallesTypesReservationsListInfos}
                                    toggleRender={this.toggleRender}
                                    contentScrollbarHeight={resourcePaddingBottom}
                                />
                            </div>
                        </div>
                    </td>

                    {/* Affichage de toute la partie droite du planning  (horaires, ligne des salles et réservations) */}
                    <td>
                        <div
                            className="scheduler-view"
                            style={{
                                width: schedulerContainerWidth,
                                verticalAlign: 'top',
                            }}
                        >
                            {/* Affichage du header */}
                            <div
                                className="schedulerViewHeader"
                                style={{
                                    overflow: "hidden",
                                    borderBottom: "1px solid #e9e9e9",
                                    height: config.tableHeaderHeight,
                                    borderRadius: '15px !important',
                                }}
                            >
                                <div
                                    ref={this.schedulerHeadRef}
                                    onMouseOver={this.onSchedulerHeadMouseOver}
                                    onMouseOut={this.onSchedulerHeadMouseOut}
                                    onScroll={this.onSchedulerHeadScroll}
                                    style={{
                                        overflowX: "scroll",
                                        overflowY: "hidden",
                                        margin: `0px 0px -${contentScrollbarHeight}px`,
                                        borderTopRightRadius: '15px',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: `${schedulerWidth}px`,
                                            backgroundColor: `${COULEUR_BACKGROUND}`,
                                            borderTopRightRadius: '15px',
                                        }}>
                                        <table className="scheduler-bg-table">
                                            <HeaderView {...this.props} contentScrollbarWidth={contentScrollbarWidth} />
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* Affichage du body */}
                            <div
                                ref={this.schedulerContentRef}
                                onMouseOver={this.onSchedulerContentMouseOver}
                                onMouseOut={this.onSchedulerContentMouseOut}
                                onScroll={this.onSchedulerContentScroll}
                                className='scrollableContent'
                                style={{
                                    ...schedulerContentStyle,
                                    marginRight: this.schedulerContentBgTable?.offsetHeight > this.schedulerContent?.offsetHeight
                                        ? `-${contentScrollbarWidth}px`
                                        : 0,
                                }}
                            >
                                <div
                                    style={{
                                        height: contentHeight,
                                        width: `${schedulerWidth}px`,
                                    }}
                                >
                                    {/* Affichage des évenements */}
                                    <div className="scheduler-content">
                                        <table className="scheduler-content-table" >
                                            <tbody>
                                                {resourceEventsList}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* Affichage de la grille du body */}
                                    <div
                                        // className="scheduler-bg"
                                        style={{
                                            backgroundColor: '#F6F6F6',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            right: '0',
                                            bottom: '0',
                                            zIndex: '1',
                                        }}
                                    >
                                        <table
                                            ref={this.schedulerContentBgTableRef}
                                            className="scheduler-bg-table"
                                            style={{
                                                width: viewType === 1
                                                    ? '100%' // 100% fonctionne en vue semaine mais pas en mois ou jour car doit dépasser pour avoir le scroll
                                                    : schedulerWidth,
                                                border: 'none',
                                                margin: '0',
                                                padding: '0',
                                                borderSpacing: '0',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {/** Modification --- Ajout prop render pour rerender quand on plie ou deplie les salles */}
                                            <BodyView
                                                {...this.props}
                                                render={render}
                                            />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        };

        /** MODIFICATION : définition des variables pour désactiver la navigation */
        let isNextDisabled = this.props.infinitePlanning
            ? false
            : moment(schedulerData.endDate, 'YYYY-MM-DD').add(1, 'days') >= this.props.lastDateMax;

        let isBackDisabled = !webdev && this.props.isPublicationEnLigne && moment(schedulerData.startDate, 'YYYY-MM-DD') <= moment();

        //MODIFICATION : changement de datePicker -- anciennement <Calendar>
        let popover = <DateInput
            inline
            onChange={this.onSelect}
            localization='fr'
            value={moment().format('DD-MM-YYYY')}
            minDate={moment()}
            maxDate={this.props.infinitePlanning
                ? false
                : this.props.lastDateMax}
        />

        // Construction du libellé pour la période d'affichage
        const dateLabelComponent = (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <CalendrierSvg
                    style={{
                        width: '2rem',
                        height: '2rem',
                        marginRight: '1.5rem'
                    }}
                />
                <span
                    style={{
                        color: `${COULEUR_FOND_VIOLET}`,
                        cursor: 'pointer',
                        fontSize: '1.5rem',
                    }}
                >
                    {dateLabel}
                </span>
            </div>
        );

        let schedulerHeader = <div />;
        if (config.headerEnabled) {
            if (webdev) {
                schedulerHeader = (                
                    <Grid
                        style={{
                            width: '80%',
                            margin: 'auto'
                        }}
                        centered={true}
                        verticalAlign='middle'
                    >
                        <Grid.Row
                            style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <MenuBarButton
                                buttonText={"Aujourd'hui"}
                                onClick={this.goToday}
                            />
                            <MenuBarButton
                                active={viewType === 0}
                                buttonText='Jour'
                                onClick={this.onViewChange(0)}
                            />
                            <MenuBarButton
                                active={viewType === 1}
                                buttonText='Semaine'
                                onClick={this.onViewChange(1)}
                            />
                            <MenuBarButton
                                active={viewType === 2}
                                buttonText='Mois'
                                onClick={this.onViewChange(2)}
                            />
                        </Grid.Row>
                        <Grid.Row style={{ paddingBottom: 0 }}>
                            <Grid.Column width={5}>
                                {leftCustomHeader}
                            </Grid.Column>
                            <Grid.Column width={6} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    disabled={isBackDisabled}
                                    onClick={this.goBack}
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: `${COULEUR_FOND_VIOLET}`,
                                    }}
                                    content={
                                        <PrecedentSvg
                                            className="buttonNavigateScheduler"
                                        />
                                    }
                                />
                                {calendarPopoverEnabled
                                    ? (
                                        <Popup
                                            trigger={dateLabelComponent}
                                            content={popover}
                                            position='bottom center'
                                            style={{ padding: 0 }}
                                            on='click'
                                        />
                                    ) :  dateLabelComponent
                                }
                                <Button
                                    disabled={isNextDisabled}
                                    onClick={this.goNext}
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: `${COULEUR_FOND_VIOLET}`,
                                    }}
                                    icon={
                                        <SuivantSvg
                                            className="buttonNavigateScheduler"
                                        />
                                    }
                                />
                            </Grid.Column>
                            <Grid.Column width={5}>
                                {rightCustomHeader}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                );
            } else {
                schedulerHeader = (
                    <Grid
                        style={{
                            width: '80%',
                            margin: 'auto'
                        }}
                        centered={true}
                        verticalAlign='middle'
                    >
                        <Grid.Row
                            style={{
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                        >
                            <MenuBarButton
                                buttonText={"Aujourd'hui"}
                                onClick={this.goToday}
                            />
                            <MenuBarButton
                                active={viewType === 0}
                                buttonText='Jour'
                                onClick={this.onViewChange(0)}
                            />
                            <MenuBarButton
                                active={viewType === 1}
                                buttonText='Semaine'
                                onClick={this.onViewChange(1)}
                            />
                            <MenuBarButton
                                active={viewType === 2}
                                buttonText='Mois'
                                onClick={this.onViewChange(2)}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Button
                                disabled={isBackDisabled}
                                onClick={this.goBack}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: `${COULEUR_FOND_VIOLET}`,
                                }}
                                content={
                                    <PrecedentSvg
                                        className="buttonNavigateScheduler"
                                    />
                                }
                            />
                            {calendarPopoverEnabled
                                ? (
                                    <Popup
                                        trigger={dateLabelComponent}
                                        content={popover}
                                        position='bottom center'
                                        style={{ padding: 0 }}
                                        on='click'
                                    />
                                ) :  dateLabelComponent
                            }
                            <Button
                                disabled={isNextDisabled}
                                onClick={this.goNext}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: `${COULEUR_FOND_VIOLET}`,
                                }}
                                icon={
                                    <SuivantSvg
                                        className="buttonNavigateScheduler"
                                    />
                                }
                            />
                        </Grid.Row>
                        <Grid.Row
                            style={{
                                paddingTop: '0',
                            }}
                        >
                            <Grid.Column width={8}>
                                {leftCustomHeader}
                            </Grid.Column>
                            <Grid.Column width={8}>
                                {rightCustomHeader}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                );
            }
        }

        return (
            <div
                id="RBS-Scheduler-root"
                className="scheduler"
            >
                {schedulerHeader}
                <Grid centered={true}>
                    <Grid.Row
                        centered={true}
                        style={{
                            paddingTop: '0',
                        }}
                    >
                        {!webdev || atLeastOneSalle
                            ? (
                                <table>
                                    <tbody>
                                        {tbodyContent}
                                    </tbody>
                                </table>
                            ) : (
                                <Dimmer.Dimmable
                                    as={Segment}
                                    blurring
                                    dimmed
                                    style={{ width: '80%', height: 60 }}
                                >
                                    <Dimmer active>
                                        <Header as='h2' inverted>
                                            Aucun résultat correspondant
                                        </Header>
                                    </Dimmer>
                                </Dimmer.Dimmable>
                            )}
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export { SchedulerData, ViewTypes, CellUnits, SummaryPos, DnDSource, DnDContext, AddMorePopover, DemoData }
export default Scheduler
