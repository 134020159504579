import React from 'react';
import {
    Row,
} from 'antd';

import ModalDisponibilite from './Disponibilite';
import MenuBarButton from './MenuBarButton';
import { ReactComponent as ReserverSvg } from '../assets/reserver.svg';
import { ReactComponent as DocumentsSvg } from '../assets/documents.svg';
import { ReactComponent as RechercheSvg } from '../assets/recherche.svg';

const MenuBar = (props) => {
    const {
        idEntite,
        callbackOpenModal,
        callbackOpenModalDocuments,
        optionsSelectTypesSalles,
        optionsSelectTypesReservants,
        callbackAddNewEvent,
        sallesList,
        schedulerData,
        configDatabase,
        typesReservation,
    } = props;
    const { webdev } = window;

    /** State popup recherche de disponibilités */
    const [rechercheOpen, setRechercheOpen] = React.useState(false);

    const handleRechercheClose = () => {
        setRechercheOpen(false);
    }

    //** Boutons d'action : pré-réservation, recherche et documents  */
    // Création du bouton "Nouvelle pré-réservation"
    const buttonNouvellePrereservation = (
        <MenuBarButton
            buttonText='Pré-réserver'
            imageSvgComponent={<ReserverSvg />}
            onClick={() => {
                callbackOpenModal(true);
                callbackAddNewEvent(schedulerData, null, null, null);
            }}
        />
    );

    // Création du bouton "Recherche de dispo"
    const buttonRechercheDispo = (
        <MenuBarButton
            buttonText='Disponibilités'
            imageSvgComponent={<RechercheSvg />}
            onClick={() => { setRechercheOpen(true) }}
        />
    );
    // Création du bouton "Documents"
    const buttonDocuments = (
        <MenuBarButton
            buttonText='Documents'
            popupTitleText='Recherche de documents'
            imageSvgComponent={<DocumentsSvg />}
            onClick={() => {
                callbackOpenModalDocuments(true);
            }}
        />
    );

    return (
        <Row
            justify='start'
        >
            {!webdev && buttonNouvellePrereservation}
            {buttonRechercheDispo}
            {rechercheOpen && (<ModalDisponibilite
                onClose={handleRechercheClose}
                callbackOpenModal={callbackOpenModal}
                callbackAddNewEvent={callbackAddNewEvent}
                configDatabase={configDatabase}
                idEntite={idEntite}
                typesReservant={optionsSelectTypesReservants}
                typesSalle={optionsSelectTypesSalles}
                typesReservation={typesReservation}
                salles={sallesList}
                schedulerData={schedulerData}
            />)}
            {buttonDocuments}
        </Row>
    );
}

export default MenuBar;