import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import {
    Container,
    Modal,
    Icon,
    Step,
    Form,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../Button';
import ModalDisponibiliteCriteres from './ModalDisponibiliteCriteres';
import ModalDisponibiliteResults from './ModalDisponibiliteResults';
import { ReactComponent as RechercheSvg } from '../../assets/rechercheWhite.svg';
import { getSallesDisponibles } from '../../store/actions/sallesActions';

const ModalDisponibiliteContent = (props) => {
    const {
        configDatabase,
        idEntite,
        getSallesDisponibles,
        onSalleSelect,
        sallesDisponibles,
        salles: sallesAffiches,
        schedulerData,
        typesReservant,
        typesReservation,
        typesSalle,
    } = props;
    
    const [criteres, setCriteres] = React.useState({
        debut: null,
        fin: null,
        typeReservant: null,
        activite: 0,
        typeSalle: 0,
        capacite: null,
        placesAssises: null,
        placesDebouts: null,
    });
    const [etape, setEtape] = React.useState(1);
    /* Autres */
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [actionsVisible, setActionsVisible] = React.useState(true);

    const {
        debut,
        fin,
        typeReservant,
        activite,
        typeSalle,
        capacite,
        placesAssises,
        placesDebouts,
    } = criteres;

    const handleSalleSelect = (id) => {
        if (onSalleSelect) {
            onSalleSelect(id, debut, fin, sallesDisponibles);
        }
    }

    const handleChange = (name, value) => {
        setCriteres({
            ...criteres,
            [name]: value
        })
        setError(null);
        setActionsVisible(true);
    }

    const handlePreviousClick = () => {
        setError(null);
        setEtape(1);
    }

    const handleNextClick = () => {
        if (_.isNil(typeReservant) || typeReservant === null) {
            setError('Un type de réservant doit être renseigné');

            return null;
        }

        if (_.isNil(debut)) {
            setError('La date de début doit être renseignée');

            return null;
        }

        if (_.isNil(fin)) {
            setError('La date de fin doit être renseignée');

            return null;
        }

        setError(null);
        setEtape(2);

        setLoading(true);

        getSallesDisponibles(
            _.map(sallesAffiches, ({ identifiant }) => identifiant),
            moment(debut).format('YYYY-MM-DD HH:mm:ss'),
            moment(fin).format('YYYY-MM-DD HH:mm:ss'),
            typeSalle === 0 ? 'all' : typeSalle,
            typeReservant === 0 ? 'all' : typeReservant,
            activite === 0 ? 'all' : activite,
            capacite || 0, // Si ils ne sont pas définis, on les met à 0 pour avoir toutes les salles
            placesAssises || 0,
            placesDebouts || 0,
            idEntite,
            configDatabase
        ).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            <Modal.Header>
                <RechercheSvg />
                <span style={{ marginLeft: '0.5rem' }} >
                    Recherche d'une salle libre
                </span>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Step.Group className='styled'>
                        <Step active={etape === 1} >
                            <Step.Content>
                                <Step.Title>Critères de recherche</Step.Title>
                            </Step.Content>
                            <Icon
                                circular
                                inverted
                                name='check'
                                size='small'
                            />
                        </Step>
                        <Step active={etape === 2} disabled={etape < 2}>
                            <Step.Content>
                                <Step.Title>Disponibilités</Step.Title>
                            </Step.Content>
                            <Icon
                                circular
                                inverted
                                name='check'
                                size='small'
                            />
                        </Step>
                    </Step.Group>
                    <Form autoComplete="off" spellCheck="false">
                        {etape === 1 && (
                            <ModalDisponibiliteCriteres
                                activites={typesReservation}
                                defaultActivite={activite}
                                defaultCapacite={capacite}
                                defaultDebut={debut}
                                defaultFin={fin}
                                defaultPlacesAssises={placesAssises}
                                defaultPlacesDebouts={placesDebouts}
                                defaultTypeReservant={typeReservant}
                                defaultTypeSalle={typeSalle}
                                onChange={handleChange}
                                typesReservant={typesReservant}
                                typesSalle={typesSalle}
                            />
                        )}
                        {etape === 2 && (
                            <ModalDisponibiliteResults
                                configDatabase={configDatabase}
                                identifiantEntite={idEntite}
                                debut={debut}
                                fin={fin}
                                typeReservant={_.find(typesReservant, ({ identifiant }) => identifiant === typeReservant)}
                                activite={_.find(typesReservation, ({ identifiant }) => identifiant === activite)}
                                typeSalle={_.find(typesSalle, ({ identifiant }) => identifiant === typeSalle)}
                                capacite={capacite}
                                onSalleSelect={handleSalleSelect}
                                placesAssises={placesAssises}
                                placesDebouts={placesDebouts}
                                salles={_.orderBy(
                                    _.filter(
                                        sallesDisponibles,
                                        ({ identifiant }) => {
                                            const schedulerDataSalle = _.filter(
                                                schedulerData?.resources,
                                                ({ id }) => id === identifiant
                                            )[0];

                                            const {
                                                dateMaximum,
                                                finDelai
                                            } = schedulerDataSalle || {};
                                            
                                            if (
                                                (!dateMaximum || moment(fin).isBefore(dateMaximum))
                                                && (!finDelai || moment(debut).isAfter(finDelai))
                                            ) {
                                                return true;
                                            }

                                            return false;
                                        }
                                    ),
                                    ['identifiantTypesSalle', 'nomSalle']
                                )}
                                typesSalle={typesSalle}
                                loading={loading}
                            />
                        )}
                    </Form>
                </Modal.Description>
            </Modal.Content>
            {actionsVisible && (
                <Modal.Actions>
                    <Container
                        fluid
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 'bold',
                                fontSize: '1.2em',
                                color: '#e9453a',
                                paddingLeft: 20,
                                textAlign: 'left',
                                flex: 1,
                            }}
                        >
                            {error}
                        </span>
                        <div style={{ textAlign: 'left' }}>
                            <div>
                                {etape === 1
                                    ? (
                                        <Button
                                            disabled={error !== null}
                                            icon='search'
                                            content='Rechercher'
                                            primary
                                            onClick={handleNextClick}
                                        />
                                    ) : (
                                        <Button
                                            content='Modifier'
                                            basic
                                            icon='angle double left'
                                            onClick={handlePreviousClick}
                                        />
                                    )}
                            </div>
                        </div>
                    </Container>
                </Modal.Actions>
            )}
        </>
    );
};

export default connect(
    (state) => ({
        sallesDisponibles: state.sallesReducer?.sallesDisponibles,
    }),
    (dispatch) => bindActionCreators({
        getSallesDisponibles: getSallesDisponibles,
    }, dispatch),
)(ModalDisponibiliteContent);
