import Axios from 'axios';
import {
    GET_ALL_RESERVANTS,
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getAllReservants = (idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/reservants?perPage=200&idEntite='+idEntite)
    .then(( data ) => {
        dispatch({
            type: GET_ALL_RESERVANTS,
            reservantsList: data,
        });
    });
};
