import _ from 'lodash';
import React from 'react';

import Types from '360-types';

import {
    Form,
    Input,
} from 'semantic-ui-react';

const InputDateHour = (props) => {
    const {
        date,
        disabled,
        focused,
        onFocus,
        label,
        type,
        ...rest
    } = props;

    const handleFocus = () => {
        if (onFocus) {
            onFocus(type+'Date');
        }
    }

    return (
        <Form.Field
            style={{ marginRight: '3em' }}
            onClick={disabled ? null : handleFocus}
            {...rest}
        >
            <label>{label}</label>
            <div
                style={{
                    cursor: 'pointer',
                    border: '1px solid',
                    borderColor: focused ? '#5270a9' : 'rgba(34,36,38,.15)',
                    borderRadius: 20,
                    background: focused ? 'white' : 'transparent',
                    width: 'calc(255px + 2px + 2em)', // Taille des deux input + border-left et right + margin left et right
                }}
            >
                <Input
                    disabled={disabled}
                    transparent
                    icon={{
                        name: 'calendar alternate outline',
                        size: 'large',
                    }}
                    iconPosition='left'
                    style={{
                        width: 175,
                        margin: '.67857143em 0 .67857143em 1em',
                    }}
                    input={{
                        style: { cursor: 'pointer' }
                    }}
                    placeholder='Renseigner une date'
                    readOnly
                    value={_.isNil(date)
                        ? ''
                        : date.format('D MMMM YYYY')
                    }
                    onFocus={handleFocus}
                />
                <Input
                    disabled={disabled}
                    transparent
                    icon={{
                        name: 'clock outline',
                        size: 'large',
                    }}
                    iconPosition='left'
                    style={{
                        width: 80,
                        margin: '.67857143em 1em .67857143em 0',
                    }}
                    input={{
                        style: {
                            cursor: 'pointer',
                            textAlign: 'center'
                        }
                    }}
                    placeholder='- - : - -'
                    readOnly
                    value={_.isNil(date)
                        ? ''
                        : date.format('H') + ':' + date.format('mm')
                    }
                    onFocus={handleFocus}
                />
            </div>
        </Form.Field>
    );
};

InputDateHour.propTypes = {
    /** Moment object */
    date: Types.object,

    /** Whether or not the field should be highlighted. */
    focused: Types.bool,

    /** */
    onFocus: Types.func,

    /** Field's label. */
    label: Types.string,

    /** Allow to recognize the field on focus. */
    type: Types.string,
}

export default InputDateHour;
