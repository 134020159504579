import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import EventItemPopoverResume from './EventItemPopoverResume';
import {
    COULEUR_BLEU_CLAIR_TRANSPARENT,
    COULEUR_BACKGROUND,
    COULEUR_FOND_VIOLET,
} from '../../../config/colors';
import {ReactComponent as ReserverSvg} from '../../../assets/reserver.svg';

class EventItemPopover extends Component {
    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        eventItem: PropTypes.object.isRequired,
        statusColor: PropTypes.string.isRequired,
        onEventClick: PropTypes.func,
    }

    render() {
        const {
            schedulerData,
            eventItem,
            statusColor,
            multipleEvents,
            headerItem,
            sallesList,
            isCreneauxDispo,
            callbackOpenModal,
            callbackAddNewEvent,
            onEventClick,
        } = this.props;
        const {
            localeMoment,
        } = schedulerData;
        const {
            id,
            title,
            start,
            end,
            resourceId,
        } = eventItem;
        const { webdev } = window;

        // On récupère la salle concerné par la ou les réservations
        const salles = sallesList?.reduce((listSalles, salle, index) => {
            if(resourceId === salle.identifiant){
                listSalles.push(salle);
            }
            return listSalles;
        }, []);

        //On récupère les réservations et on les tri dans l'ordre croissant pour l'affichage
        const reservations = headerItem.events.map(x => x.eventItem);
        const sortedReservations = reservations.sort((a,b) => moment(a.start) - moment(b.start));

        // On récupère l'heure d'ouverture de la salle ([0] car on n'en récupère qu'une)
        // Format : 800 pour 8h
        const heureDebutSalle = salles?.length <= 0
            ? undefined
            : parseInt(salles[0].heureDebut);
        // On récupère l'heure de fermeture de la salle ([0] car on n'en récupère qu'une)
        // Format : 1900 pour 19h
        const heureFinSalle = salles?.length <= 0
            ? undefined
            : parseInt(salles[0].heureFin);

        // On récupère les dates de début et de fin
        const startTime = localeMoment(start.toString());
        const endTime = localeMoment(end.toString());
        // On formate les dates et heures de début et de fin
        const startHour = startTime.format('HH:mm');
        const startDate = startTime.format('DD MMM');
        const endHour = endTime.format('HH:mm');
        const endDate = endTime.format('DD MMM');

        // On regarde si on est sur une réservation qui démarre et se termine le même jour
        const sameDate = startDate === endDate;

        // On récupère les valeurs de début et de fin de réservation (08:00 → 8000)
        const startHourNumberSplit = startHour.split(':');
        const endHourNumberSplit = endHour.split(':');
        const startHourValue = parseInt(`${startHourNumberSplit[0]}${startHourNumberSplit[1]}`);
        const endHourValue = parseInt(`${endHourNumberSplit[0]}${endHourNumberSplit[1]}`);

        // On regarde si on doit afficher le bouton d'ajout de pré-réservation (si pas créneau complet)
        //     → Si l'heure de début de réservation est supérieure à l'heure d'ouverture de la salle
        // OU  → Si l'heure de fin de réservation est inférieure à la date de fermeture de la salle
        // ► Il est possible de faire une autre réservation 
        // TODO : Gérer le cas où le paramétrage des horaires de salles n'a pas été mis en place
        // → Il faudra regarder la date de début et la de fin de réservation pour savoir si on a plus de 24h
        // ► Au quel cas on ne pourra pas ajouter de réservation
        const addingReservationIsPossible = multipleEvents && heureDebutSalle !== undefined && heureFinSalle !== undefined 
            ? (startHourValue > heureDebutSalle) || (endHourValue < heureFinSalle)
            : false;

        const hasOneIntervention = _.find(sortedReservations, ({ interventionServiceTechnique } = {}) => interventionServiceTechnique === true);

        return (
            <div
                style={{
                    color: `${COULEUR_FOND_VIOLET}`,
                    textAlign: 'center',
                }}
            >
                <Col span={24} style={{padding: '16px 12px 0 12px'}}>
                    {/* Titre dela popup de survol */}
                    <Row
                        align='middle'
                        justify='center'
                    >
                        <Col>
                            <span
                                title={title}
                                style={{
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                }}
                            >
                                {title}
                            </span>
                        </Col>
                    </Row>
                    {/* Si on a qu'une seule réservation */}
                    {!multipleEvents
                        ?
                        <EventItemPopoverResume
                            startHour={startHour}
                            endHour={endHour}
                            id={id}
                            onClick={onEventClick}
                        />
                        :
                        <div>
                            {/* Sinon si on a plusieurs réservations sur la salle*/}
                            {sortedReservations.map((event, index) => {
                                const {
                                    id,
                                    start,
                                    end,
                                    interventionServiceTechnique,
                                } = event;

                                const startTimeEvent = localeMoment(start.toString());
                                const endTimeEvent = localeMoment(end.toString());
                                // On formate les heures de début et de fin
                                const startHourEvent = startTimeEvent.format('HH:mm');
                                const endHourEvent = endTimeEvent.format('HH:mm');

                                return (
                                    <div
                                        key={id}
                                    >
                                        <EventItemPopoverResume
                                            startHour={startHourEvent}
                                            endHour={endHourEvent}
                                            id={id}
                                            onClick={onEventClick}
                                            rightGutter={hasOneIntervention}
                                            interventionServiceTechnique={interventionServiceTechnique}
                                            rowStyle={index === 0
                                                ? { paddingBottom: 0 }
                                                : null
                                            }
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    }
                </Col>
                {/* Si on est sur une réservation qui à le même jour de début et de fin */}
                {sameDate
                    ? /* On n'affiche que la date de début (= date de fin) */
                    < Row
                        align='middle'
                        justify='center'
                        gutter={[16, 0]}
                    >
                        <Col span={24}>
                            <Row
                                align='middle'
                                justify='center'
                            >
                                <div>
                                    <span
                                        style={{
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {startDate}
                                    </span>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    :
                    /* Sinon on affiche la date de début et la date de fin */
                    <Row
                        align='middle'
                        justify='space-around'
                        gutter={[16, 0]}
                    >
                        {/* Date de début */}
                        <Col span={12}>
                            <Row
                                justify='end'
                            >
                                <div>
                                    <span
                                        style={{
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {startDate}
                                    </span>
                                </div>
                            </Row>
                        </Col>
                        {/* Date de fin */}
                        <Col span={12}>
                            <Row
                                justify='start'
                            >
                                <div>
                                    <span
                                        style={{
                                            fontSize: '0.8rem',
                                        }}
                                    >
                                        {endDate}
                                    </span>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                }
                {/* Bouton d'ajout d'une nouvelle réservation (si possible) */}
                {
                    //!addingReservationIsPossible
                    !isCreneauxDispo
                        ? <></>
                        : <Row
                            justify='center'
                            style={{
                                marginTop: '0.5rem',
                                padding: 5,
                                backgroundColor: `${COULEUR_BLEU_CLAIR_TRANSPARENT}`,
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                if (webdev) {
                                    const salle = _.find(sallesList, ({ identifiant }) => identifiant === resourceId);
                        
                                    vAjouteReservation( // eslint-disable-line
                                        moment(end).format('YYYY-MM-DD HH:mm'),
                                        moment(end).add(15, 'minutes').format('YYYY-MM-DD HH:mm'),
                                        salle,
                                        false
                                    );
                                } else {
                                    callbackAddNewEvent(schedulerData, null, null, resourceId)
                                    callbackOpenModal(true)
                                }
                            }}
                        >
                            {<ReserverSvg
                                style={{
                                    marginRight: '0.5rem',
                                }}
                            />}
                            {webdev
                                ? 'Ajouter une réservation'
                                : 'Ajouter une pré-réservation'
                            }
                        </Row>
                }
            </div >
        );
    }
}

export default EventItemPopover;
