import Axios from 'axios';
import { URL_API } from '../../config/url_api';
import {
    DOWNLOAD_DOCUMENTS,
    GET_ALL_DOCUMENTS_SALLES,
} from '../constants/actionsTypes';

export const getAllDocumentsSalles = (
    identifiantEntite,
    listIdentifiantEnregistrementOrigine,
    typeDocumentMetier,
) => (dispatch) => {
    const url = `${URL_API}/api/documentInformations/search?identifiantEntite=${identifiantEntite}` +
        `&type=${typeDocumentMetier}` +
        `&identifiantEnregistrementOrigine=${listIdentifiantEnregistrementOrigine}` +
        `&perPage=50`;

    Axios.get(url).then((data) => {
        dispatch({
            type: GET_ALL_DOCUMENTS_SALLES,
            documents: data,
        });
    });
};

export const downloadDocuments = (
    idEntite,
    idSalleList,
) => async (dispatch) => {
    const url = `${URL_API}/api/documentInformations/download?idEntite=${idEntite}` +
        `&idSalleList=${idSalleList}`;

    const data = await Axios.get(
        url,
        { responseType: 'blob', },
    )
    
    dispatch({
        type: DOWNLOAD_DOCUMENTS,
        zip: data,
    });

    return data;
};
