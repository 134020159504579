import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { CellUnits } from './index'
import moment from 'moment-timezone'
import ViewTypes from './ViewTypes';

class HeaderView extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        nonAgendaCellHeaderTemplateResolver: PropTypes.func,
    }

    render() {
        const {
            schedulerData,
            nonAgendaCellHeaderTemplateResolver,
            contentScrollbarWidth,
        } = this.props;

        const {
            headers,
            cellUnit,
            config,
            localeMoment,
            viewType,
        } = schedulerData || {};
        const {
            nonAgendaDayCellHeaderFormat,
            nonAgendaOtherCellHeaderFormat,
            nonWorkingTimeHeadColor,
            nonWorkingTimeHeadBgColor,
        } = config || {};
        const headerHeight = schedulerData.getTableHeaderHeight();
        const cellWidth = schedulerData.getContentCellWidth();
        const minuteStepsInHour = schedulerData.getMinuteStepsInHour();
        const headersLength = headers?.length || 0;
        let headerList = [];
        let style = {};
        // Dans le cas ou la vue est en jour - Affichage des heures
        if (cellUnit === CellUnits.Hour) {
            headers.forEach((item, index) => {
                if (index % minuteStepsInHour === 0) {
                    const datetime = moment.utc(item.time);
                    const cellRealWidth = cellWidth * minuteStepsInHour;

                    style = !!item.nonWorkingTime
                    ? {
                            width: cellRealWidth - (contentScrollbarWidth / headersLength),
                            minWidth: cellRealWidth - (contentScrollbarWidth / headersLength),
                            color: nonWorkingTimeHeadColor,
                            backgroundColor: nonWorkingTimeHeadBgColor,
                        }
                        : {
                            width: cellRealWidth - (contentScrollbarWidth / headersLength),
                            minWidth: cellRealWidth - (contentScrollbarWidth / headersLength),
                        };

                    if (index === headersLength - minuteStepsInHour) {
                        style = !!item.nonWorkingTime ? {
                            color: nonWorkingTimeHeadColor,
                            backgroundColor: nonWorkingTimeHeadBgColor,
                        } : {};
                    }

                    let pFormattedList = nonAgendaDayCellHeaderFormat.split('|').map(item => datetime.format(item));
                    let element;
                    if (typeof nonAgendaCellHeaderTemplateResolver === 'function') {
                        element = nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style);
                    } else {
                        element = (
                            <th key={item.time} className="header3-text" style={style}>
                                <div>
                                    {
                                        pFormattedList.map((item, index) => (
                                            <div key={index}>{item}</div>
                                        ))
                                    }
                                </div>
                            </th>
                        );
                    }
                    headerList.push(element);
                }
            });
            // Sinon, la vue est en semaine ou en mois - donc affichage des jours
        } else {
            if (viewType === ViewTypes.Week) {
                headers.forEach((item, index) => {
                    // On affichera qu'un header sur deux : 
                    //   → 16/02 00:00 : OUI
                    //   → 16/02 12:00 : NON
                    //   → 17/02 00:00 : OUI ...
                    if (index % 2 === 0) {
                        const datetime = localeMoment(item.time);

                        style = !!item.nonWorkingTime
                            ? {
                                minWidth: cellWidth * 2 - (contentScrollbarWidth / (headersLength / 2)) - 2,
                                color: nonWorkingTimeHeadColor,
                                backgroundColor: nonWorkingTimeHeadBgColor,
                            } : {
                                minWidth: cellWidth * 2 - (contentScrollbarWidth / (headersLength / 2)) - 2,
                            };
                        if (index === headersLength - 1) {
                            style = !!item.nonWorkingTime ? {
                                color: nonWorkingTimeHeadColor,
                                backgroundColor: nonWorkingTimeHeadBgColor,
                            } : {};
                        }
                        let pFormattedList = nonAgendaOtherCellHeaderFormat.split('|').map((item) => datetime.format(item));
                        let element;
                        if (typeof nonAgendaCellHeaderTemplateResolver === 'function') {
                            element = nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style)
                        } else {
                            element = (
                                <th key={item.time} className="header3-text" style={style}>
                                    <div>
                                        {
                                            pFormattedList.map((item, index) => (
                                                <div key={index}>{item}</div>
                                            ))
                                        }
                                    </div>
                                </th>
                            );
                        }
                        headerList.push(element);
                    }
                });
            } else {
                // Sinon si on est en vue mois
                headerList = headers.map((item, index) => {
                    const datetime = localeMoment(item.time);

                    style = !!item.nonWorkingTime
                        ? {
                            minWidth: cellWidth - (contentScrollbarWidth / (headersLength)) - 2,
                            color: nonWorkingTimeHeadColor,
                            backgroundColor: nonWorkingTimeHeadBgColor,
                        } : {
                            minWidth: cellWidth - (contentScrollbarWidth / (headersLength)) - 2,
                        };

                    let pFormattedList = nonAgendaOtherCellHeaderFormat.split('|').map((item) => datetime.format(item));
                    if (typeof nonAgendaCellHeaderTemplateResolver === 'function') {
                        return nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style)
                    } else {
                        return (
                            <th key={item.time} className="header3-text" style={style}>
                                <div>
                                    {
                                        pFormattedList.map((item, index) => (
                                            <div key={index}>{item}</div>
                                        ))
                                    }
                                </div>
                            </th>
                        );
                    }
                });
            }
        }

        return (
            <thead>
                <tr style={{ height: headerHeight }}>
                    {headerList}
                </tr>
            </thead>
        );
    }
}

export default HeaderView
