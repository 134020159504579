/* eslint-disable no-restricted-globals */
import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Divider, Dropdown, Header, Label } from 'semantic-ui-react';
import { useToasts } from 'react-toast-notifications';

import {
    SchedulerData,
    ViewTypes,
    DATE_FORMAT
} from './components/bigScheduler/src';
import Planning from './components/Planning';
import { ModalPreReservation } from './components/Prereservation';

/** Import du store */
import { getAllSalles, getArraySalleById, getListSalleTypesReservations } from './store/actions/sallesActions';
import { getAllTypesSalles } from './store/actions/typesSallesActions';
import { getAllTypesReservations } from './store/actions/typesReservationsActions';
import { addPlanning } from './store/actions/planningsActions';
import { getAllReservants } from './store/actions/reservantsActions';
import { getParamEntite } from './store/actions/paramActions';
import { getAllTypeReservant } from './store/actions/typeReservantActions';
import { getStatutWebEnAttente } from './store/actions/statutsActions';
import { getAllTypeReservantSalle } from './store/actions/typeReservantSalleActions';
import {
    COULEUR_TYPE_SALLE,
    COULEUR_ZONE_DE_SELECTION,
} from './config/colors';
import Documents from './components/Documents/Documents';

const App = (props) => {
    const {
        addPlanning,
        getAllReservants,
        getAllTypesReservations,
        getAllTypeReservant,
        getAllTypeReservantSalle,
        getArraySalleById,
        getListSalleTypesReservations,
        getParamEntite,
        getSalles,
        getStatutWebEnAttente,
        getTypesSalles,
        param,
        sallesList,
        sallesTypesReservationsList,
        statutWebEnAttente,
        typeReservantList,
        typesReservationsList,
        typesSallesList,
    } = props;

    const { addToast } = useToasts();

    /** Paramètre issu des paramètres généraux */
    const {
        affichageMois,
        initialisationHeuresEtatsLieux,
        heureDebutDefaut,
        heureFinDefaut,
        nomCommune
    } = param;

    /** Paramètres récupérés de la génération issue de webdev */
    const {
        id_Salle: paramIdSalle,
        id_entite: paramIdEntite,
        webdev,
    } = window;

    // Identifiant de l'entité reçu en paramètre (ou 1 → Entité de la qualité)
    const idEntite = paramIdEntite || 1;
    const isPublicationEnLigne = true;
    const configDatabase = window.publication;

    // Ajoute la config de la BDD dans le header X-Database de toutes les requêtes
    Axios.interceptors.request.use(
        request => {
            if (!_.isNil(configDatabase)) {
                request.headers['X-Database'] = configDatabase;
            }

            return request;
        }
    )

    /**
     * ----------------------
     * DEFINITION DES STATES
     * ----------------------
     */

    /** state pour le modal de création et modification */
    const [openModal, setOpenModal] = React.useState(false);
    const [, setIsModalCreate] = React.useState() // true -> creation --- false -> modification
    const [, setEventUpdated] = React.useState();
    const [statutReservationId, setStatutReservationId] = React.useState();
    const [searchSalleValues, setSearchSalleValues] = React.useState([]);
    const [searchTypeSalleValue, setSearchTypeSalleValue] = React.useState(-1);
    /** state pour le modal de suppression */
    const [, setOpenDeleteModal] = React.useState(false);
    const [, setEventDeleted] = React.useState('');
    /** State pour le modal de conflit */
    const [, setOpenConflictModal] = React.useState(false);
    const [, setTypeConflict] = React.useState();
    /** State pour le modal de conflit */
    const [openModalDocuments, setOpenModalDocuments] = React.useState(false);
    /** state pour création event */
    const [start, setStart] = React.useState();
    const [end, setEnd] = React.useState();
    const [idSalle, setIdSalle] = React.useState();
    const [wasDragDrop, setWasDragDrop] = React.useState(false); // true si sélection sur plusieurs créneau


    /** Fonction qui permet de determiner les weekends dans le planning
     *  Cette fonction sera donné au schedulerData
     */
     const isNonWorkingTime = React.useCallback(() => {
        //** On retourne toujours faux : pas de déifférenciatation des weekends */
        // Sinon les couleurs définies dans style.css sont surchargées par :
        // → nonWorkingTimeHeadColor, nonWorkingTimeHeadBgColor, nonWorkingTimeBodyBgColor
        return false;
    }, []);

    /** State schedulerData + initialisation */
    const [schedulerData, setSchedulerData] = React.useState(
        new SchedulerData(new moment().format(DATE_FORMAT),
            ViewTypes.Week,
            false,
            false,
            {
                dayMaxEvents: 2,
                dayStartFrom: 6,
                weekMaxEvents: 2,
                monthMaxEvents: 2,
                resourceName: 'Salles',
                taskName: 'test',
                addMorePopoverHeaderFormat: 'dddd DD MM YYYY',
                nonAgendaDayCellHeaderFormat: 'HH:mm',
                nonAgendaOtherCellHeaderFormat: 'dddd|DD/MM',
                checkConflict: true,
                selectedAreaColor: COULEUR_ZONE_DE_SELECTION,
                groupOnlySlotColor: COULEUR_TYPE_SALLE,
                views: [
                    { viewName: 'Jour', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
                    { viewName: 'Semaine', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
                    { viewName: 'Mois', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
                ],
            },
            {
                isNonWorkingTimeFunc: isNonWorkingTime,
            }, moment));

    const {
        events,
        config,
        setViewType,
    } = schedulerData || {};

    /**
     * --------------------------
     * FIN DEFINITION DES STATES
     * --------------------------
     */

    /** Chargement du store au chargement de la page */
    React.useEffect(() => {
        if (paramIdSalle === undefined || paramIdSalle === 0) {
            getSalles(idEntite, configDatabase);
        } else {
            getArraySalleById(paramIdSalle, configDatabase);
        }
        getTypesSalles(configDatabase);
        getAllReservants(idEntite, configDatabase);
        getAllTypesReservations(idEntite, configDatabase);
        getParamEntite(idEntite, configDatabase);
        getAllTypeReservant(configDatabase);
        getStatutWebEnAttente(idEntite, configDatabase);
        getAllTypeReservantSalle(idEntite, configDatabase);
    }, [
        configDatabase,
        getSalles,
        getTypesSalles,
        getAllReservants,
        getAllTypesReservations,
        getParamEntite,
        getAllTypeReservant,
        getStatutWebEnAttente,
        getAllTypeReservantSalle,
        paramIdSalle,
        idEntite,
        getArraySalleById
    ]);

    /** Chargement de l'identifiant du statut correspondant à "réservation web en attente" */
    React.useEffect(() => {
        setStatutReservationId(statutWebEnAttente.identifiant)
    }, [statutWebEnAttente])

    React.useEffect(() => {
        let heureDebut = '00';
        let heureFin = '24';

        /** On récupère les heures */
        if (heureDebutDefaut) {
            heureDebut = heureDebutDefaut?.slice(0, 2);
        }

        if (heureFinDefaut) {
            heureFin = heureFinDefaut?.slice(0, 2);
        }

        // Changement des heures de début et de fin
        schedulerData.config.dayStartFrom = heureDebut;
        schedulerData.config.dayStopTo = heureFin;

        /** Changement d'affichage */
        if (affichageMois) {
            schedulerData.setViewType(2);
            setSchedulerData(schedulerData);
        }
    }, [
        param,
        heureDebutDefaut,
        heureFinDefaut,
        config,
        setViewType,
        schedulerData,
        affichageMois,
    ])

    // On va cherche les types de réservation associés aux salles qu'on a reçu (= "activités")
    React.useEffect(() => {
        if (sallesList !== undefined && sallesList.length > 0) {
            // On récupère la liste des ids de salles
            const sallesIdList = sallesList !== undefined
                ? sallesList.map((salle) => salle.identifiant)
                : [];
            // On va chercher tous les types de réservations des salles récupérées des props (si on a des salles)
            // /!\ La liste des ids de salles est passée sous forme de chaine : "1,2,3,...", d'où le "join"
            if (sallesIdList.length > 0) {
                getListSalleTypesReservations(sallesIdList.join(), configDatabase);
            }
        }
    }, [
        getListSalleTypesReservations,
        idEntite,
        sallesList,
        configDatabase,
    ]);

    /*
     * ------------------------------------------------------
     * FONCTIONS DE CALCUL DE CONFLIT ENTRE LES RESERVATIONS 
     * ------------------------------------------------------
     */

   /** Fonction qui vérifie si un évènement est en confilt avec un autre evenement de la liste */
   const isConflictInEvents = React.useCallback((events, start, end, resource) => {
       let res = false

       events.forEach(event => {
           if (isConflictBetweenEvents(event.start, event.end, event.resourceId, start, end, resource)) {
               res = true
           }
       });

       return res;
   }, []);

   /** Fonction qui retourne si deux evenements sont conflictuels */
   const isConflictBetweenEvents = (start1, end1, salle1, start2, end2, salle2) => {
       return (
           salle1 === salle2
           && (
               (moment(start1) >= moment(start2) && moment(start1) < moment(end2))
               || (moment(end1) > moment(start2) && moment(end1) <= moment(end2))
               || (moment(start1) <= moment(start2) && moment(end1) >= moment(end2))
           )
       );
   }

   /**
    * ----------------------------------------------------------
    * FIN FONCTIONS DE CALCUL DE CONFLIT ENTRE LES RESERVATIONS 
    * ----------------------------------------------------------
    */
    /*
     * -------------------------------------
     * FONCTIONS
     * -------------------------------------
     */

    /** Fonction qui permet d'ouvrir ou fermer le modal de création*/
    const callbackOpenModal = React.useCallback((openModal) => {
        setOpenModal(openModal);
    }, []);

    /** Fonction qui permet d'ouvrir ou fermer le modal de création*/
    const callbackOpenModalDocuments = React.useCallback((openModal) => {
        setOpenModalDocuments(openModal);
    }, []);

    /** Fonction qui permet de donner les valeurs utiles à la création d'un event */
    const callbackAddNewEvent = (sched, startParam, endParam, identifiantSalle, wasDragDrop = false) => {
        setStart(startParam);
        setEnd(endParam);
        setWasDragDrop(wasDragDrop);
        setIsModalCreate(true)
        setSchedulerData(sched);
        setIdSalle(identifiantSalle);
        setEventUpdated(null);
    };

    /** Fonction passée au modal - le modal l'utilise pour créer un event */
    const createEvent = React.useCallback((
        start,
        end,
        salleId,
        typeReservant,
        nomOrganisation,
        civilite,
        nom,
        prenom,
        numeroVoirie,
        complementNumeroVoirie, // A, C, ...
        lettre, // Bis, ter, ...
        voie,
        codePostal,
        localite,
        adresseComplete,
        phoneNumber,
        email,
        infosComplementaires
    ) => {
        // S'il y a un conflit avec une autre réservation on renvoi null
        if (isConflictInEvents(events, start, end, salleId)) {
            return;
        }

        // On initialise la réservation avec les informations récupérées
        const newReservation = {
            'identifiantSalles': salleId,
            'identifiantStatuts': statutReservationId,
            'identifiantEntite': idEntite,
            'dateCreationReservationWeb': moment().format('YYYY-MM-DD HH:mm:ss'),
            'dateDebut': moment(start).format('YYYY-MM-DD HH:mm:ss'),
            'dateFin': moment(end).format('YYYY-MM-DD HH:mm:ss'),
            'dateEtatLieuxEntree': initialisationHeuresEtatsLieux ? moment(start).format('YYYY-MM-DD HH:mm:ss') : null,
            'dateEtatLieuxSortie': initialisationHeuresEtatsLieux ? moment(end).format('YYYY-MM-DD HH:mm:ss') : null,
            'reservationLigne': 1,
            'complementInformation': infosComplementaires
        };

        /** On initialise le réservant avec les informations récupérées */
        let reservant;

        const adresse = {
            numeroVoirie,
            complementNumeroVoirie,
            lettre,
            voie,
            codePostal,
            localite,
            adresseComplete,
        };

        /** Vérification du type de réservant -- 4 = PARTICULIER --- A MODIFIER AVEC LA NOUVELLE ANALYSE*/
        if (typeReservant === 4) {
            reservant = {
                typeReservant,
                adresse,
                nom,
                prenom,
                email,
                identifiantCivilite: civilite,
                nomClassement: nom + ' ' + prenom,
                numeroPortable: phoneNumber,
                identifiantEntite: idEntite
            };
        } else {
            reservant = {
                typeReservant,
                adresse,
                email,
                identifiantCiviliteResponsable: civilite,
                nom: nomOrganisation,
                nomClassement: nomOrganisation,
                nomResponsable: nom,
                prenomResponsable: prenom,
                numeroPortable: phoneNumber,
                identifiantEntite: idEntite
            };
        }

        /** On demande à l'API de créer la réservation */
        return addPlanning({ reservation: newReservation, reservant: reservant }, configDatabase);
    }, [
        addPlanning,
        configDatabase,
        idEntite,
        initialisationHeuresEtatsLieux,
        isConflictInEvents,
        events,
        statutReservationId
    ]);

    const handleSearchTypeSalleChange = React.useCallback((e, { value }) => {
        setSearchTypeSalleValue(value)

        if (value > 0) {
            // il faut enlever les salles sélectionnées qui ne correspondent pas au type
            setSearchSalleValues(_.filter(searchSalleValues, (identifiant) => {
                let keep = false;

                const salle = _.find(sallesList, ({ identifiant: idSalle}) => idSalle === identifiant);

                if (!_.isNil(salle)) {
                    const { identifiantTypesSalle } = salle;

                    keep = identifiantTypesSalle === value
                }

                return keep;
            }))
        }
    }, [sallesList, searchSalleValues]);

    const renderLabel = React.useCallback((item, ind, propsLabel) => {
        const {
            key,
            text,
        } = item;
        const {
            onRemove,
        } = propsLabel;

        return (
            <Label
                key={key}
                content={text}
                onRemove={onRemove}
            />
        );
    }, []);

    const handleSalleChange = React.useCallback((e, { value }) => {
        if (_.size(value) > 5) {
            addToast(
                <Header as='h4'>
                    Maximum de nombre de salles sélectionnées atteint
                    <Divider style={{ marginBottom: '0.5rem' }} />
                    <Header.Subheader style={{ textAlign: 'justify' }}>
                        Enlevez une salle de la sélection pour pouvoir en ajouter une nouvelle
                    </Header.Subheader>
                </Header>,
                { appearance: 'warning', autoDismiss: true, autoDismissTimeout: 10000 }
            );
        } else {
            setSearchSalleValues(value)
        }
    }, [addToast]);

    /**
     * -----------------------------------------
     * FIN FONCTIONS
     * -----------------------------------------
     */

    return (
        <>
            {webdev && (
                <div style={{ width: '100%', marginBottom: 30 }}>
                    <div
                        className='sallesSearch'
                        style={{
                            width: '80%',
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Dropdown
                            selection
                            options={[
                                {
                                    key: 'null',
                                    value: -1,
                                    text: 'Tous les types de salle'                                            
                                },
                                ..._.map(typesSallesList, ({identifiant: id, libelle}) => ({
                                key: 'typeSalle'+id,
                                value: id,
                                text: libelle
                            }))]}
                            value={searchTypeSalleValue}
                            onChange={handleSearchTypeSalleChange}
                            style={{ width: 200, marginRight: "0.5em" }}
                        />
                        <Dropdown
                            placeholder='Saisir une salle'
                            multiple
                            search
                            selection
                            options={_.map(_.filter(
                                sallesList,
                                ({identifiantTypesSalle}) => searchTypeSalleValue === -1
                                    || (searchTypeSalleValue > 0 && identifiantTypesSalle === searchTypeSalleValue)
                                ),
                                ({ identifiant: id, nomSalle }) => ({
                                    key: 'salle'+id,
                                    value: id,
                                    text: nomSalle
                                }
                            ))}
                            clearable
                            onChange={handleSalleChange}
                            renderLabel={renderLabel}
                            value={searchSalleValues}
                            noResultsMessage={null}
                            style={{ minWidth: 200 }}
                        />
                    </div>
                </div>
            )}
            <Planning
                isPublicationEnLigne={isPublicationEnLigne}
                idEntite={idEntite}
                configDatabase={configDatabase}
                callbackOpenModal={callbackOpenModal}
                callbackOpenModalDocuments={callbackOpenModalDocuments}
                callbackAddNewEvent={callbackAddNewEvent}
                heureDebutDefaut={heureDebutDefaut}
                heureFinDefaut={heureFinDefaut}
                optionsSelectTypesSalles={typesSallesList}
                optionsSelectTypesReservants={typeReservantList}
                sallesList={sallesList}
                sallesTypesReservationsList={sallesTypesReservationsList}
                schedulerData={schedulerData}
                setEventUpdated={setEventUpdated}
                setEventDeleted={setEventDeleted}
                setIsModalCreate={setIsModalCreate}
                setOpenConflictModal={setOpenConflictModal}
                setOpenDeleteModal={setOpenDeleteModal}
                setSchedulerData={setSchedulerData}
                setTypeConflict={setTypeConflict}
                typesSallesList={typesSallesList}
                typesReservationsList={typesReservationsList}
                searchSalleValues={searchSalleValues}
                searchTypeSalleValue={searchTypeSalleValue}
            />
            {/* Affichage des Modals */}
            <ModalPreReservation
                open={openModal}
                setOpenModal={setOpenModal}
                createEvent={createEvent}
                startDate={start}
                endDate={wasDragDrop || schedulerData.viewType === 0 ? end : null}
                schedulerData={schedulerData}
                configDatabase={configDatabase}
                idEntite={idEntite}
                identifiantSalle={idSalle}
            />
            <Documents
                openModalDocuments={openModalDocuments}
                setOpenModalDocuments={setOpenModalDocuments}
                idEntite={idEntite}
                configDatabase={configDatabase}
                sallesList={sallesList}
                typesSallesList={typesSallesList} 
            />
        </>
    );
}

export default connect(
    (state) => ({
        allTypeReservantSalle: state.typeReservantSalleReducer?.allTypeReservantSalle,
        param: state.paramReducer?.param,
        sallesList: state.sallesReducer?.sallesList,
        sallesTypesReservationsList: state.sallesReducer?.sallesTypesReservationsList,
        statutWebEnAttente: state.statutsReducer?.statutWebEnAttente,
        typeReservantList: state.typeReservantReducer?.typeReservantList,
        typesReservationsList: state.typesReservationsReducer?.typesReservationsList,
        typesSallesList: state.typesSallesReducer?.typesSallesList,
    }),
    (dispatch) => bindActionCreators({
        addPlanning: addPlanning,
        getParamEntite: getParamEntite,
        getAllReservants: getAllReservants,
        getAllTypeReservant: getAllTypeReservant,
        getAllTypeReservantSalle: getAllTypeReservantSalle,
        getAllTypesReservations: getAllTypesReservations,
        getArraySalleById: getArraySalleById,
        getListSalleTypesReservations: getListSalleTypesReservations,
        getSalles: getAllSalles,
        getStatutWebEnAttente: getStatutWebEnAttente,
        getTypesSalles: getAllTypesSalles,
    }, dispatch),
)(App)