import { combineReducers } from 'redux';
import sallesReducer from './sallesReducer';
import typesSallesReducer from './typesSallesReducer';
import planningsReducer from './planningsReducer';
import reservantsReducer from './reservantsReducer';
import typesReservationsReducer from './typesReservationsReducer';
import paramReducer from './paramReducer';
import typeReservantSalleReducer from './typeReservantSalleReducer';
import typeReservantReducer from './typeReservantReducer';
import statutsReducer from './statutsReducer';
import docInfoReducer from './docInfoReducer';

export default combineReducers({ 
    sallesReducer,
    typesSallesReducer,
    planningsReducer,
    reservantsReducer,
    typesReservationsReducer,
    paramReducer,
    typeReservantSalleReducer,
    typeReservantReducer,
    statutsReducer,
    docInfoReducer,
});
