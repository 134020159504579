/* eslint-disable no-restricted-globals */
import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/fr';

import { isSameDay } from 'react-dates';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
    Row,
} from 'antd';
import {
    Dimmer,
    Loader
} from 'semantic-ui-react';

import MenuBar from './MenuBar';
import Legend from './Legend';
import {
    getRangePlannings
} from '../store/actions/planningsActions';
import Scheduler, {
    ViewTypes,
    AddMorePopover
} from './bigScheduler/src';
import {
    COULEUR_INDISPONIBILITE,
    COULEUR_RESERVATION,
    COULEUR_TEXTE_TODAY,
    COULEUR_FOND_VIOLET,
    COULEUR_BACKGROUND,
    COULEUR_VERT,
    COULEUR_VERT_CLAIR,
    COULEUR_BLEU,
    COULEUR_BLEU_FONCE,
    COULEUR_BLEU_CLAIR,
} from '../config/colors';

class Planning extends Component {
    constructor(props) {
        super(props);
        this.callbackOpenModal = props.callbackOpenModal;
        this.callbackOpenModalDocuments = props.callbackOpenModalDocuments;
        this.callbackAddNewEvent = props.callbackAddNewEvent;
        this.configDatabase = props.configDatabase;
        this.getRangePlannings = props.getRangePlannings;
        this.idEntite = props.idEntite;
        this.isPublicationEnLigne = props.isPublicationEnLigne;
        this.setEventDeleted = props.setEventDeleted;
        this.setEventUpdated = props.setEventUpdated;
        this.setIsModalCreate = props.setIsModalCreate;
        this.setOpenConflictModal = props.setOpenConflictModal;
        this.setOpenDeleteModal = props.setOpenDeleteModal;
        this.setSchedulerData = props.setSchedulerData;
        this.setTypeConflict = props.setTypeConflict;
        this.schedulerData = props.schedulerData;
        this.schedulerData.setLocaleMoment(moment);
        this.state = {
            viewModel: this.schedulerData,
            formatedResources: [],
            formatedReservations: [],
        }

        const { webdev } = window;

        if (webdev) {
            this.getRangePlannings(
                moment(this.schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD'),
                moment(this.schedulerData.endDate).add(1, 'months').format('YYYY-MM-DD'),
                this.idEntite,
                this.configDatabase
            );
        } else {
            this.getRangePlannings(
                this.isPublicationEnLigne ?
                    moment().format('YYYY-MM-DD') :
                    moment(this.schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD'),
                moment(this.schedulerData.endDate).add(1, 'months').format('YYYY-MM-DD'),
                this.idEntite,
                this.configDatabase
            );
        }

        this.setSchedulerData(this.schedulerData);
        this.lastDateMax = null;
        this.infinitePlanning = false;
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            loadedPlannings,
            sallesList,
            typesSallesList,
        } = this.props;
        const {
            formatedResources,
            formatedReservations,
        } = this.state;

        // Tableau contenant les couleurs du planning
        const colors = [
            COULEUR_VERT_CLAIR,
            COULEUR_VERT,
            COULEUR_BLEU,
            COULEUR_BLEU_FONCE,
            COULEUR_BLEU_CLAIR,
        ];

        if (
            !_.isEqual(typesSallesList, prevProps.typesSallesList)
            || !_.isEqual(sallesList, prevProps.sallesList)
            || !_.isEqual(loadedPlannings, prevProps.loadedPlannings)
        ) {
            this.setData(colors, typesSallesList, sallesList, loadedPlannings);
        }

        if (!_.isEqual(formatedResources, prevState.formatedResources)) {
            this.schedulerData.setResources(formatedResources);
        }

        if (!_.isEqual(formatedReservations, prevState.formatedReservations)) {
            this.schedulerData.setEvents(formatedReservations);
        }
    }

    /**
     * -------------------------------
     * FONCTIONS A DONNER AU PLANNING
     * -------------------------------
     */

    /** Fonction qui permet de reculer dans le planning */
    prevClick = (schedulerData) => {
        this.getRangePlannings(
            this.isPublicationEnLigne ?
                moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ?
                    moment().format('YYYY-MM-DD') :
                    moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD') :
                moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD'),
            moment(schedulerData.endDate).add(1, 'months').format('YYYY-MM-DD'),
            this.idEntite,
            this.configDatabase,
        );
        const events = schedulerData.events;
        schedulerData.prev();
        schedulerData.setEvents(events);
        this.setState({
            viewModel: schedulerData,
        })
        this.setSchedulerData(schedulerData);
    }

    /** Fonction qui permet d'avancer dans le planning */
    nextClick = (schedulerData) => {
        this.getRangePlannings(
            this.isPublicationEnLigne
                ? moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                    ? moment().format('YYYY-MM-DD')
                    : moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD')
                : moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD'),
            moment(schedulerData.endDate).add(1, 'months').format('YYYY-MM-DD'),
            this.idEntite,
            this.configDatabase,
        );
        const events = schedulerData.events;
        schedulerData.next();
        schedulerData.setEvents(events);
        this.setState({
            viewModel: schedulerData,
        })
        this.setSchedulerData(schedulerData);
    }

    /** Fonction qui permet le retour au jour actuel */
    todayClick = (schedulerData) => {
        const events = schedulerData.events;
        schedulerData.setDate();
        schedulerData.setEvents(events);
        this.setState({
            viewModel: schedulerData,
        })
        this.setSchedulerData(schedulerData);
    }

    /** Fonction qui permet le changement de vue */
    onViewChange = (schedulerData, view) => {
        const events = schedulerData.events;
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(events);
        this.setState({
            viewModel: schedulerData,
        });
        this.setSchedulerData(schedulerData);
    }

    /** Fonction qui permet de selectionner une date */
    onSelectDate = (schedulerData, date) => {
        this.getRangePlannings(
            this.isPublicationEnLigne
                ? moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')
                    ? moment().format('YYYY-MM-DD')
                    : moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD')
                : moment(schedulerData.startDate).subtract(1, 'months').format('YYYY-MM-DD'),
            moment(schedulerData.endDate).add(1, 'months').format('YYYY-MM-DD'),
            this.idEntite,
            this.configDatabase,
        );
        const events = schedulerData.events;
        schedulerData.setEvents(events);
        schedulerData.setViewType(0);
        schedulerData.setDate(date);
        
        this.setState({
            viewModel: schedulerData,
        }, () => {
            this.setSchedulerData(schedulerData);
        });
    }

    /** Fonction déclenchée lors d'un click sur un event */
    eventClicked = (schedulerData, event) => {
        alert(`You just clicked an event: {id: ${event.id}, title: ${event.title}}`);
    };

    /** Fonction qui permet d'ouvrir le formulaire de modification */
    updateEvent = (schedulerData, event) => {
        this.setIsModalCreate(false);
        this.callbackOpenModal(true);
        this.setEventUpdated(event);
        this.callbackAddNewEvent(schedulerData, event.start, event.end, event.resourceId);
    };

    /** Fonction qui permet d'ouvrir le modal de suppression */
    deleteEvent = (schedulerData, event) => {
        this.setEventDeleted(event);
        this.setOpenDeleteModal(true);
    };

    /** Fonction de création d'un event */
    newEvent = (schedulerData, salleId, slotName, start, end, isDragDrop = false) => {
        const {
            sallesList 
        } = this.props;
        const { webdev } = window;

        if (webdev) {
            const salle = _.find(sallesList, ({ identifiant }) => identifiant === salleId);

            vAjouteReservation(start, end, salle, isDragDrop); // eslint-disable-line
        } else {
            this.callbackAddNewEvent(schedulerData, start, end, salleId, isDragDrop);
            this.setIsModalCreate(true);
            this.callbackOpenModal(true);
        }
    }

    /** Fonction appelée lors d'un drag&drop sur le départ d'un évènement */
    updateEventStart = (schedulerData, event, newStart) => {
        if (confirm(`Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`)) {
            schedulerData.updateEventStart(event, new Date(newStart));
        }
        this.setState({
            viewModel: schedulerData,
        });
        this.setSchedulerData(schedulerData);
    }

    /** Fonction appelée lors d'un drag&drop sur la fin d'un évènement */
    updateEventEnd = (schedulerData, event, newEnd) => {
        if (confirm(`Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`)) {
            schedulerData.updateEventEnd(event, new Date(newEnd));
        }
        this.setState({
            viewModel: schedulerData,
        });
        this.setSchedulerData(schedulerData);
    }

    /** Fonction appelée lors d'un déplacement en drag&drop d'un évènement */
    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        if (confirm(`Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`)) {
            schedulerData.moveEvent(event, slotId, slotName, new Date(start), new Date(end));
            this.setState({
                viewModel: schedulerData,
            });
            this.setSchedulerData(schedulerData);
        }
    }

    /** Fonction appelée si l'on crée un évènement par le drag&drop mais que le créneau est déjà pris --- déclenche le modal de conflit */
    conflictOccurred = (
        schedulerData,
        action,
        event,
        type,
        slotId,
        slotName,
        start,
        end
    ) => {
        this.setOpenConflictModal(true);
        this.setTypeConflict(action);
    }

    toggleExpandFunc = (
        schedulerData,
        slotId
    ) => {
        schedulerData.toggleExpandStatus(slotId);
        this.setState({
            viewModel: schedulerData,
        });
        this.setSchedulerData(schedulerData);
    }

    /** Fonction qui permet de personnaliser l'affichage du header du planning */
    nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
        let datetime = schedulerData.localeMoment(item.time);
        let isCurrentDate;
        style.fontSize = '1.2rem';
        let className = '';
        /** Permet de vérifier si il s'agit du jour ou de l'heure actuelle */
        if (schedulerData.viewType === ViewTypes.Day) {
            isCurrentDate = datetime.isSame(new Date(), 'hour');
        }
        else {
            isCurrentDate = datetime.isSame(new Date(), 'day');
            className = 'dayHeader';
        }
        if (isCurrentDate) {
            style.backgroundColor = `${COULEUR_FOND_VIOLET}`; //#118dea
            style.color = `${COULEUR_TEXTE_TODAY}`;
        } else {
            style.backgroundColor = `${COULEUR_BACKGROUND}`; //#118dea
            style.color = `${COULEUR_FOND_VIOLET}`;
        }
        /** Permet de désactiver le click sur le header quand le jour en question dépassse la date maximum de toutes les salles */
        let onClick;
        if (
            (moment(item.time, 'YYYY-MM-DD HH:mm') >= this.lastDateMax && !this.infinitePlanning) // cas ou toutes les dates max sont dépassées
            || schedulerData.viewType === ViewTypes.Day // cas où on est en mode jour
            || (this.isPublicationEnLigne && moment(item.time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) // cas ou il n'y a pas de retour arrière
        ) {
            className = ''
            onClick = () => { }
        } else {
            className = 'dayHeader';
            onClick = () => {
                this.handleClickHeader(schedulerData, item);
            }
        }

        return (
            <th
                key={item.time}
                className={`header3-text ${className}`}
                style={style}
                onClick={onClick}
            >
                {
                    formattedDateItems.map((formattedItem, index) => (
                        <div key={index}
                            dangerouslySetInnerHTML={{ __html: formattedItem.replace(/[0-9]/g, '<b>$&</b>') }}
                        />
                    ))
                }
            </th>
        );
    }

    /** Fonction déclenchée pour afficher plus d'évenement dans une cellule du planning */
    onSetAddMoreState = (newState) => {
        if (newState === undefined) {
            this.setState({
                headerItem: undefined,
                left: 0,
                top: 0,
                height: 0
            });
        } else {
            this.setState({
                ...newState,
            });
        }
    }

    /**
     * -----------------------------------
     * FIN FONCTIONS A DONNER AU PLANNING
     * -----------------------------------
     * 
     * --------------------------------------
     * FONCTIONS DE FORMATAGE + CLICK HEADER
     * --------------------------------------
     */

    /** Fonction qui permet de diriger la vue sur le jour voulu */
    handleClickHeader = (schedulerData, item) => {
        const date = item.time.split(' ')[0];

        this.onSelectDate(schedulerData, date);
    }

    /** Renvoie un tableau contenant les salles et leur groupe au bon format pour le scheduler 
     * 
     *  Format group = {
                    id: id,
                    name: nameGroup,
                    groupOnly: true
                }
     * 
        Format salle = {
                id: identifiant,
                name: nomSalle,
                parentId: identifiantTypesSalle,
                nbPersonnes: capaciteAccueil,
                nbPersonnesAssises: capaciteAccueilAssis,
                nbPersonnesDebouts: capaciteAccueilDebout
                (dateMax: Date)
            }
     * 
    */
    formatResources = (salles, typesSalleColors) => {
        const {
            typesSallesList,
            typeReservantList,
            allTypeReservantSalle,
            searchSalleValues,
            searchTypeSalleValue,
        } = this.props;
        /** Liste des salles pour l'affichage */
        let formatedResources = [];
        /** Liste des id des types de salles déjà créer */
        let groupsId = [];

        let filteredSalles = _.filter(
            salles,
            ({ identifiantTypesSalle, identifiant }) => (_.isEmpty(searchSalleValues)
            || _.includes(searchSalleValues, identifiant))
            && (searchTypeSalleValue === -1 || (searchTypeSalleValue > 0 && identifiantTypesSalle === searchTypeSalleValue))
        );

        /** On parcourt toutes les salles */
        filteredSalles.forEach((salle, index) => {
            const {
                identifiant,
                nomSalle,
                identifiantTypesSalle,
                capaciteAccueil,
                capaciteAccueilAssis,
                capaciteAccueilDebout,
                reservationWeb,
                jourOuHeure,
                nombreJourReserve,
                dateMaximumReservation,
                heureDebut,
                heureFin
            } = salle;
            /** Si le type de salle n'est pas déjà créé */
            if (!groupsId.includes(identifiantTypesSalle)) {
                // Variables pour stocker le libellé du type de salle et sa couleur
                let nameGroup = '';
                let groupColor = '';
                /** On va récupérer son libelle */
                typesSallesList.forEach(typeSalle => {
                    if (identifiantTypesSalle === typeSalle.identifiant) {
                        nameGroup = typeSalle.libelle;
                        // Si on a bien des couleurs pour le type de salle
                        if (typesSalleColors !== undefined) {
                            // On détermine la couleur du groupe
                            typesSalleColors.forEach((typeSalleColor) => {
                                if (typeSalleColor.identifiantTypeSalle === typeSalle.identifiant) {
                                    groupColor = typeSalleColor.color;
                                }
                            });
                        }
                    }
                });
                /** On créé le type de salle correspondante en tant que groupe */
                const groupId = `group_${identifiantTypesSalle}`;
                let groupObject = {
                    id: groupId,
                    name: nameGroup,
                    color: groupColor,
                    groupOnly: true,
                };
                /** On l'ajoute au tableau des salles */
                formatedResources.push(groupObject);
                /** On stocke son id dans le tableau des types de salles créés */
                groupsId.push(identifiantTypesSalle);
            }
            // Variables pour le nom et la disponibilité de la salle
            let nomResource = '';
            let isReservable = '';
            /** SI l'option indique qu'elle n'est pas réservable */
            if (!reservationWeb) {
                nomResource = `${nomSalle} (non réservable)`;
                isReservable = false;
            } else {
                /** 
                 * SINON Recherche si la salle est réservable par au moins 1 type de réservant
                 */
                /** Tableau qui contient tous les liens entre les types de réservants et la salle actuelle */
                const typeReservantSalleBySalle = allTypeReservantSalle.filter(x => x.identifiantSalle === identifiant);
                /** Tableau qui contient seulement les valeurs du paramètre "isReservable" */
                const isReservableByTypeReservant = typeReservantSalleBySalle.map(x => x.reservable);
                /** 
                 * Si la longueur du tableau est inférieure à la taille du tableau de tous les types de réservants OU que la longeur est nulle
                 * Dans ce cas elle est réservable donc le nom reste le même
                 */
                if (isReservableByTypeReservant.length < typeReservantList.length || isReservableByTypeReservant.length === 0) {
                    nomResource = nomSalle;
                    isReservable = true;
                } else {
                    /** SINON SI il y a au moins 1 true, elle est réservable */
                    if (isReservableByTypeReservant.some((x) => x === true)) {
                        nomResource = nomSalle;
                        isReservable = true;
                    } else {
                        /** SINON, il y a que des false donc pas réservable */
                        nomResource = `${nomSalle} (non réservable)`;
                        isReservable = false;
                    }
                }
            }
            let finDelai;
            switch (jourOuHeure) {
                case 1:
                    finDelai = moment().add(nombreJourReserve, 'days');
                    break;
                case 2:
                    finDelai = moment().add(nombreJourReserve, 'hours');
                    break;

                default:
                    finDelai = moment()
                    break;
            }
            let dateMax;
            const typePeriode = dateMaximumReservation.split('|')[0];
            if (typePeriode === 'DA') {
                dateMax = moment(dateMaximumReservation.split('|')[1]);
            } else {
                const nombre = dateMaximumReservation.split('|')[1];
                switch (typePeriode) {
                    case 'SE':
                        dateMax = moment().add(nombre, 'weeks');
                        break;
                    case 'MO':
                        dateMax = moment().add(nombre, 'months');
                        break;
                    case 'AN':
                        dateMax = moment().add(nombre, 'years');
                        break;
                    default:
                        break;
                }
            }
            // On construit l'identifiant du groupe parent (= type de salle associé)
            const typeSalleGroupId = `group_${identifiantTypesSalle}`;
            // On construit l'objet salle
            const salleObject = {
                id: identifiant,
                name: nomResource,
                parentId: typeSalleGroupId,
                identifiantSalle: identifiant,
                nbPersonnes: capaciteAccueil,
                nbPersonnesAssises: capaciteAccueilAssis,
                nbPersonnesDebouts: capaciteAccueilDebout,
                isReservable: isReservable,
                finDelai: finDelai,
                dateMaximum: dateMax,
                heureDebut: heureDebut,
                heureFin: heureFin
            };
            formatedResources.push(salleObject);
        });

        if (_.isEmpty(formatedResources)) {
            return [{
                id: -1,
                name: "",
            }];
        }

        return formatedResources;
    }

    /**
     * Renvoi si oui ou non la réservation doit être ignorée
     *
     * @param {*} dateFinReservation 
     * @param {*} finDelaiSalle 
     * @returns 
     */
    shouldIgnoreReservation = (reserv, salle) => {
        const {
            heureDebutDefaut,
            heureFinDefaut,
        } = this.props;
        const {
            viewType
        } = this.schedulerData || {};

        const {
            dateDebutReservation,
            dateFinReservation,
        } = reserv || {};
        const {
            heureDebut,
            heureFin,
            finDelai,
            dateMaximum,
        } = salle || {};

        if (!_.isNil(salle)) {
            const maintenant = moment();

            let horsHoraire = false;

            // Si en vue jour et sur le même jour alors il faut prendre en compte les horaires de la salle si définies
            if (viewType === ViewTypes.Day && isSameDay(dateDebutReservation, dateFinReservation)) {
                const hDeb = !_.isEmpty(heureDebut)
                    ? heureDebut
                    : !_.isEmpty(heureDebutDefaut)
                        ? heureDebutDefaut
                        : null;
                const hFin = !_.isEmpty(heureFin)
                    ? heureFin
                    : !_.isEmpty(heureFinDefaut)
                        ? heureFinDefaut
                        : null;

                let dateHeureDebutSalle = null;
                let dateHeureFinSalle = null;

                // si on a une horaire de début paramétré
                if (!_.isNil(hDeb)) {
                    dateHeureDebutSalle = moment(moment(dateFinReservation).set({
                        hour: _.toFinite(hDeb.substring(0, 2)),
                        minute: _.toFinite(hDeb.substring(2)),
                        second: 0,
                    }).toDate());

                    // Si le délai est après l'ouverture de la salle, le délai doit faire foi 
                    if (moment(finDelai).isAfter(dateHeureDebutSalle)) {
                        dateHeureDebutSalle = moment(finDelai);
                    }

                    // si la réservation finie avant l'ouverture
                    horsHoraire = moment(dateFinReservation).isSameOrBefore(dateHeureDebutSalle, 'minute');
                }

                if (!_.isNil(hFin)) {
                    dateHeureFinSalle = moment(moment(dateDebutReservation).set({
                        hour: _.toFinite(hFin.substring(0, 2)),
                        minute: _.toFinite(hFin.substring(2)),
                        second: 0,
                    }).toDate());
                    
                    // Si le délai est après la fermeture de la salle, la salle n'est plus réservable
                    if (moment(finDelai).isAfter(dateHeureFinSalle)) {
                        horsHoraire = true;
                    }

                    horsHoraire = horsHoraire || moment(dateDebutReservation).isSameOrAfter(dateHeureFinSalle, 'minute');
                }      
            }

            // On ignore forcément la réservation si :
            // - La date de fin est avant le finDelai
            const isBeforeFinDelai = !_.isNil(finDelai) && moment(dateFinReservation).isBefore(finDelai, 'minute');
            // - La date d'aujourd'hui est après la date de fin de la réservation
            const isBeforeMaintenant = moment(dateFinReservation).isBefore(maintenant, 'minute');
            // - La date de fin est avant la dateMax (paramètres généraux)
            const isAfterDateMax = !_.isNil(dateMaximum) && moment(dateFinReservation).isAfter(dateMaximum, 'minute');

            if (isBeforeFinDelai || isBeforeMaintenant || isAfterDateMax || horsHoraire) {
                return true;
            }
        }

        return false;
    }

    /**
     * Renvoi la bonne date/heure de début pour gérer les indisponibilités.
     *
     * @param {*} dateDebut 
     * @returns 
     */
    getReservationStartDate = (dateDebut, dateFin, salle) => {
        const {
            heureDebutDefaut,
        } = this.props;

        const {
            viewType
        } = this.schedulerData || {};
        
        const {
            finDelai,
            heureDebut,
        } = salle || {};


        let start = dateDebut;
        const maintenant = moment();
        maintenant.set('second', 0); // On met à 0 pour pas avoir de problème avec les isBefore etc...

        // Si on a un délai de réservation qui emmène à une date supérieure à la date actuelle alors ce sera le début de la réservation
        if (finDelai !== undefined && (moment(finDelai).isAfter(moment(dateDebut)) && moment(finDelai).isBefore(moment(dateFin)))) {
            // On veut démarrer sur la demi-heure juste avant la date
            if (viewType === ViewTypes.Day) {
                const minutesDiff = moment(finDelai).diff(moment(dateDebut), 'minutes');

                if (minutesDiff > 0) {
                    start = moment(dateDebut).add(minutesDiff, 'minutes');
                    if (start.minute() < 30) {
                        start.set('minute', 0);
                    } else {
                        start.set('minute', 30);
                    }
                }
            }

            // On veut démarrer à 0h ou 12h
            if (viewType === ViewTypes.Week) {
                const hoursDiff = moment(finDelai).diff(moment(dateDebut), 'hour');

                if (hoursDiff > 0) {
                    start = moment(dateDebut).add(hoursDiff, 'hour');
                    start.set('minute', 0);

                    if (start.hour() < 12) {
                        start.set('hour', 0);
                    } else {
                        start.set('hour', 12);
                    }
                }
            }

            // On veut démarrer à 0h
            if (viewType === ViewTypes.Month) {
                const hoursDiff = moment(finDelai).diff(moment(dateDebut), 'hour');
                if (hoursDiff > 0) {
                    start = moment(dateDebut).add(hoursDiff, 'hour');
                    start.set('minute', 0);
                    start.set('hour', 0);
                }
            }

        } else {

            // Gestion demi-heure
            if (viewType === ViewTypes.Day) {   
                const hDebut = !_.isEmpty(heureDebut)
                ? heureDebut
                : !_.isEmpty(heureDebutDefaut)
                    ? heureDebutDefaut
                    : null;

                let dateHeureDebutSalle = start;

                if (!_.isNil(hDebut)) {
                    dateHeureDebutSalle = moment(moment(start).set({
                        hour: _.toFinite(hDebut.substring(0, 2)),
                        minute: _.toFinite(hDebut.substring(2)),
                        second: 0,
                    }).toDate());
                }

                // Si la date de début est avant "dateHeureDebutSalle"
                // il va falloir modifier la date de début de la réservation pour commencer
                // juste après le "gris" d'indisponibilité (et pas avoir de superposition)
                if (moment(start).isBefore(dateHeureDebutSalle)) {
                    const creneau = moment(dateHeureDebutSalle);

                    // on enlève les minutes pour tomber sur le créneau xx:00 ou xx:30
                    creneau.subtract(
                        dateHeureDebutSalle.minute() < 30
                            ? creneau.minute()
                            : creneau.minute() % 30,
                        'minutes'
                    );

                    if (moment(start).isBefore(creneau, 'hour')) {
                        start = creneau;
                    }
                }
            }

            // Gestion demi journée
            if (viewType === ViewTypes.Week) {
                // Si la date de début est avant "maintenant"
                // il va falloir modifier la date de début de la réservation pour commencer
                // juste après le "gris" d'indisponibilité (et pas avoir de superposition)
                if (moment(start).isBefore(maintenant, 'day')) {
                    start = maintenant;

                    start.set('minute', 0);

                    if (maintenant.hour() < 12) {
                        start.set('hour', 0);
                    } else {
                        start.set('hour', 12);
                    }
                }
            }

            // Gestion journée
            if (viewType === ViewTypes.Month) {
                // Si la date de début est avant "maintenant"
                // il va falloir modifier la date de début de la réservation pour commencer
                // juste après le "gris" d'indisponibilité (et pas avoir de superposition)
                if (moment(start).isBefore(maintenant, 'day')) {
                    start = maintenant;

                    start.set('minute', 0);
                    start.set('hour', 0);
                }
            }

        }

        return start;
    }

    /**
     * Renvoi la bonne date/heure de fin pour gérer les indisponibilités.
     *
     * @param {*} dateFin 
     * @returns 
     */
    getReservationEndDate = (dateFinReservation, salle) => {
        const {
            heureFinDefaut,
        } = this.props;
        const {
            viewType
        } = this.schedulerData || {};

        const {
            heureFin,
            dateMaximum,
        } = salle || {};

        let end = moment(dateFinReservation);

        const hFin = !_.isEmpty(heureFin)
            ? heureFin
            : !_.isEmpty(heureFinDefaut)
                ? heureFinDefaut
                : null;

        let dateHeureFinSalle = null;
                
        if (!_.isNil(hFin)) {
            dateHeureFinSalle = moment(moment(dateFinReservation).set({
                hour: _.toFinite(hFin.substring(0, 2)),
                minute: _.toFinite(hFin.substring(2)),
                second: 0,
            }).toDate());

            if (dateHeureFinSalle.isAfter(moment(dateMaximum, 'minute'))) {
                dateHeureFinSalle = moment(dateMaximum);
            }
        }

        // Gestion demi-heure
        if (viewType === ViewTypes.Day) {
            // Si la date de fin est après la fermeture
            // il va falloir modifier la date de fin de la réservation pour terminer
            // juste avant le "gris" d'indisponibilité (et pas avoir de superposition)
            if (moment(end).isAfter(dateHeureFinSalle, 'minute')) {
                const creneau = moment(dateHeureFinSalle);

                // on enlève les minutes pour tomber sur le créneau xx:00 ou xx:30
                creneau.subtract(
                    dateHeureFinSalle.minute() < 30
                        ? dateHeureFinSalle.minute()
                        : dateHeureFinSalle.minute() % 30,
                    'minutes'
                );

                end = creneau;
            }
        }

        return end;
    }

    /** Fonction qui permet de mettre au bon format les réservations
     *  Renvoie un tableau d'objets
     *  Format reservation = {
                id: identifiant,
                start: dateDebut,
                end: dateFin,
                resourceId: identifiantSalles,
                title: 'Réservé'
            }
     */
    formatReservations(reservations, salles, sallesColors) {
        const {
            reservantsList,
            allTypeReservantSalle,
        } = this.props;
        const { webdev } = window;

        let formatedReservations = [];

        // si c'est null, l'API a pas encore répondu, ou a fail.
        // on veut faire la différence entre [] et null pour afficher quand même
        // le planning si on a aucune réservation
        if (reservations) {
            reservations.forEach((reservation) => {
                // On récupère les infos de la réservation
                const {
                    identifiant,
                    identifiantSalles,
                    identifiantReservant,
                    dateDebut,
                    dateFin,
                    interventionServiceTechnique,
                } = reservation;

                const salle = salles?.find(({ id }) => id === identifiantSalles);

                const shouldIgnore = !webdev && this.shouldIgnoreReservation(
                    {
                        dateDebutReservation: dateDebut,
                        dateFinReservation: dateFin,
                    },
                    salle
                );
                
                // Si la réservation est sur un créneau qui est toujours disponible
                if (!shouldIgnore) {    
                    let start = dateDebut;
                    let end = dateFin;
                    
                    if (!webdev) {
                        start = this.getReservationStartDate(dateDebut, dateFin, salle);
                        end = this.getReservationEndDate(dateFin, salle);
                    }

                    // On initialise le libellé de la réservation et sa couleur
                    let title = 'Réservé';
                    let color = '';

                    // On parcourt les réservants pour trouver le nom celui de la réservation
                    reservantsList.forEach((reservant) => {
                        // On récupère les infos du réservant
                        const {
                            identifiant: idReservant,
                            nom,
                            typeReservant,
                        } = reservant

                        // Si on est sur le réservant de concerné par la réservation
                        if (idReservant === identifiantReservant) {
                            // On parcourt les types réservant salles
                            allTypeReservantSalle.forEach(typeResSalle => {
                                if (typeResSalle.identifiantTypeReservant === typeReservant && typeResSalle.identifiantSalle === identifiantSalles) {
                                    // On vérifie que le type de salle n'est pas paramétré comme anonymisé
                                    // Et qu'il ne s'agit pas d'un particouler (automatiquement anonymisé)
                                    if (!typeResSalle.anonyme && typeReservant !== 4) {
                                        title = title + ' par ' + nom;
                                    }
                                }
                            });
                        }
                    });

                    // Si on a bien des couleurs pour les salles
                    if (sallesColors !== undefined) {
                        // On parcourt le tableau des salles et couleurs pour trouver la couleur de la salle pour la réservation
                        sallesColors.forEach((salleColor) => {
                            // Si on est sur la salle de la réservation alors on MAJ la couleur
                            if (salleColor.identifiantSalle === identifiantSalles) {
                                color = salleColor.color;
                            }
                        });
                    }

                    // Si on a pas trouvé de couleur alors on met celle par défaut
                    const reservationColor = color !== undefined && color !== ''
                        ? color
                        : COULEUR_RESERVATION;

                    // On ajoute l'objet réservation
                    formatedReservations.push({
                        id: identifiant,
                        start,
                        end,
                        interventionServiceTechnique,
                        resourceId: identifiantSalles,
                        title: title,
                        reservantId: identifiantReservant,
                        movable: false,
                        resizable: false,
                        bgColor: reservationColor,
                    });
                }
            });
        }

        return formatedReservations;
    }

    checkMaxReservations() {
        /** Fonction qui permet de stocker la date max de toutes les salles et ainsi de savoir si le planning doit être infini ou bloqué dans le temps */
        this.schedulerData.resources.forEach(element => {
            const {
                groupOnly,
                dateMaximum,
            } = element;
            if (!groupOnly && dateMaximum) {

                if (this.lastDateMax === null || dateMaximum > this.lastDateMax) {
                    this.lastDateMax = dateMaximum
                }
            } else {
                this.infinitePlanning = true;
            }
        });
    }

    setData = (colors, typesSallesList, sallesList, loadedPlannings) => {
        let typesSallesColors = [];

        // On parcourt les types de salles 
        typesSallesList.forEach((typeSalle, index) => {
            const groupColor = colors[index % colors.length];
            // Pour chaque type de salle on attribue une couleur
            typesSallesColors.push({
                identifiantTypeSalle: typeSalle.identifiant,
                color: groupColor,
            });
        });

        /** Mise au bon format des salles */
        const formatedResources = this.formatResources(sallesList, typesSallesColors);

        /** Mise au bon format des réservations */
        const sallesColors = this.getSallesColors(typesSallesColors, sallesList);
        const formatedReservations = this.formatReservations(loadedPlannings, formatedResources, sallesColors);

        this.setState((prev) => ({
            ...prev,
            formatedResources,
            formatedReservations,
        }));
    }

    // Fonction qui permet d'associer les salles et les couleurs
    getSallesColors(typesSalleColors, sallesList) {
        let sallesColors = [];
        // On parcourt les types de salles et leur couleur
        typesSalleColors.forEach((typeSalleColor) => {
            const {
                identifiantTypeSalle,
                color,
            } = typeSalleColor;
            // On parcourt les salles et on récupère celles qui correspondent à l'id du type de salle
            const sallesListByTypeSalle = sallesList.reduce((listSalles, salle) => {
                // Si la salle elle correspond au type de salle
                if (salle.identifiantTypesSalle === identifiantTypeSalle) {
                    listSalles.push(salle);
                }
                return listSalles;
            }, []);
            // Si on a récupéré des salles pour le type de salle en cours
            if (sallesListByTypeSalle.length > 0) {
                // On parcourt la liste de salle
                sallesListByTypeSalle.forEach((salle) => {
                    // On ajoute chaque 
                    sallesColors.push({
                        identifiantSalle: salle.identifiant,
                        color: color,
                    })
                });
            }
        });
        return sallesColors;
    }

    // Fonction qui permet d'associer les types de réservations de salles et les détails des types de réservations
    formatListTypesReservationsBySalle(listSalles, listTypesResevations, listSallesTypesReservations) {
        let listTypesReservationsBySalle = [];
        // On vérifie qu'on a des données à traîter
        if (listTypesResevations !== undefined && listTypesResevations?.length > 0
            && listSallesTypesReservations !== undefined && listTypesResevations?.length > 0
            && listSalles !== undefined && listSalles?.length > 0) {
            // On parcourt la liste des salles pour retrouver la liste des types de réservations des chacunes
            listSalles.forEach((salle) => {
                // On récupère la liste des identifiants de types de réservations pour l'identifiant de salle en cours
                const listTypesReservationsSalleBySalle = listSallesTypesReservations.filter((salleTypeReservation) => {
                    return salleTypeReservation.identifiantSalles === salle.identifiant;
                }, []);
                // Si on a bien récupéré des identifiants de types de réservations pour l'identifiant de salle en cours
                if (listTypesReservationsSalleBySalle.length > 0) {
                    // On parcourt ce tableau pour retrouver les infos des types de réservations associées (code, lib, etc ...)
                    let listObjectTypeReservationsBySalle = [];
                    listTypesReservationsSalleBySalle.forEach((typeReservationSalleBySalle) => {
                        // On cherche les types de réservations pour l'identifiant de type de réservation en cours
                        const listObjectTypeReservationsBySalleTemp = listTypesResevations.filter((typeReservation) => {
                            return typeReservation.identifiant === typeReservationSalleBySalle.identifiantTypeReservation;
                        }, []);
                        // Si on a bien récupéré des types de réservation pour l'identifiant de type de réservation
                        if (listObjectTypeReservationsBySalleTemp.length > 0) {
                            // On l'ajoute dans le tableau des objets de types de réservations pour la salle en cours
                            listObjectTypeReservationsBySalle.push(...listObjectTypeReservationsBySalleTemp);
                        }
                    });
                    // Si on a bien récupéré des types de réservations pour la salle en cours
                    if (listObjectTypeReservationsBySalle.length > 0) {
                        // On construit un tableau avec des structures : idSalle + liste des idTypeRéservations
                        listTypesReservationsBySalle.push({
                            idSalle: salle.identifiant,
                            listTypesReservations: listObjectTypeReservationsBySalle,
                        });
                    }
                }
            });
        }
        return listTypesReservationsBySalle;
    }

    /** 
    * ------------------------------------------
    * FIN FONCTIONS DE FORMATAGE + CLICK HEADER
    * ------------------------------------------
    */

    /**
     * 
     * @param {*} schedulerData : les données du planning
     * @description Construction du  
     */
    buildLeftHeader(schedulerData) {
        const {
            idEntite,
            callbackAddNewEvent,
            callbackOpenModal,
            callbackOpenModalDocuments,
            configDatabase,
            optionsSelectTypesReservants,
            optionsSelectTypesSalles,
            sallesList,
            typesSallesList,
            typesReservationsList,
        } = this.props;

        return (
            /* On met une Row autour des trois boutons pour les grouper
            → Avec la propriété 'space-between' on écarte donc les 3 boutons de la légend */
            <Row
                style={{
                    marginLeft: '0.5rem',
                }}
            >
                <MenuBar
                    idEntite={idEntite}
                    configDatabase={configDatabase}
                    callbackOpenModal={callbackOpenModal}
                    callbackOpenModalDocuments={callbackOpenModalDocuments}
                    callbackAddNewEvent={callbackAddNewEvent}
                    sallesList={sallesList}
                    typesSallesList={typesSallesList}
                    typesReservation={typesReservationsList}
                    optionsSelectTypesSalles={optionsSelectTypesSalles}
                    optionsSelectTypesReservants={optionsSelectTypesReservants}
                    schedulerData={schedulerData}
                />
            </Row>
        );
    }

    buildRightHeader() {
        return (<Legend />);
    }

    /** Fonction de rendu */
    render() {
        const {
            allTypeReservantSalle,
            configDatabase,
            idEntite,
            loadedPlannings,
            reservantsList,
            sallesList,
            sallesTypesReservationsList,
            typesReservationsList,
            typesSallesList,
            heureDebutDefaut,
            heureFinDefaut,
        } = this.props;
        const {
            viewModel,
            headerItem,
            height,
            top,
            left,
            formatedResources,
        } = this.state;

        // On fusionne les données des salles, types de réservations et le tableau de jointure entre les deux
        // de façon à avoir un tableau [idSalle: value, listTypeReservations : [{...}, ...]]
        const sallesTypesReservationsListInfos = this.formatListTypesReservationsBySalle(
            sallesList,
            typesReservationsList,
            sallesTypesReservationsList
        );

        this.checkMaxReservations();

        let popover = headerItem !== undefined
            ? <AddMorePopover
                closeAction={this.onSetAddMoreState}
                eventItemClick={this.eventClicked}
                left={left}
                headerItem={headerItem}
                height={height}
                moveEvent={this.moveEvent}
                schedulerData={viewModel}
                top={top}
                viewEventClick={this.ops1}
                viewEvent2Click={this.ops2}
                viewEventText="Ops 1"
                viewEvent2Text="Ops 2"
            />
            : <div />;
        // Boutons de gestion : nouvelle pré-reservation, recherche et documents
        const leftCustomHeader = this.buildLeftHeader(this.schedulerData);
        // Légende du planning
        const rightCustomHeader = this.buildRightHeader();

        return formatedResources.length !== 0
            && typesSallesList.length !== 0
            && loadedPlannings !== null
            && reservantsList.length !== 0
            && allTypeReservantSalle.length !== 0
            ? (
                <div>
                    <Scheduler
                        conflictOccurred={this.conflictOccurred}
                        //eventItemClick={this.eventClicked} //Click sur les réservations
                        infinitePlanning={this.infinitePlanning} // Nouvelle prop ajoutée dans les sources
                        isNonWorkingTimeFunc={this.isNonWorkingTime}
                        isPublicationEnLigne={this.isPublicationEnLigne} //Ajout prop pour activer/desactiver le retour arriere
                        lastDateMax={this.lastDateMax} // Nouvelle prop ajoutée dans les sources
                        leftCustomHeader={leftCustomHeader}
                        moveEvent={this.moveEvent}
                        newEvent={this.newEvent}
                        nextClick={this.nextClick}
                        nonAgendaCellHeaderTemplateResolver={this.nonAgendaCellHeaderTemplateResolver}
                        onSelectDate={this.onSelectDate}
                        onSetAddMoreState={this.onSetAddMoreState}
                        onViewChange={this.onViewChange}
                        prevClick={this.prevClick}
                        rightCustomHeader={rightCustomHeader}
                        sallesList={sallesList}
                        sallesTypesReservationsListInfos={sallesTypesReservationsListInfos}
                        schedulerData={viewModel}
                        todayClick={this.todayClick} // Nouvelle prop ajoutée dans les sources
                        toggleExpandFunc={this.toggleExpandFunc}
                        typesSallesList={typesSallesList}
                        updateEventEnd={this.updateEventEnd}
                        viewEventClick={this.updateEvent}
                        //viewEventText="Modifier" //Bouton sur popup résa
                        //viewEvent2Text="Supprimer" //Bouton sur popup résa
                        viewEvent2Click={this.deleteEvent}
                        updateEventStart={this.updateEventStart}
                        callbackOpenModal={this.callbackOpenModal} //nouvelle prop pour ouvrir le modal de création résa
                        callbackAddNewEvent={this.callbackAddNewEvent} //nouvelle prop pour initialiser les infos du formulaire
                        heureDebutDefaut={heureDebutDefaut}//heure de début d'affichage des paramètre généraux
                        heureFinDefaut={heureFinDefaut}//heure de fin d'affichage des paramètre généraux
                        configDatabase={configDatabase}
                        idEntite={idEntite}
                    />
                    {popover}
                </div>
            )
            : (
                <Dimmer active inverted>
                    <Loader size='big'>Chargement du planning...</Loader>
                </Dimmer>
            );
    }
}

export default connect(
    (state) => ({
        sallesList: state.sallesReducer?.sallesList,
        typesSallesList: state.typesSallesReducer?.typesSallesList,
        loadedPlannings: state.planningsReducer?.loadedPlannings,
        reservantsList: state.reservantsReducer?.reservantsList,
        allTypeReservantSalle: state.typeReservantSalleReducer?.allTypeReservantSalle,
        typeReservantList: state.typeReservantReducer?.typeReservantList,
    }),
    (dispatch) => bindActionCreators({
        getRangePlannings: getRangePlannings,
    }, dispatch),
)(DragDropContext(HTML5Backend)(Planning));