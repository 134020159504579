import React from 'react';

import Types from '360-types';

import { Icon } from 'semantic-ui-react';

const CalendarNavIcon = (props) => {
    const {
        direction,
    } = props;

    return (
        <Icon
            link
            name={'chevron circle ' + direction}
            size='large'
            style={{
                top: 22,
                [direction]: 22,
                position: 'absolute',
                color: '#5270a9',
            }}
        />
    );
};

CalendarNavIcon.propTypes = {
    /** Arrow direction. */
    direction: Types.oneOf(['left', 'right'])
}

CalendarNavIcon.defaultProps = {
    direction: 'left',
}

export default CalendarNavIcon;
