import React from 'react';

import { Button as ButtonSemantic } from 'semantic-ui-react';

const Button = (props) => {
    const {
        ...rest
    } = props;

    return (
        <ButtonSemantic
            type='button' // pour éviter le refresh webdev
            {...rest}
        />
    );
};

export default Button;
