import React from 'react';

import {
    Icon,
    Dimmer,
    Loader,
    Grid,
} from 'semantic-ui-react';

import Button from '../Button';

const ModalPreReservationFinalMessage = (props) => {
    const {
        isPending,
        isLoading,
        isReservationError,
        onEndReservationClick,
    } = props;

    return (
        <>
            <Dimmer active={isPending} inverted>
                {isLoading && (<Loader size='big' />)}
                {!isLoading && (
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column width={3} verticalAlign='middle' textAlign='right'>
                                <Icon
                                    size='huge'
                                    name={isReservationError ? 'warning circle' : 'checkmark'}
                                    color={isReservationError ? 'red' : 'green'}
                                />
                            </Grid.Column>
                            <Grid.Column width={13} verticalAlign='middle' textAlign='left'>
                                <span style={{
                                    color: isReservationError ? 'red' : 'green',
                                    fontSize: '1.5rem',
                                    whiteSpace: 'pre-line',
                                }}>
                                    {isReservationError
                                        ? `Une erreur est survenue : impossible d'effectuer la pré-réservation.`
                                        : `Nous avons bien réceptionné votre demande de réservation de la salle.\n
                                        Un mail précisant l'acceptation ou le refus de votre demande vous sera envoyé dès son traitement par nos services.`}
                                </span>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered style={{ marginTop: '3rem' }}>
                            <Button
                                basic
                                size='tiny'
                                content={isReservationError ? 'Reprendre la saisie' : 'Revenir sur le planning'}
                                icon={isReservationError ? 'angle left' : 'angle right'}
                                labelPosition={isReservationError ? 'left' : 'right'}
                                onClick={onEndReservationClick}
                            />
                        </Grid.Row>
                    </Grid>
                )}
            </Dimmer>
        </>
    );
}

export default ModalPreReservationFinalMessage;