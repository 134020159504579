import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment-timezone';

import { PropTypes } from 'prop-types';

import {
    Icon,
} from 'semantic-ui-react';

import Popup from '../../Popup/Popup';
import EventItemPopover from './EventItemPopover';
import { CellUnits, DATETIME_FORMAT } from './index';
import { DnDTypes } from './DnDTypes';
import ViewTypes from './ViewTypes';

const supportTouch = 'ontouchstart' in window;

class EventItem extends Component {
    constructor(props) {
        super(props);

        const { left, top, width } = props;
        this.state = {
            left: left,
            top: top,
            width: width,
            isOpen: false
        };
        this.startResizer = null;
        this.endResizer = null;
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        eventItem: PropTypes.object.isRequired,
        isStart: PropTypes.bool.isRequired,
        isEnd: PropTypes.bool.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
        isInPopover: PropTypes.bool.isRequired,
        leftIndex: PropTypes.number.isRequired,
        rightIndex: PropTypes.number.isRequired,
        isDragging: PropTypes.bool.isRequired,
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        conflictOccurred: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
    }

    UNSAFE_componentWillReceiveProps(np) {
        const {
            left,
            top,
            width,
        } = np;
        this.setState({
            left: left,
            top: top,
            width: width,
        });
        this.subscribeResizeEvent(np);
    }

    componentDidMount() {
        this.subscribeResizeEvent(this.props);
    }

    initStartDrag = (ev) => {
        const {
            schedulerData,
            eventItem
        } = this.props;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slot = schedulerData.getSlotById(slotId);
        if (!!slot && !!slot.groupOnly) {
            return;
        }
        if (schedulerData._isResizing()) {
            return;
        }
        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }
        this.setState({
            startX: clientX
        });
        schedulerData._startResizing();
        if (supportTouch) {
            this.startResizer.addEventListener('touchmove', this.doStartDrag, false);
            this.startResizer.addEventListener('touchend', this.stopStartDrag, false);
            this.startResizer.addEventListener('touchcancel', this.cancelStartDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doStartDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopStartDrag, false);
        }
        document.onselectstart = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
    }

    doStartDrag = (ev) => {
        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            left,
            width,
            leftIndex,
            rightIndex,
            schedulerData,
        } = this.props;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = rightIndex * cellWidth - offset;
        const { startX } = this.state;
        let newLeft = left + clientX - startX;
        let newWidth = width + startX - clientX;
        if (newWidth < minWidth) {
            newWidth = minWidth;
            newLeft = (rightIndex - 1) * cellWidth + (rightIndex - 1 > 0 ? 2 : 3);
        }
        else if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newLeft = 3;
        }
        this.setState({ left: newLeft, width: newWidth });
    }

    stopStartDrag = (ev) => {
        ev.stopPropagation();
        if (supportTouch) {
            this.startResizer.removeEventListener('touchmove', this.doStartDrag, false);
            this.startResizer.removeEventListener('touchend', this.stopStartDrag, false);
            this.startResizer.removeEventListener('touchcancel', this.cancelStartDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doStartDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopStartDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            width,
            left,
            top,
            leftIndex,
            rightIndex,
            schedulerData,
            eventItem,
            updateEventStart,
            conflictOccurred,
        } = this.props;
        schedulerData._stopResizing();
        if (this.state.width === width) {
            return;
        }
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) {
                this.setState({
                    left: left,
                    top: top,
                    width: width,
                });
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            cellUnit,
            events,
            config,
            localeMoment,
        } = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = rightIndex * cellWidth - offset;
        const { startX } = this.state;
        let newWidth = width + startX - clientX;
        let deltaX = clientX - startX;
        let sign = deltaX < 0
            ? -1
            : (deltaX === 0 ? 0 : 1);
        let count = (sign > 0
            ? Math.floor(Math.abs(deltaX) / cellWidth)
            : Math.ceil(Math.abs(deltaX) / cellWidth)) * sign;
        if (newWidth < minWidth) {
            count = rightIndex - leftIndex - 1;
        } else if (newWidth > maxWidth) {
            count = -leftIndex;
        }
        let newStart = moment.utc(eventItem.start).add(
            cellUnit === CellUnits.Hour
                ? count * config.minuteStep
                : count,
            cellUnit === CellUnits.Hour
                ? 'minutes'
                : 'days'
        ).format(DATETIME_FORMAT);
        if (count !== 0 && cellUnit !== CellUnits.Hour && config.displayWeekend === false) {
            if (count > 0) {
                let tempCount = 0, i = 0;
                while (true) {
                    i++;
                    let tempStart = moment.utc(eventItem.start).add(i, 'days');
                    let dayOfWeek = tempStart.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount++;
                        if (tempCount === count) {
                            newStart = tempStart.format(DATETIME_FORMAT);
                            break;
                        }
                    }

                }
            } else {
                let tempCount = 0, i = 0;
                while (true) {
                    i--;
                    let tempStart = moment.utc(eventItem.start).add(i, 'days');
                    let dayOfWeek = tempStart.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount--;
                        if (tempCount === count) {
                            newStart = tempStart.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            }
        }
        let hasConflict = false;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slotName = undefined;
        let slot = schedulerData.getSlotById(slotId);
        if (!!slot)
            slotName = slot.name;
        if (config.checkConflict) {
            const start = moment.utc(newStart);
            const end = moment.utc(eventItem.end);
            events.forEach((e) => {
                if (schedulerData._getEventSlotId(e) === slotId && e.id !== eventItem.id) {
                    let eStart = moment.utc(e.start),
                        eEnd = moment.utc(e.end);
                    if ((start >= eStart && start < eEnd)
                        || (end > eStart && end <= eEnd)
                        || (eStart >= start && eStart < end)
                        || (eEnd > start && eEnd <= end)) {
                        hasConflict = true;
                    }
                }
            });
        }
        if (hasConflict) {
            this.setState({
                left: left,
                top: top,
                width: width,
            });
            if (conflictOccurred !== undefined) {
                conflictOccurred(schedulerData, 'StartResize', eventItem, DnDTypes.EVENT, slotId, slotName, newStart, eventItem.end);
            }
            else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
            this.subscribeResizeEvent(this.props);
        }
        else {
            if (updateEventStart) {
                updateEventStart(schedulerData, eventItem, newStart);
            }
        }
    }

    cancelStartDrag = (ev) => {
        ev.stopPropagation();
        this.startResizer.removeEventListener('touchmove', this.doStartDrag, false);
        this.startResizer.removeEventListener('touchend', this.stopStartDrag, false);
        this.startResizer.removeEventListener('touchcancel', this.cancelStartDrag, false);
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            schedulerData,
            left,
            top,
            width,
        } = this.props;
        schedulerData._stopResizing();
        this.setState({
            left: left,
            top: top,
            width: width,
        });
    }

    initEndDrag = (ev) => {
        const {
            schedulerData,
            eventItem,
        } = this.props;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slot = schedulerData.getSlotById(slotId);
        if (!!slot && !!slot.groupOnly) {
            return;
        }
        if (schedulerData._isResizing()) {
            return;
        }
        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length === 0) {
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) {
                return;
            }
            clientX = ev.clientX;
        }
        this.setState({
            endX: clientX
        });
        schedulerData._startResizing();
        if (supportTouch) {
            this.endResizer.addEventListener('touchmove', this.doEndDrag, false);
            this.endResizer.addEventListener('touchend', this.stopEndDrag, false);
            this.endResizer.addEventListener('touchcancel', this.cancelEndDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doEndDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopEndDrag, false);
        }
        document.onselectstart = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
    }

    doEndDrag = (ev) => {
        ev.stopPropagation();
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            width,
            leftIndex,
            schedulerData,
        } = this.props;
        const {
            headers,
        } = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0
            ? 5
            : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = (headers.length - leftIndex) * cellWidth - offset;
        const {
            endX
        } = this.state;
        let newWidth = (width + clientX - endX);
        if (newWidth < minWidth) {
            newWidth = minWidth;
        } else if (newWidth > maxWidth) {
            newWidth = maxWidth;
        }
        this.setState({
            width: newWidth,
        });
    }

    stopEndDrag = (ev) => {
        ev.stopPropagation();
        if (supportTouch) {
            this.endResizer.removeEventListener('touchmove', this.doEndDrag, false);
            this.endResizer.removeEventListener('touchend', this.stopEndDrag, false);
            this.endResizer.removeEventListener('touchcancel', this.cancelEndDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doEndDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopEndDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            width,
            left,
            top,
            leftIndex,
            rightIndex,
            schedulerData,
            eventItem,
            updateEventEnd,
            conflictOccurred,
        } = this.props;
        schedulerData._stopResizing();
        if (this.state.width === width) {
            return;
        }
        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) {
                this.setState({
                    left: left,
                    top: top,
                    width: width,
                });
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {
            headers,
            cellUnit,
            events,
            config,
            localeMoment,
        } = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = (headers.length - leftIndex) * cellWidth - offset;
        const { endX } = this.state;
        let newWidth = (width + clientX - endX);
        let deltaX = newWidth - width;
        let sign = deltaX < 0
            ? -1
            : (deltaX === 0 ? 0 : 1);
        let count = (sign < 0
            ? Math.floor(Math.abs(deltaX) / cellWidth)
            : Math.ceil(Math.abs(deltaX) / cellWidth))
            * sign;
        if (newWidth < minWidth) {
            count = leftIndex - rightIndex + 1;
        } else if (newWidth > maxWidth) {
            count = headers.length - rightIndex;
        }
        let newEnd = moment.utc(eventItem.end).add(
            cellUnit === CellUnits.Hour
                ? count * config.minuteStep
                : count,
            cellUnit === CellUnits.Hour
                ? 'minutes'
                : 'days'
        ).format(DATETIME_FORMAT);
        if (count !== 0 && cellUnit !== CellUnits.Hour && config.displayWeekend === false) {
            if (count > 0) {
                let tempCount = 0, i = 0;
                while (true) {
                    i++;
                    let tempEnd = moment.utc(eventItem.end).add(i, 'days');
                    let dayOfWeek = tempEnd.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount++;
                        if (tempCount === count) {
                            newEnd = tempEnd.format(DATETIME_FORMAT);
                            break;
                        }
                    }

                }
            } else {
                let tempCount = 0, i = 0;
                while (true) {
                    i--;
                    let tempEnd = moment.utc(eventItem.end).add(i, 'days');
                    let dayOfWeek = tempEnd.weekday();
                    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount--;
                        if (tempCount === count) {
                            newEnd = tempEnd.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            }
        }
        let hasConflict = false;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slotName = undefined;
        let slot = schedulerData.getSlotById(slotId);
        if (!!slot) {
            slotName = slot.name;
        }
        if (config.checkConflict) {
            const start = moment.utc(eventItem.start);
            const end = moment.utc(newEnd);
            events.forEach((e) => {
                if (schedulerData._getEventSlotId(e) === slotId && e.id !== eventItem.id) {
                    const eStart = moment.utc(e.start);
                    const eEnd = moment.utc(e.end);
                    if ((start >= eStart && start < eEnd)
                        || (end > eStart && end <= eEnd)
                        || (eStart >= start && eStart < end)
                        || (eEnd > start && eEnd <= end)) {
                        hasConflict = true;
                    }
                }
            });
        }
        if (hasConflict) {
            this.setState({
                left: left,
                top: top,
                width: width,
            });

            if (conflictOccurred != undefined) {
                conflictOccurred(schedulerData, 'EndResize', eventItem, DnDTypes.EVENT, slotId, slotName, eventItem.start, newEnd);
            }
            else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
            this.subscribeResizeEvent(this.props);
        }
        else {
            if (updateEventEnd != undefined)
                updateEventEnd(schedulerData, eventItem, newEnd);
        }
    }

    cancelEndDrag = (ev) => {
        ev.stopPropagation();
        this.endResizer.removeEventListener('touchmove', this.doEndDrag, false);
        this.endResizer.removeEventListener('touchend', this.stopEndDrag, false);
        this.endResizer.removeEventListener('touchcancel', this.cancelEndDrag, false);
        document.onselectstart = null;
        document.ondragstart = null;
        const {
            schedulerData,
            left,
            top,
            width,
        } = this.props;
        schedulerData._stopResizing();
        this.setState({
            left: left,
            top: top,
            width: width,
        });
    }


    startResizable = (props) => {
        const {
            eventItem,
            isInPopover,
            schedulerData,
        } = props;
        const {
            config,
        } = schedulerData;
        return config.startResizable === true && isInPopover === false
            && (eventItem.resizable == undefined || eventItem.resizable !== false)
            && (eventItem.startResizable == undefined || eventItem.startResizable !== false);
    }

    endResizable = (props) => {
        const {
            eventItem,
            isInPopover,
            schedulerData,
        } = props;
        const {
            config,
        } = schedulerData;
        return config.endResizable === true && isInPopover === false
            && (eventItem.resizable == undefined || eventItem.resizable !== false)
            && (eventItem.endResizable == undefined || eventItem.endResizable !== false);
    }

    subscribeResizeEvent = (props) => {
        if (this.startResizer != undefined) {
            if (supportTouch) {
                // this.startResizer.removeEventListener('touchstart', this.initStartDrag, false);
                // if (this.startResizable(props))
                //     this.startResizer.addEventListener('touchstart', this.initStartDrag, false);
            } else {
                this.startResizer.removeEventListener('mousedown', this.initStartDrag, false);
                if (this.startResizable(props))
                    this.startResizer.addEventListener('mousedown', this.initStartDrag, false);
            }
        }
        if (this.endResizer != undefined) {
            if (supportTouch) {
                // this.endResizer.removeEventListener('touchstart', this.initEndDrag, false);
                // if (this.endResizable(props))
                //     this.endResizer.addEventListener('touchstart', this.initEndDrag, false);
            } else {
                this.endResizer.removeEventListener('mousedown', this.initEndDrag, false);
                if (this.endResizable(props))
                    this.endResizer.addEventListener('mousedown', this.initEndDrag, false);
            }
        }
    }

    render() {
        const {
            eventItem,
            isStart,
            isEnd,
            eventItemClick,
            schedulerData,
            isDragging,
            onEventClick,
            connectDragSource,
            connectDragPreview,
            eventItemTemplateResolver,
            heureDebutDefaut,
            heureFinDefaut,
            headerItem,
        } = this.props;
        const {
            left,
            width,
            top,
        } = this.state;

        const {
            config,
            resources,
            viewType,
        } = schedulerData;
        const {
            defaultEventBgColor,
            eventItemHeight,
            eventItemLineHeight,
            eventItemPopoverEnabled,
            showPopover,
        } = config;
        const {
            bgColor,
            start,
            end,
            resourceId,
            interventionServiceTechnique,
        } = eventItem;
        const {
            start: creneauStart,
            end: creneauEnd,
            events,
        } = headerItem || {};
        const {
            // nombre de créneau sur laquelle la réservation est posée
            span: nombreCreneauReservation
        } = _.head(events) || {};
        
        let realCreneauEnd = creneauEnd; // servira réservation sur plusieurs créneau

        let itemBackgroundColor = defaultEventBgColor;
        if (!!bgColor) {
            itemBackgroundColor = bgColor;
        }
        let startResizeDiv = <div />;
        if (this.startResizable(this.props)) {
            startResizeDiv = <div
                className="event-resizer event-start-resizer"
                ref={(ref) => this.startResizer = ref}>
            </div>;
        }
        let endResizeDiv = <div />;
        if (this.endResizable(this.props)) {
            endResizeDiv = <div
                className="event-resizer event-end-resizer"
                ref={(ref) => this.endResizer = ref}>
            </div>;
        }

        /** On récupère la salle */
        const salle = resources.filter(x => x.id === resourceId)[0];

        /** On récupère les horaires d'ouverture de la salle */
        const {
            heureDebut: heureDebutSalle,
            heureFin: heureFinSalle,
            isReservable: isReservableSalle,
            finDelai: finDelaiSalle,
            dateMaximum: dateMaximumSalle
        } = salle;

        // On calcul les espacements haut et bas de la div dans la ligne
        // => (Hauteur de ligne - hauteur de la div) / 2
        const marginHeight = (eventItemLineHeight - eventItemHeight) / 2;

        const hDeb = !_.isEmpty(heureDebutSalle)
            ? heureDebutSalle
            : !_.isEmpty(heureDebutDefaut)
                ? heureDebutDefaut
                : null;
        const hFin = !_.isEmpty(heureFinSalle)
        ? heureFinSalle
        : !_.isEmpty(heureFinDefaut)
        ? heureFinDefaut
        : null;
        
                
        const heureDebutDefautParam = hDeb?.slice(0, 2);
        const minuteDebutDefautParam = hDeb?.slice(2, 4);
        const heureFinDefautParam = hFin?.slice(0, 2);
        const minuteFinDefautParam = hFin?.slice(2, 4);

        // On construit les dates de début et de fin de réservation (en l'absence d'horaire sur la salles → horaires affichage planning)
        let dateDebutDefaut = `${moment(start).format('YYYY-MM-DD')} ${heureDebutDefautParam}:${minuteDebutDefautParam}:00`;
        let dateFinDefaut = `${moment(end).format('YYYY-MM-DD')} ${heureFinDefautParam}:${minuteFinDefautParam}:00`;

        if (nombreCreneauReservation === 1) {
            // Si l'ouverture de la salle est avant le début du créneau, la début du créneau doit faire foi
            if (moment(dateDebutDefaut).isBefore(moment(creneauStart), 'minute')) {
                dateDebutDefaut = creneauStart;
            }

            // Si la fermeture de la salle est après la fin du créneau, la fin du créneau doit faire foi
            if (moment(dateFinDefaut).isAfter(moment(creneauEnd), 'minute')) {
                dateFinDefaut = creneauEnd;
            }
        } else {            
            // Si l'ouverture de la salle est avant le début du créneau, la début du créneau doit faire foi
            if (moment(dateDebutDefaut).isBefore(moment(creneauStart), 'minute')) {
                dateDebutDefaut = creneauStart;
            }

            let dateTmp = moment(realCreneauEnd);

            // -1 pour enlever le "premier" créneau (start à end) vu qu'il est implicite
            for (let i = 0; i < nombreCreneauReservation - 1; i++) {
                dateTmp.add(12, 'hours');
            }

            // Si la fermeture de la salle est après la fin du créneau (le créneau où la fin de la réservation arrive), la fin du créneau doit faire foi
            if (moment(dateFinDefaut).isAfter(moment(dateTmp), 'minute')) {
                dateFinDefaut = dateTmp.format('YYYY-MM-DD HH:mm:ss');
            }
        }

        /** On vérifie si la salle est encore réservable en prenant en compte les paramètres */
        const isDatesReservables =
            /** Si la salle est réservable */
            isReservableSalle
            /** Et que le délai est passé */
            && moment(start) > finDelaiSalle
            /** Et que la date maximum n'est pas définie ou non dépasséee */
            && (!dateMaximumSalle || moment(end) < dateMaximumSalle);

        let isCreneauxDispo = isDatesReservables && viewType !== ViewTypes.Day;

        if (eventItem.id === 18750) {
            console.log(
                isReservableSalle,
                finDelaiSalle,
                moment(start) > finDelaiSalle,
                !dateMaximumSalle || moment(end) < dateMaximumSalle
            );
        }
        // Le créneau n'est pas disponible si l'évènement
        // présent occupe la totalité de plage horaire du planning
        if (moment(start).isSameOrBefore(moment(dateDebutDefaut), 'minute')
            && moment(end).isSameOrAfter(moment(dateFinDefaut), 'minute')) {
            isCreneauxDispo = false;
        }

        /** On créé le style du background en fonction si il y a une seule ou plusieurs réservations */
        /** si pas d'horaire max SUR LA SALLE, on prend ceux des paramètres généraux NVT 433468  */
        const styleBgColor =
            isCreneauxDispo
                ? `-webkit-repeating-linear-gradient(135deg,  
                    ${itemBackgroundColor},
                    ${itemBackgroundColor} 4.5px,
                    transparent 4.5px,
                    transparent 9px`
                : itemBackgroundColor;
        // Div pour l'élément de réservation
        const roundCls = isStart
            ? isEnd
                ? 'round-all'
                : 'round-head'
            : isEnd
                ? 'round-tail'
                : 'round-none';

        let eventItemTemplate = (
            <div
                key={eventItem.id}
                className={roundCls}
                style={{
                    display: 'flex',
                    height: eventItemHeight,
                    background: styleBgColor,
                    overflow: 'hidden',
                    marginTop: `${marginHeight}px`,
                    marginBottom: `${marginHeight}px`,
                }}
                onClick={() => {
                    if (onEventClick) {
                        onEventClick(start, isCreneauxDispo, eventItem.id);
                    }
                }}
            >
                {(!isCreneauxDispo && interventionServiceTechnique) && (
                    <div style={{
                        position: 'absolute',
                        width: 24,
                        left: 'calc(50% - 12px)',
                        margin: 'auto',
                    }}>
                        <Icon
                            fitted
                            name='warning sign'
                            style={{ color: 'white' }}
                        />
                    </div>
                )}
                <div
                    className='event-item'
                    style={{
                        flex: 1,
                        height: '100%',
                        margin: 0
                    }}
                >
                    <span
                        style={{
                            marginLeft: '0.8rem',
                            fontSize: '1rem',
                            lineHeight: `${eventItemHeight}px`,
                        }}
                    />
                </div>
            </div>
        );

        if (eventItemTemplateResolver !== undefined) {
            eventItemTemplate = eventItemTemplateResolver(schedulerData, eventItem, itemBackgroundColor, isStart, isEnd, 'event-item', eventItemHeight, undefined);
        }

        let a = <a
            className="timeline-event"
            style={{
                left: left,
                width: width,
                top: top
            }}
            onClick={() => {
                if (!!eventItemClick) {
                    eventItemClick(schedulerData, eventItem);
                }
            }}>
            {eventItemTemplate}
            {startResizeDiv}
            {endResizeDiv}
        </a>;

        return (
            isDragging
                ? null
                : (schedulerData._isResizing() || eventItemPopoverEnabled === false || showPopover === false
                    ? (
                        <div>
                            {
                                connectDragPreview(
                                    connectDragSource(a)
                                )
                            }
                        </div>
                    ) : (
                        <Popup
                            style={{ padding: 0 }}
                            position='bottom center'
                            trigger={connectDragPreview(
                                connectDragSource(a)
                            )}
                            content={
                                <EventItemPopover
                                    {...this.props}
                                    eventItem={eventItem}
                                    statusColor={bgColor}
                                    isCreneauxDispo={isCreneauxDispo}
                                    onEventClick={onEventClick}
                                />
                            }
                            hoverable
                        />
                    )
                )
        );
    }
}

export default EventItem