import _ from 'lodash';
import React from 'react';
import moment from 'moment'

import Types from '360-types';

import {
    Form,
    Grid,
} from 'semantic-ui-react';
import { isSameDay } from 'react-dates';

import Calendar from './Calendar';
import HourPicker from './HourPicker';
import InputDateHour from './InputDateHour';

const DatePicker = (props) => {
    const {
        debut: dateDebut,
        fin: dateFin,
        focused,
        onFocus,
        onChange
    } = props;
    
    const [focusedInput, setFocusedInput] = React.useState();
    // Si on vient juste de rentrer en focus.
    const [justHaveBeenFocused, setJustHaveBeenFocused] = React.useState(false);
    const [debut, setDebut] = React.useState(dateDebut);
    const [fin, setFin] = React.useState(dateFin);
    
    const handleChange = (value) => {
        const nextValue = moment(value); // Obligé de faire ça sinon ça modifie par adresse ensuite

        let currentCreneau = moment();
        let currentReached = false;

        while (!currentReached) {
            if (currentCreneau.minute() % 15 !== 0) {
                currentCreneau.subtract(1, 'minute');
            } else {
                currentReached = true;
            }
        }

        if (focusedInput === 'startDate') {
            // Si une date de début avait déjà été fixé et qu'on vient d'arriver dans l'input
            // ou que la date de début est après la date de fin
            // on vide la date de fin
            if ((!_.isNil(debut) && justHaveBeenFocused) || (!_.isNil(fin) && nextValue.isAfter(fin))) {
                setFin(null);
            }

            if (isSameDay(nextValue, currentCreneau)) {
                setDebut(nextValue.set({ hour: currentCreneau.hour(), minute: currentCreneau.minute()}));
            } else {
                if (!_.isNil(fin) && isSameDay(nextValue, fin)) {
                    setDebut(moment(fin).subtract(15, 'minute'));
                } else {
                    setDebut(nextValue);
                }
            }
        } else {
            let nextCreneau = moment(currentCreneau).set({ minute: currentCreneau.minute() + 15 });

            if (!_.isNil(debut)) {
                if (isSameDay(debut, nextValue)) {
                    nextCreneau = moment(debut).add(15, 'minute');

                    setFin(nextValue.set({ hour: nextCreneau.hour(), minute: nextCreneau.minute()}));
                } else {
                    setFin(value);
                }
            } else {
                setFin(value);
            }
        }

        setJustHaveBeenFocused(false);
    }

    const handleHourChange = ({ name, value }) => {
        if (focusedInput === 'startDate') {
            if (name === 'hour') {
                const nextDate = moment(debut).set({ hour: value });

                if (!_.isNil(fin) && isSameDay(nextDate, fin)
                    && (nextDate.isAfter(fin) || nextDate.isSame(fin))
                ) {
                    nextDate.set({ minute: fin.minute() });
                    nextDate.subtract(15, 'minute');

                    setFin(null);
                }

                setDebut(nextDate);
            }
            
            if (name === 'minute') {
                const nextDate = moment(debut).set('minute', value);

                if (!_.isNil(fin) && isSameDay(nextDate, fin)
                    && (nextDate.isAfter(fin) || nextDate.isSame(fin))
                ) {
                    setFin(null);
                }

                setDebut(moment(debut).set('minute', value));
            }
        } else {
            if (name === 'hour') {
                const nextDate = moment(fin).set({ hour: value });

                if (!_.isNil(debut) && isSameDay(debut, nextDate)
                    && (nextDate.isBefore(debut) || debut.isSame(nextDate))
                ) {
                    nextDate.set({ minute: debut.minute() });
                    nextDate.add(15, 'minute');
                }

                setFin(nextDate);
            }
            
            if (name === 'minute') {
                setFin(moment(fin).set({ minute: value }));
            }
        }

    }
    
    const handleFocus = (name) => {
        if (_.includes(['startDate', 'startHour'], name)) {
            setFocusedInput('startDate');

            if (onFocus) {
                onFocus({ target: { name: 'debut'}});
            }
        } else {
            setFocusedInput('endDate');

            if (onFocus) {
                onFocus({ target: { name: 'fin'}});
            }
        }

        setJustHaveBeenFocused(true);
    }

    React.useEffect(() => { if (!focused) { setFocusedInput('') } }, [focused]);
    React.useEffect(() => { setDebut(dateDebut) }, [dateDebut]);
    React.useEffect(() => { setFin(dateFin) }, [dateFin]);

    React.useEffect(() => {
        if (onChange) {
            onChange('debut', debut);
        }
    }, [debut]);

    React.useEffect(() => {
        if (onChange) {
            onChange('fin', fin);
        }
    }, [fin]);

    return (
        <Grid className='DatePicker'>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <Form.Group>
                            <InputDateHour
                                required
                                type='start'
                                label='Début'
                                focused={focusedInput === 'startDate'}
                                date={debut}
                                onFocus={handleFocus}
                            />
                            <InputDateHour
                                required
                                type='end'
                                label='Fin'
                                focused={focusedInput === 'endDate'}
                                disabled={_.isNil(debut)}
                                date={fin}
                                onFocus={handleFocus}
                            />
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            {!_.isEmpty(focusedInput) && (
                <Grid.Row
                    style={{
                        marginTop: -20,
                        paddingTop: 0,
                        paddingBottom: 0,
                    }}
                >
                    <Grid.Column width={12}>
                        <Calendar
                            dateDebut={debut}
                            dateFin={fin}
                            focusedInput={focusedInput}
                            onChange={handleChange}
                        />
                    </Grid.Column>
                    <Grid.Column width={4} style={{ paddingTop: '2.5rem' }}>
                        {focusedInput === 'startDate' && (
                            <HourPicker
                                onChange={handleHourChange}
                                date={debut}
                                // dateMax={fin}
                                title='Horaire de début'
                            />
                        )}
                        {focusedInput === 'endDate' && (
                            <HourPicker
                                onChange={handleHourChange}
                                dateMin={debut}
                                date={fin}
                                title='Horaire de fin'
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    )
};

DatePicker.propTypes = {
    /** Moment object */
    debut: Types.object,

    /** Moment object*/
    fin: Types.object,

    /** Whether or not the calendar should appear. */
    focused: Types.bool,

    /** */
    onFocus: Types.func,

    /** */
    onChange: Types.func,
}

DatePicker.defaultProps = {
    focused: false,
}

export default DatePicker;
