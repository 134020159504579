/** Actions table SALLES */
export const GET_ALL_SALLES = 'GET_ALL_SALLES';
export const GET_ARRAY_SALLE_BY_ID = 'GET_ARRAY_SALLE_BY_ID';
export const GET_SALLE_BY_ID = 'GET_SALLE_BY_ID';
export const GET_SALLES_DISPONIBLES = 'GET_SALLES_DISPONIBLES';

/** Actions table TYPES_SALLES */
export const GET_ALL_TYPES_SALLES = 'GET_ALL_TYPES_SALLES';

/** Actions table TYPES_RESERVATION */
export const GET_ALL_TYPES_RESERVATIONS = 'GET_ALL_TYPES_RESERVATIONS';

/** Actions table PLANNINGS (réservations) */
export const ADD_PLANNING = 'ADD_PLANNING';
export const GET_RANGE_PLANNINGS = 'GET_RANGE_PLANNING';

/** Actions table RESERVANT */
export const GET_ALL_RESERVANTS = 'GET_ALL_RESERVANTS';
export const ADD_RESERVANT = 'ADD_RESERVANT';

/** Actions table PARAM */
export const GET_PARAM_ENTITE = 'GET_PARAM_ENTITE';

/** Actions table TYPE_RESERVANT_SALLE */
export const GET_TYPE_RESERVANT_BY_SALLE = 'GET_TYPE_RESERVANT_BY_SALLE';
export const GET_SALLE_BY_TYPE_RESERVANT = 'GET_SALLE_BY_TYPE_RESERVANT';
export const GET_ALL_TYPE_RESERVANT_SALLE = 'GET_ALL_TYPE_RESERVANT_SALLE';

/** Actions table TYPE_RESERVANT */
export const GET_ALL_TYPE_RESERVANT = 'GET_ALL_TYPE_RESERVANT';

/** Actions table STATUTS */
export const GET_STATUT_WEB_EN_ATTENTE = 'GET_STATUT_WEB_EN_ATTENTE';

/** Actions table STATUTS */
export const GET_ALL_DOCUMENTS_SALLES = 'GET_ALL_DOCUMENTS_SALLES';

/** Actions table TYPE_RESERVATION_SALLE via SALLES */
export const GET_SALLES_TYPE_RESERVATION = 'GET_SALLES_TYPE_RESERVATION';

/** Actions table DOCUMENTS_INFO */
export const DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS';
