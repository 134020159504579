import React from 'react';
import _ from 'lodash';

import {
    Form,
    Grid,
    Popup,
    Divider,
    List,
    Container,
    Header,
    Ref,
} from 'semantic-ui-react';

import {
    Form as Form360,
    TextArea,
} from '360-composants';
import Button from '../Button';

const optionsLettre = [
    {
        value: 0,
        text: ''
    },
    {
        value: 'bis',
        text: 'bis'
    },
    {
        value: 'ter',
        text: 'ter'
    },
    {
        value: 'quater',
        text: 'quater'
    },
    {
        value: 'quinquies',
        text: 'quinquies'
    },
    {
        value: 'sexies',
        text: 'sexies'
    },
    {
        value: 'septies',
        text: 'septies'
    },
    {
        value: 'octies',
        text: 'octies'
    },
];

const ModalPreReservationCoordonnees = (props) => {
    const {
        adresse: adresseProps,
        civilite: civiliteProps,
        city: cityProps,
        email: emailProps,
        emailValidation: emailValidationProps,
        isCourrielInvalid,
        houseNumber: houseNumberProps,
        infosComplementaires: infosComplementairesProps,
        nom: nomProps,
        nomOrganisation: nomOrganisationProps,
        lettre: lettreProps,
        numComplement: numComplementProps,
        onChange,
        optionsCivilite,
        organisation,
        phoneNumber: phoneNumberProps,
        isTelephoneInvalid,
        postCode: postCodeProps,
        prenom: prenomProps,
        street: streetProps,
    } = props;

    /** Champs */
    const [prenom, setPrenom] = React.useState(prenomProps || '');
    const [nom, setNom] = React.useState(nomProps || '');
    const [civilite, setCivilite] = React.useState(civiliteProps || 0);
    const [nomOrganisation, setNomOrganisation] = React.useState(nomOrganisationProps || '');
    const [adresse, setAdresse] = React.useState(adresseProps || '');
    const [houseNumber, setHouseNumber] = React.useState(houseNumberProps || '');
    const [lettre, setLettre] = React.useState(lettreProps || 0);
    const [numComplement, setNumComplement] = React.useState(numComplementProps || '');
    const [street, setStreet] = React.useState(streetProps || '');
    const [postCode, setPostCode] = React.useState(postCodeProps || '');
    const [city, setCity] = React.useState(cityProps || '');
    const [phoneNumber, setPhoneNumber] = React.useState(phoneNumberProps || '');
    const [email, setEmail] = React.useState(emailProps || '');
    const [emailValidation, setEmailValidation] = React.useState(emailValidationProps || '');
    const [infosComplementaires, setInfosComplementaires] = React.useState(infosComplementairesProps || '');
    /** Autres */
    const [focusedColumn, setFocusedColumn] = React.useState(null);
    const [adressResults, setAdressResults] = React.useState([]);
    const [searchAdresse, setSearchAdresse] = React.useState(null);
    const [currentAdresseIndex, setCurrentAdresseIndex] = React.useState(null);
    // affichage description adresse complète ou non
    const [adressFull, setAdressFull] = React.useState(false);
    // Entrée en saisie dans le n°
    const [houseNumberInput, setHouseNumberInput] = React.useState(null);

    const handleHouseNumberInputRef = (node) => {
        setHouseNumberInput(node);
    };

    const setHouseNumberInputFocused = () => {
        if (!_.isNil(houseNumberInput)) {
            houseNumberInput.focus();
        }
    };

    const handleColumnFocus = ({ target: { name: nameEvent } }, { name: nameData } = {}) => {
        const nomChamp = nameEvent || nameData;

        if (['civilite', 'prenom', 'nom', 'nomOrganisation'].includes(nomChamp)) {
            setFocusedColumn('EtatCivil');
        }

        if (['adresse', 'houseNumber', 'lettre', 'numComplement', 'street', 'postCode', 'city',].includes(nomChamp)) {
            setFocusedColumn('Adresse');
        }

        if (['phoneNumber', 'email', 'emailValidation'].includes(nomChamp)) {
            setFocusedColumn('Coordonnees');
        }

        if (nomChamp === 'infosComplementaires') {
            setFocusedColumn('Complement');
        }
    }

    const getNextElementIndex = () => {
        // prochaine adresse dans le tableau
        const nextIndex = currentAdresseIndex < adressResults.length - 1
            ? currentAdresseIndex + 1
            : currentAdresseIndex;

        return nextIndex;
    }

    const getPreviousElementIndex = () => {
        // adresse d'avant
        const prevIndex = currentAdresseIndex > 0
            ? currentAdresseIndex - 1
            : 0;

        return prevIndex;
    }

    const handleKeyDown = (event) => {
        const { key } = event;

        // si on a sélectionné avec entrée et qu'on fait flèche bas après, on a toujours le focus
        // donc il faut "relancer"
        if (focusedColumn === null && key !== 'Shift') {
            setFocusedColumn('Adresse');
            setCurrentAdresseIndex(0);
        } else {
            const nombreElement = adressResults?.length;

            if (key === 'ArrowUp' || key === 'ArrowDown') {
                if (nombreElement > 0) {
                    let index = null;

                    if (key === 'ArrowUp') {
                        index = getPreviousElementIndex();
                    }

                    if (key === 'ArrowDown') {
                        index = getNextElementIndex();
                    }

                    setCurrentAdresseIndex(index);
                    handleAdressResultSelect(index, false);
                }
            }

            if (key === 'Enter' || key === 'Tab') {
                if (currentAdresseIndex !== null && currentAdresseIndex >= 0) {
                    handleAdressResultSelect(currentAdresseIndex);

                    // Si on est sur l'option "adresse non reconnue" on ouvre le pavé adresse
                    if (currentAdresseIndex === 0) {
                        setAdressFull(true);
                        setHouseNumberInputFocused();
                    } else {
                        setFocusedColumn(null);
                    }
                } else {
                    setFocusedColumn('Adresse');
                }
            }
        }
    }

    const formatAdresse = (
        houseNumberParam,
        lettreParam,
        numComplementParam,
        streetParam,
        postcodeParam,
        cityParam,
    ) => (houseNumberParam !== undefined && houseNumberParam !== '' ? houseNumberParam + ' ' : '')
    + (lettreParam !== undefined && lettreParam !== 0 ? optionsLettre.find(({ value }) => value === lettreParam).text + ' ' : '')
    + (numComplementParam !== undefined && numComplementParam !== '' ? numComplementParam + ' ' : '')
    + (streetParam !== undefined && streetParam !== '' ? streetParam + ' ' : '')
    + (postcodeParam !== undefined && postcodeParam !== '' ? postcodeParam + ' ' : '')
        + (cityParam !== undefined && cityParam !== '' ? cityParam : '');

    const toggleAdresseFull = () => {
        setAdressFull(prevState => !prevState);
        setFocusedColumn('Adresse');
    }

    const handleAdresseChange = (e, { value }) => {
        setSearchAdresse(value);

        if (value !== '') {
            fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}`)
                .then(response => response.json())
                .then((json) => {
                    const formatedAdresses = [
                        {
                            title: 'Adresse non reconnue',
                            housenumber: '',
                            street: '',
                            postcode: '',
                            city: '',
                            citycode: '',
                        },
                        ...json.features.map(({ properties: {
                            housenumber,
                            street: str,
                            postcode: pc,
                            citycode,
                            city: ct,
                            type,
                            name
                        } }) => ({
                            title: formatAdresse(housenumber, 0, type === 'street' ? name : str, pc, ct),
                            housenumber: housenumber ? housenumber : '',
                            street: type === 'street' ? name : str, //dans le cas où le numéro n'est pas renseigné, la rue ne s'affiche pas dans 'street' mais dans 'name'
                            postcode: pc,
                            city: ct,
                            citycode: citycode
                        })
                        )
                    ];

                    // > 1 car il y a le résultat par défaut
                    if (formatedAdresses.length > 1) {
                        setAdressResults(formatedAdresses);
                        setCurrentAdresseIndex(0);
                    }
                });
        } else {
            handleAdressResultSelect(-1);
            setAdressResults([]);
        }
    }

    const handleAdresseClick = (index) => () => {
        // Si on est sur l'option "adresse non reconnue" on ouvre le pavé adresse et on passe en saisie dans le n°
        if (index === 0) {
            setAdressFull(true);
            setHouseNumberInputFocused();
        }

        handleAdressResultSelect(index);
    }

    const handleAdressResultSelect = (index, clearSearchAdresse = true) => {
        if (index > 0) {
            if (clearSearchAdresse) {
                setSearchAdresse(null);
                setAdressFull(false);
                setAdressResults([]);
                setFocusedColumn(null);
                setCurrentAdresseIndex(null)
            }

            const adr = adressResults[index];

            if (adr) {
                const {
                    housenumber: houseNumberParam = '',
                    street: streetParam = '',
                    postcode: postcodeParam = '',
                    city: cityParam = '',
                } = adr;

                let houseNumValue = houseNumberParam;
                let houseLettreValue = 0;
                let houseNumComplementValue = '';

                if (isNaN(houseNumberParam) && houseNumberParam !== '' && houseNumberParam.includes(' ')) {
                    houseNumValue = houseNumberParam.split(' ')[0];
                    const houseLettreText = houseNumberParam.split(' ')[1];
                    const houseLettre = optionsLettre.find(({ value }) => _.toUpper(value) === _.toUpper(houseLettreText));

                    if (houseLettre) {
                        const { value } = houseLettre;
                        houseLettreValue = value;
                    } else {
                        houseNumComplementValue = houseLettreText;
                    }
                }

                const adrFormated = formatAdresse(
                    houseNumValue !== '' ? houseNumValue : houseNumber,
                    houseLettreValue,
                    houseNumComplementValue,
                    streetParam !== '' ? streetParam : street,
                    postcodeParam !== '' ? postcodeParam : postCode,
                    cityParam !== '' ? cityParam : city
                );

                setAdresse(adrFormated);
                setHouseNumber(houseNumValue);
                setLettre(houseLettreValue);
                setNumComplement(houseNumComplementValue);
                setStreet(streetParam);
                setPostCode(postcodeParam);
                setCity(cityParam);


                if (onChange) {
                    onChange('adresse', adrFormated);
                    onChange('houseNumber', houseNumValue);
                    onChange('lettre', houseLettreValue);
                    onChange('numComplement', houseNumComplementValue);
                    onChange('street', streetParam);
                    onChange('postCode', postcodeParam);
                    onChange('city', cityParam);
                }
            }
        } else {
            if (clearSearchAdresse) {
                setSearchAdresse(null);
                setAdressResults([]);
                setCurrentAdresseIndex(null)
            }

            setAdresse('');
            setHouseNumber('');
            setLettre(0);
            setNumComplement('');
            setStreet('');
            setPostCode('');
            setCity('');

            if (onChange) {
                onChange('adresse', '');
                onChange('houseNumber', '');
                onChange('lettre', '');
                onChange('numComplement', '');
                onChange('street', '');
                onChange('postCode', '');
                onChange('city', '');
            }
        }
    }

    const handleInputChange = (name, value, setter) => {
        let val = value;
        if (name === 'nom') {
            val = _.toUpper(val);
        }

        if (name === 'prenom' || name === 'nomOrganisation') {
            val = _.upperFirst(val);
        }

        setter(val);

        // il faut reformater l'adresse complètement dans les cas suivant
        // en plus du champ concerné
        if (['adresse', 'houseNumber', 'lettre', 'numComplement', 'street', 'postCode', 'city',].includes(name)) {
            let adr = '';
            if (name === 'houseNumber') {
                adr = formatAdresse(val, lettre, numComplement, street, postCode, city);
            }

            if (name === 'lettre') {
                adr = formatAdresse(houseNumber, val, numComplement, street, postCode, city);
            }

            if (name === 'numComplement') {
                adr = formatAdresse(houseNumber, lettre, val, street, postCode, city);
            }

            if (name === 'street') {
                adr = formatAdresse(houseNumber, lettre, numComplement, val, postCode, city);
            }

            if (name === 'postCode') {
                adr = formatAdresse(houseNumber, lettre, numComplement, street, val, city);
            }

            if (name === 'city') {
                adr = formatAdresse(houseNumber, lettre, numComplement, street, postCode, val);
            }

            setAdresse(adr);

            if (onChange) {
                onChange('adresse', adr);
                onChange(name, val);
            }
        } else {
            if (onChange) {
                onChange(name, val);
            }
        }
    }

    /** Init / mise à jour des states avec les props */
    React.useEffect(() => { if (adresseProps) { setAdresse(adresseProps) } }, [adresseProps]);
    React.useEffect(() => { if (civiliteProps) { setCivilite(civiliteProps) } }, [civiliteProps]);
    React.useEffect(() => { if (cityProps) { setCity(cityProps) } }, [cityProps]);
    React.useEffect(() => { if (emailProps) { setEmail(emailProps) } }, [emailProps]);
    React.useEffect(() => { if (emailValidationProps) { setEmailValidation(emailValidationProps) } }, [emailValidationProps]);
    React.useEffect(() => { if (houseNumberProps) { setHouseNumber(houseNumberProps) } }, [houseNumberProps]);
    React.useEffect(() => { if (infosComplementairesProps) { setInfosComplementaires(infosComplementairesProps) } }, [infosComplementairesProps]);
    React.useEffect(() => { if (nomProps) { setNom(nomProps) } }, [nomProps]);
    React.useEffect(() => { if (nomOrganisationProps) { setNomOrganisation(nomOrganisationProps) } }, [nomOrganisationProps]);
    React.useEffect(() => { if (lettreProps) { setLettre(lettreProps) } }, [lettreProps]);
    React.useEffect(() => { if (numComplementProps) { setNumComplement(numComplementProps) } }, [numComplementProps]);
    React.useEffect(() => { if (phoneNumberProps) { setPhoneNumber(phoneNumberProps) } }, [phoneNumberProps]);
    React.useEffect(() => { if (postCodeProps) { setPostCode(postCodeProps) } }, [postCodeProps]);
    React.useEffect(() => { if (prenomProps) { setPrenom(prenomProps) } }, [prenomProps]);
    React.useEffect(() => { if (streetProps) { setStreet(streetProps) } }, [streetProps]);

    React.useEffect(() => {
        if (!_.isNil(houseNumberInput) && adressFull) {
            houseNumberInput.getElementsByTagName('input')[0].focus();
        }
    }, [adressFull, houseNumberInput]);

    React.useEffect(() => {
        if (focusedColumn !== 'Adresse') {
            setAdressFull(false);
        }
    }, [focusedColumn]);

    return (
        <Grid
            className='activable'
            style={{ marginBottom: 0 }}
        >
            <Grid.Row>
                <Grid.Column>
                    <label style={{ fontSize: '1.25rem' }}>Informations concernant le réservant</label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={focusedColumn === 'EtatCivil' ? 'active' : null}>
                {organisation ? (
                    <Grid.Column>
                        <Form.Input
                            required
                            name='nomOrganisation'
                            label="Nom de l'organisation"
                            placeholder="Nom de l'organisation"
                            onFocus={handleColumnFocus}
                            value={nomOrganisation || ''}
                            onChange={({ target: { name, value } }) => handleInputChange(name, value, setNomOrganisation)}
                        />
                    </Grid.Column>
                ) : (
                    <>
                        <Grid.Column width={5}>
                            <Form.Select
                                required
                                label='Civilité'
                                options={optionsCivilite}
                                placeholder='Civilité'
                                onFocus={handleColumnFocus}
                                value={civilite || ''}
                                name='civilite'
                                onChange={(event, { name, value }) => handleInputChange(name, value, setCivilite)}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Input
                                required
                                name='nom'
                                placeholder='Nom'
                                label='Nom'
                                value={nom}
                                onFocus={handleColumnFocus}
                                onChange={({ target: { name, value } }) => handleInputChange(name, value, setNom)}
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Input
                                required
                                name='prenom'
                                label='Prénom'
                                placeholder='Prénom'
                                value={prenom}
                                onFocus={handleColumnFocus}
                                onChange={({ target: { name, value } }) => handleInputChange(name, value, setPrenom)}
                            />
                        </Grid.Column>
                    </>
                )}
            </Grid.Row>
            <Grid.Row className={focusedColumn === 'Adresse' ? 'active' : null}>
                <Grid.Column {...adressFull && { style: { paddingBottom: 0 } }}>
                    <Form.Group>
                        <Form.Input
                            width={16}
                            required
                            label='Adresse'
                            name='adresse'
                            placeholder='Adresse complète'
                            icon='search'
                            onFocus={handleColumnFocus}
                            onKeyDown={handleKeyDown}
                            onChange={handleAdresseChange}
                            value={searchAdresse || adresse || ''}
                        />
                        <Form.Field>
                            <label style={{ whiteSpace: 'pre-wrap' }}> </label>
                            <Popup
                                position='left center'
                                content="Affiche ou cache le détail de l'adresse"
                                trigger={
                                    <Button
                                        style={{ height: '34px' }}
                                        size='mini'
                                        onClick={toggleAdresseFull}
                                        color={adressFull ? 'red' : 'olive'}
                                        icon={adressFull ? 'minus' : 'add'}
                                        type='button'
                                    />
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    {focusedColumn === 'Adresse' && searchAdresse && adressResults.length > 0 && (
                        <Container
                            fluid
                            style={{
                                display: 'flex',
                                justifyItems: 'center',
                                marginTop: '-0.5em',
                                padding: '0 2px',
                            }}
                        >
                            <List
                                link
                                style={{
                                    height: 180,
                                    maxWidth: 798
                                }}
                            >
                                {adressResults.map(({ title }, index) => (
                                    <List.Item
                                        key={index}
                                        active={currentAdresseIndex === index}
                                        onClick={handleAdresseClick(index)}
                                        {...currentAdresseIndex !== index && { as: 'a' }}
                                    >
                                        <Header as='h5'>
                                            {title}
                                        </Header>
                                    </List.Item>
                                ))}
                            </List>
                        </Container>
                    )}
                </Grid.Column>
            </Grid.Row>
            {adressFull && (
                <Grid.Row className={focusedColumn === 'Adresse' ? 'active' : null}>
                    <Grid.Column width={5} style={{ paddingTop: 0 }}>
                        <Form.Group>
                            <Form.Field
                                width={7}
                                style={{
                                    paddingRight: '0.25em',
                                }}
                            >
                                <label>N°</label>
                                <Ref innerRef={handleHouseNumberInputRef}>
                                    <Form.Input
                                        type='number'
                                        className='houseNumberInput'
                                        placeholder='N°'
                                        name='houseNumber'
                                        onFocus={handleColumnFocus}
                                        value={houseNumber}
                                        onChange={({ target: { name, value } }) => handleInputChange(name, value, setHouseNumber)}
                                    />
                                </Ref>
                            </Form.Field>
                            <Form.Field
                                width={6}
                                style={{
                                    minWidth: '10px',
                                    paddingRight: '0.25em',
                                    paddingLeft: '0.25em',
                                }}
                            >
                                <label>Lettre</label>
                                <Form.Select
                                    fluid
                                    placeholder='Lettre'
                                    name='lettre'
                                    options={optionsLettre}
                                    onFocus={handleColumnFocus}
                                    value={lettre === 0 ? '' : lettre}
                                    onChange={(event, { name, value }) => handleInputChange(name, value, setLettre)}
                                />
                            </Form.Field>
                            <Form.Field
                                width={3}
                                style={{
                                    paddingLeft: '0.25em',
                                }}
                            >
                                <label>Cpl.</label>
                                <Form.Input
                                    className='numComplement'
                                    input={{
                                        style: {
                                            paddingLeft: 8,
                                            paddingRight: 0,
                                        }
                                    }}
                                    placeholder='A'
                                    name='numComplement'
                                    onFocus={handleColumnFocus}
                                    value={numComplement}
                                    onChange={({ target: { name, value } }) => handleInputChange(name, value, setNumComplement)}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Grid.Column>
                    <Grid.Column width={5} style={{ paddingTop: 0 }}>
                        <Form.Input
                            label='Voie'
                            name='street'
                            placeholder='Voie'
                            onFocus={handleColumnFocus}
                            value={street}
                            onChange={({ target: { name, value } }) => handleInputChange(name, value, setStreet)}
                        />
                    </Grid.Column>
                    <Grid.Column width={2} style={{ paddingTop: 0 }}>
                        <Form.Input
                            label='Code Postal'
                            name='postCode'
                            placeholder='- - - - -'
                            onFocus={handleColumnFocus}
                            value={postCode}
                            onChange={({ target: { name, value } }) => handleInputChange(name, value, setPostCode)}
                        />
                    </Grid.Column>
                    <Grid.Column width={4} style={{ paddingTop: 0 }}>
                        <Form.Input
                            label='Ville'
                            name='city'
                            placeholder='Ville'
                            onFocus={handleColumnFocus}
                            value={city}
                            onChange={({ target: { name, value } }) => handleInputChange(name, value, setCity)}
                        />
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row
                className={focusedColumn === 'Coordonnees' ? 'active' : null}
                style={{ marginBottom: 15 }}
            >
                <Grid.Column width={4}>
                    <Form.Input
                        required
                        name='phoneNumber'
                        label='Téléphone'
                        placeholder='Téléphone'
                        maxLength='12'
                        onFocus={handleColumnFocus}
                        value={phoneNumber}
                        error={isTelephoneInvalid(phoneNumber)}
                        onChange={({ target: { name, value } }) => handleInputChange(name, value, setPhoneNumber)}
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Input
                        required
                        name='email'
                        label='Courriel'
                        placeholder='Courriel'
                        type='email'
                        onFocus={handleColumnFocus}
                        value={email}
                        error={isCourrielInvalid(email)}
                        onChange={({ target: { name, value } }) => handleInputChange(name, value, setEmail)}
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Form.Input
                        required
                        name='emailValidation'
                        label='Courriel (vérification)'
                        placeholder='Courriel (vérification)'
                        type='email'
                        onFocus={handleColumnFocus}
                        value={emailValidation}
                        onPaste={(event) => event.preventDefault()}
                        onChange={({ target: { name, value } }) => handleInputChange(name, value, setEmailValidation)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Divider style={{ width: '100%', margin: '0 25%' }} />
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <label style={{ fontSize: '1.25rem' }}>Informations complémentaires</label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={focusedColumn === 'Complement' ? 'active' : null} style={{ paddingBottom: '1rem' }}>
                <Grid.Column style={{ paddingBottom: '1rem' }}>
                    <Form360.Field
                        name='infosComplementaires'
                        label="Complément d'informations (facultatif) :"
                        placeholder="Vous pouvez ajouter ici toutes les précisions nécessaires"
                        control={TextArea}
                        maxLength={200}
                        style={{
                            minHeight: 80,
                            resize: 'none',
                        }}
                        value={infosComplementaires}
                        onFocus={handleColumnFocus}
                        onChange={({ target: { name, value } }) => handleInputChange(name, value, setInfosComplementaires)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

ModalPreReservationCoordonnees.defaultProps = {
    organisation: false,
}

export default ModalPreReservationCoordonnees;