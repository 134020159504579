import _ from 'lodash';
import React from 'react';

import {
    Modal as ModalSemantic
} from 'semantic-ui-react';

const Modal = (props) => {
    const {
        children,
        ...rest
    } = props;

    // Où rendre la Modal ; besoin de ça à cause de l'encapsulation du style
    const [mountNode, setMountNode] = React.useState(null);

    React.useEffect(() => {
        if (_.isNil(mountNode)) {
            const node = document.getElementById('JVS_publication_ligne');
            
            if (!_.isNil(node)) {
                setMountNode(node);
            }
        }
    }, [mountNode]);

    if (_.isNil(mountNode)) {
        return null;
    }

    if (children) {
        return (
            <ModalSemantic mountNode={mountNode} {...rest}>
                {children}
            </ModalSemantic>
        )
    }

    return (
        <ModalSemantic mountNode={mountNode} {...rest} />
    );
};

export default Modal;