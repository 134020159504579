import _ from 'lodash';
import React from 'react';

import {
    Grid,
} from 'semantic-ui-react';

import ModalDisponibiliteResultsCriteres from './ModalDisponibiliteResultsCriteres';
import ModalDisponibiliteResultsTable from './ModalDisponibiliteResultsTable';

const ModalDisponibiliteResults = (props) => {
    const {
        configDatabase,
        identifiantEntite,
        activite,
        capacite,
        debut,
        fin,
        loading,
        onSalleSelect,
        placesAssises,
        placesDebouts,
        salles,
        typeReservant,
        typeSalle,
        typesSalle,
    } = props;
    
    return (
        <Grid>
            <Grid.Row style={{ margin: '0 20px' }}>
                <Grid.Column>
                    <ModalDisponibiliteResultsCriteres
                        activite={activite}
                        capacite={capacite}
                        debut={debut}
                        fin={fin}
                        placesAssises={placesAssises}
                        placesDebouts={placesDebouts}
                        typeReservant={typeReservant}
                        typeSalle={typeSalle}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ margin: '0 20px' }}>
                <Grid.Column>
                    <ModalDisponibiliteResultsTable
                        configDatabase={configDatabase}
                        identifiantEntite={identifiantEntite}
                        loading={loading}
                        onSelect={onSalleSelect}
                        salles={salles}
                        typesSalle={typesSalle}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ModalDisponibiliteResults;