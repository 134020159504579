import _ from 'lodash';
import React, { Component } from 'react';
import moment from 'moment-timezone';

import { PropTypes } from 'prop-types';
import { isSameDay } from 'react-dates';

import AddMore from './AddMore';
import Summary from './Summary';
import SelectedArea from './SelectedArea';
import { CellUnits, DATETIME_FORMAT, SummaryPos, ViewTypes } from './index';
import { getPos } from './Util';
import { DnDTypes } from './DnDTypes';

const supportTouch = 'ontouchstart' in window;

class ResourceEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSelecting: false,
            left: 0,
            width: 0,
        }
    }

    static propTypes = {
        resourceEvents: PropTypes.object.isRequired,
        schedulerData: PropTypes.object.isRequired,
        dndSource: PropTypes.object.isRequired,
        onSetAddMoreState: PropTypes.func,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        movingEvent: PropTypes.func,
        conflictOccurred: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        newEvent: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
    }

    componentDidMount() {
        const { schedulerData } = this.props;
        const { config } = schedulerData;
        if (config.creatable === true) {
            if (supportTouch) {
                // this.eventContainer.addEventListener('touchstart', this.initDrag, false);
            } else {
                this.eventContainer.addEventListener('mousedown', this.initDrag, false);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(np) {
        if (supportTouch) {
            // this.eventContainer.removeEventListener('touchstart', this.initDrag, false);
        } else {
            this.eventContainer.removeEventListener('mousedown', this.initDrag, false);
        }
        if (np.schedulerData.config.creatable) {
            if (supportTouch) {
                // this.eventContainer.addEventListener('touchstart', this.initDrag, false);
            } else {
                this.eventContainer.addEventListener('mousedown', this.initDrag, false);
            }
        }
    }

    initDrag = (ev) => {
        ev.stopPropagation();

        const {
            resourceEvents,
            schedulerData,
            heureDebutDefaut,
            heureFinDefaut,
        } = this.props;
        const {
            isSelecting
        } = this.state;

        const {
            groupOnly,
            slotId
        } = resourceEvents || {};
        const {
            headers,
            resources,
            viewType,
        } = schedulerData || {};
        const { webdev } = window;

        if (isSelecting) return;

        if ((ev.srcElement || ev.target) !== this.eventContainer) return;

        const salle = resources.find(x => x.id === slotId);

        // normalement peut pas arriver
        if (!salle) return;

        if (groupOnly) return;

        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }

        let cellWidth = schedulerData.getContentCellWidth();
        let pos = getPos(this.eventContainer);
        let startX = clientX - pos.x;
        let leftIndex = Math.floor(startX / cellWidth);
        let left = leftIndex * cellWidth;
        let rightIndex = Math.ceil(startX / cellWidth);
        let width = (rightIndex - leftIndex) * cellWidth;

        const time = headers[leftIndex].time;

        const {
            dateMaximum,
            finDelai,
            groupOnly: groupOnlySalle,
            heureDebut,
            heureFin,
        } = salle;

        const hDeb = !_.isEmpty(heureDebut)
            ? heureDebut
            : !_.isEmpty(heureDebutDefaut)
                ? heureDebutDefaut
                : null;
        const hFin = !_.isEmpty(heureFin)
            ? heureFin
            : !_.isEmpty(heureFinDefaut)
                ? heureFinDefaut
                : null;

        let outOfBounds = false;
        let isCreneauActuelDepasse = false;

        switch (viewType) {
            case ViewTypes.Week:
                // Dernier créneau dispo : 23/03/2021 de 12h à 0h
                isCreneauActuelDepasse = moment(time).add(12, 'hours').isBefore(finDelai);

                if (!_.isEmpty(hFin)) {
                    const dateHeureFin = moment(moment().set({
                        hour: _.toFinite(hFin.substring(0, 2)),
                        minute: _.toFinite(hFin.substring(2)),
                        second: 0,
                    }).toDate());
                    
                    if (isSameDay(moment(), moment(time))) {
                        outOfBounds = moment().isAfter(dateHeureFin, 'minute');
                    }
                }

                break;
            case ViewTypes.Day:
                // Dernier créneau dispo : 23/03/2021 de 15h30 à 16h
                isCreneauActuelDepasse = moment(time).add(30, 'minutes').isBefore(finDelai);

                if (!_.isEmpty(hDeb)) {
                    let dateHeureDebut = moment(moment().set({
                        hour: _.toFinite(hDeb.substring(0, 2)),
                        minute: _.toFinite(hDeb.substring(2)),
                        second: 0,
                    }).toDate());

                    if (!isSameDay(moment(time), dateHeureDebut)) {   
                        dateHeureDebut = moment(dateHeureDebut.set({
                            date: moment(time).date(),
                            month: moment(time).get('month'),
                            year: moment(time).get('year'),
                        }).toDate());
                    }

                    outOfBounds = dateHeureDebut.isAfter(moment(time), 'minute');
                }
                
                if (!_.isEmpty(hFin)) {
                    let dateHeureFin = moment(moment().set({
                        hour: _.toFinite(hFin.substring(0, 2)),
                        minute: _.toFinite(hFin.substring(2)),
                        second: 0,
                    }).toDate());
                    
                    // Si c'est pas aujourd'hui
                    if (!isSameDay(moment(time), dateHeureFin)) {
                        dateHeureFin = moment(dateHeureFin.set({
                            date: moment(time).date(),
                            month: moment(time).month(),
                            year: moment(time).year(),
                        }).toDate());
                    }

                    outOfBounds = outOfBounds || moment(time).isSameOrAfter(dateHeureFin, 'minute');
                }

                break;
            case ViewTypes.Month:
                // Dernier créneau dispo : du 23/03/2021 au 24/03/2021
                isCreneauActuelDepasse = moment(time).add(1, 'day').isBefore(finDelai);

                if (!_.isEmpty(hFin)) {
                    const dateHeureFin = moment(moment().set({
                        hour: _.toFinite(hFin.substring(0, 2)),
                        minute: _.toFinite(hFin.substring(2)),
                        second: 0,
                    }).toDate());
                    
                    if (isSameDay(moment(), moment(time))) {
                        outOfBounds = moment().isAfter(dateHeureFin, 'minute');
                    }
                }

                break;
            default:
                break;
        }

        // Le créneau est valide si on est avant la fin de délai ET que le créneau actuel
        const isCreneauValide = moment(time).isBefore(finDelai) && isCreneauActuelDepasse;

        // Le créneau n'est pas réservable s'il est inférieur à la date actuel (heures comprises) 
        // OU supérieur/égal à la date max de réservation (si définie)
        const isCreneauNonReservable = dateMaximum !== undefined
            ? isCreneauValide || (moment(time).isAfter(dateMaximum) || moment(time).isSame(dateMaximum, 'day'))
            : isCreneauValide;

        const isNotSalle = groupOnlySalle !== undefined
            ? groupOnlySalle
            : false;

        // Si on est pas sur une ligne de type de salle ou qu'il s'agit d'un créneau non réservable
        // ou qu'on dépasse les horaires d'ouvertures
        // On empêche le clic
        if (isNotSalle || (!webdev && (isCreneauNonReservable || outOfBounds))) return;

        this.setState({
            startX: startX,
            left: left,
            leftIndex: leftIndex,
            width: width,
            rightIndex: rightIndex,
            isSelecting: true
        });

        if (supportTouch) {
            document.documentElement.addEventListener('touchmove', this.doDrag, false);
            document.documentElement.addEventListener('touchend', this.stopDrag, false);
            document.documentElement.addEventListener('touchcancel', this.cancelDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopDrag, false);
        }
        document.onselectstart = function () {
            return false;
        };
        document.ondragstart = function () {
            return false;
        };
    }

    doDrag = (ev) => {
        ev.stopPropagation();

        let clientX = 0;
        if (supportTouch) {
            if (ev.changedTouches.length == 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const { startX } = this.state;
        const { schedulerData } = this.props;
        const { headers } = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let pos = getPos(this.eventContainer);
        let currentX = clientX - pos.x;
        let leftIndex = Math.floor(Math.min(startX, currentX) / cellWidth);
        leftIndex = leftIndex < 0 ? 0 : leftIndex;
        let left = leftIndex * cellWidth;
        let rightIndex = Math.ceil(Math.max(startX, currentX) / cellWidth);
        rightIndex = rightIndex > headers.length ? headers.length : rightIndex;
        let width = (rightIndex - leftIndex) * cellWidth;

        this.setState({
            leftIndex: leftIndex,
            left: left,
            rightIndex: rightIndex,
            width: width,
            isSelecting: true
        });
    }

    stopDrag = (ev) => {
        ev.stopPropagation();

        const {
            schedulerData,
            newEvent,
            resourceEvents,
            conflictOccurred,
        } = this.props;
        const {
            leftIndex,
            rightIndex
        } = this.state;

        const {
            headers,
            events,
            config,
            viewType,
            renderData
        } = schedulerData;
        const { webdev } = window;

        if (supportTouch) {
            document.documentElement.removeEventListener('touchmove', this.doDrag, false);
            document.documentElement.removeEventListener('touchend', this.stopDrag, false);
            document.documentElement.removeEventListener('touchcancel', this.cancelDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;

        let startTime = headers[leftIndex].time;
        let endTime = resourceEvents.headerItems[rightIndex - 1].end;

        if (viewType !== ViewTypes.Day) {
            // On reprend la plage horaire suivante -1 seconde
            endTime = moment(endTime).format(DATETIME_FORMAT);
        }

        let slotId = resourceEvents.slotId;
        let slotName = resourceEvents.slotName;

        /** MODIFICATION : On vérifie si la salle est réservable */
        let isReservable = schedulerData.resources.filter(x => x.id === slotId)[0].isReservable;

        this.setState({
            startX: 0,
            leftIndex: 0,
            left: 0,
            rightIndex: 0,
            width: 0,
            isSelecting: false
        });

        let hasConflict = false;
        if (config.checkConflict) {
            let start = moment(startTime),
                end = moment(endTime);

            events.forEach((e) => {
                if (schedulerData._getEventSlotId(e) === slotId) {
                    let eStart = moment(e.start);
                    let eEnd = moment(e.end);

                    // cas :
                    // nouvelle                 |-------------?
                    // event            |-----------------|
                    if ((start >= eStart && start < eEnd)
                        // si la fin de la nouvelle réservation chevauche l'event (après le début, avant la fin)
                        // cas :
                        // nouvelle         ?-----------------|
                        // event     |-----------------|
                        || (end > eStart && end <= eEnd)
                        // cas :
                        // nouvelle : |-------------------------|
                        // event :          |----------?
                        || (eStart >= start && eStart < end)
                        // cas :
                        // nouvelle |------------------|
                        // event            ?-----------------|
                        || (eEnd > start && eEnd <= end)
                    ) {
                        hasConflict = true;
                    }
                }
            });
        }

        const finDelai = renderData.filter(x => x.slotId === slotId)[0].finDelai

        // On ajoute un créneau pour savoir si le créneau actuel n'est pas dépassé
        // Exemple : on est le 23/03/2021 15:40
        let isCreneauActuelDepasse = false;
        switch (viewType) {
            case ViewTypes.Week:
                // Dernier créneau dispo : 23/03/2021 de 12h à 0h
                isCreneauActuelDepasse = moment(startTime).add(12, 'hours').isBefore(finDelai);
                break;
            case ViewTypes.Day:
                // Dernier créneau dispo : 23/03/2021 de 15h30 à 16h
                isCreneauActuelDepasse = moment(startTime).add(30, 'minutes').isBefore(finDelai);
                break;
            case ViewTypes.Month:
                // Dernier créneau dispo : du 23/03/2021 au 24/03/2021
                isCreneauActuelDepasse = moment(startTime).add(1, 'day').isBefore(finDelai);
                break;
            default:
                break;
        }

        if (hasConflict) {
            if (conflictOccurred != undefined) {
                conflictOccurred(schedulerData, 'New', {
                    id: undefined,
                    start: startTime,
                    end: endTime,
                    slotId: slotId,
                    slotName: slotName,
                    title: undefined,
                }, DnDTypes.EVENT, slotId, slotName, startTime, endTime);
            }
            else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
        // } else if (!isReservable || isDelaiInsuffisant) {
        } else if (!webdev && (!isReservable || isCreneauActuelDepasse)) {
            /** MODIFICATION : Si elle n'est pas réservable, on déclenche la méthode conflict mais avec un event différent */
            const { conflictOccurred } = this.props;
            conflictOccurred(schedulerData, 'nonReservable', {
                id: undefined,
                start: startTime,
                end: endTime,
                slotId: slotId,
                slotName: slotName,
                title: undefined,
            }, DnDTypes.EVENT, slotId, slotName, startTime, endTime);
        }
        else {
            if (newEvent != undefined) {
                const wasDraggingOverMultipleSlot = rightIndex - leftIndex > 1;

                newEvent(schedulerData, slotId, slotName, startTime, endTime, wasDraggingOverMultipleSlot);
            }
        }
    }

    cancelDrag = (ev) => {
        ev.stopPropagation();

        const { isSelecting } = this.state;
        if (isSelecting) {
            document.documentElement.removeEventListener('touchmove', this.doDrag, false);
            document.documentElement.removeEventListener('touchend', this.stopDrag, false);
            document.documentElement.removeEventListener('touchcancel', this.cancelDrag, false);
            document.onselectstart = null;
            document.ondragstart = null;
            this.setState({
                startX: 0,
                leftIndex: 0,
                left: 0,
                rightIndex: 0,
                width: 0,
                isSelecting: false
            });
        }
    }


    onAddMoreClick = (headerItem) => {
        const { onSetAddMoreState, resourceEvents, schedulerData } = this.props;
        if (!!onSetAddMoreState) {
            const { config } = schedulerData;
            let cellWidth = schedulerData.getContentCellWidth();
            let index = resourceEvents.headerItems.indexOf(headerItem);
            if (index !== -1) {
                let left = index * (cellWidth - 1);
                let pos = getPos(this.eventContainer);
                left = left + pos.x;
                let top = pos.y;
                let height = (headerItem.count + 1) * config.eventItemLineHeight + 20;

                onSetAddMoreState({
                    headerItem: headerItem,
                    left: left,
                    top: top,
                    height: height
                });
            }
        }
    }

    eventContainerRef = (element) => {
        this.eventContainer = element;
    }

    render() {
        const {
            resourceEvents,
            schedulerData,
            connectDropTarget,
            dndSource,
        } = this.props;
        const {
            isSelecting,
            left,
            width,
        } = this.state;

        const {
            hasSummary,
            headerItems,
            rowHeight,
            slotId,
        } = resourceEvents;
        const {
            cellUnit,
            startDate,
            endDate,
            config,
            viewType,
        } = schedulerData || {};
        const {
            dayStartFrom,
            dayStopTo,
            eventItemLineHeight,
            summaryPos,
        } = config || {};
        const selectedArea = isSelecting
            ? <SelectedArea
                {...this.props}
                left={left}
                width={width}
            />
            : <div />;
        const cellWidth = schedulerData.getContentCellWidth();
        const cellMaxEvents = schedulerData.getCellMaxEvents();
        const rowWidth = schedulerData.getContentTableWidth();
        const DnDEventItem = dndSource.getDragSource();
        let eventList = [];
        headerItems.forEach((headerItem, index) => {
            const {
                addMore,
                addMoreIndex,
                events,
                count,
                summary,
            } = headerItem;
            if (count > 0 || summary !== undefined) {
                let isTop = summaryPos === SummaryPos.TopRight || summaryPos === SummaryPos.Top || summaryPos === SummaryPos.TopLeft;
                let marginTop = hasSummary && isTop
                    ? 1 + eventItemLineHeight
                    : 1;
                let renderEventsMaxIndex = addMore === 0
                    ? cellMaxEvents
                    : addMoreIndex;

                // Si il y a plusieurs réservations dans le même créneau --- On les regroupe en une seule et le détail sera dans le popup
                if (count > 1) {
                    let durationStart = moment(startDate);
                    if (cellUnit === CellUnits.Hour) {
                        durationStart.add(dayStartFrom, 'hours');
                    }

                    let durationEnd = moment(endDate);
                    if (cellUnit === CellUnits.Hour) {
                        durationEnd.add(dayStopTo + 1, 'hours');
                    } else if (viewType === ViewTypes.Week) {
                        durationEnd.add(12, 'hours');
                    } else {
                        durationEnd.add(1, 'days');
                    }

                    /** On déclare les variables */
                    let eventStart;
                    let eventEnd;
                    let eventIsStart;
                    let eventIsEnd;
                    let eventLeft = index * cellWidth + (index > 0 ? 2 : 3);
                    let eventWidth;
                    let eventTop = marginTop;
                    let eventResourceId;
                    let eventId;
                    let eventBgColor;
                    /** On parcourt toutes les réservations */
                    events.forEach((evt, idx) => {
                        const {
                            render,
                            eventItem,
                            span,
                        } = evt || {};
                        if (evt !== undefined && render) {
                            const {
                                id,
                                bgColor,
                                end,
                                resourceId,
                                start,
                            } = eventItem || {};
                            /** On récupère les informations des réservations */
                            eventId = id;
                            eventResourceId = resourceId;
                            eventBgColor = bgColor;
                            eventWidth = (span * cellWidth - (index > 0 ? 5 : 6)) > 0
                                ? (span * cellWidth - (index > 0 ? 5 : 6))
                                : 0;
                            /** On récupère la date minimum pour le départ */
                            if (!eventStart || moment(start) < eventStart) {
                                eventStart = moment(start);
                                eventIsStart = eventStart >= durationStart;
                            }
                            /** On récupère la date max pour la fin */
                            if (!eventEnd || moment(end) > eventEnd) {
                                eventEnd = moment(end);
                                eventIsEnd = eventEnd <= durationEnd;
                            }
                        }
                    });
                    /** On vérifie que les informations sont bien renseignées */
                    if (eventId && eventStart && eventEnd && eventResourceId) {
                        /** On créé une nouvelle réservation à afficher */
                        const eventItemObject = {
                            id: eventId,
                            start: eventStart,
                            end: eventEnd,
                            resourceId: eventResourceId,
                            title: 'Réservé',
                            reservantId: null,
                            movable: false,
                            resizable: false,
                            bgColor: eventBgColor,
                            multipleEvents: 'true'
                        };
                        /** On envoie la réservation pour l'affichage */
                        const eventItem = <DnDEventItem
                            {...this.props}
                            key={eventItemObject.id}
                            eventItem={eventItemObject}
                            isStart={eventIsStart}
                            isEnd={eventIsEnd}
                            isInPopover={false}
                            left={eventLeft}
                            width={eventWidth}
                            top={eventTop}
                            leftIndex={index}
                            rightIndex={index}
                            multipleEvents={true}
                            headerItem={headerItem}
                        />
                        eventList.push(eventItem);
                    }

                } else {
                    /** On affiche la réservation 
                     * --- ce code était aussi utilisé dans le cas de plusieurs réservations pour toutes les afficher les unes en dessous des autres
                     * */
                    events.forEach((evt, idx) => {
                        if (idx < renderEventsMaxIndex && evt !== undefined && evt.render) {
                            let durationStart = moment(startDate);
                            let durationEnd = moment(endDate).add(1, 'days');
                            if (cellUnit === CellUnits.Hour) {
                                durationStart = moment(startDate).add(dayStartFrom, 'hours');
                                durationEnd = moment(endDate).add(dayStopTo + 1, 'hours');
                            }
                            let eventStart = moment(evt.eventItem.start);
                            let eventEnd = moment(evt.eventItem.end);
                            let isStart = eventStart >= durationStart;
                            let isEnd = eventEnd <= durationEnd;
                            let left = index * cellWidth + (index > 0 ? 2 : 3);
                            let width = (evt.span * cellWidth - (index > 0 ? 5 : 6)) > 0 ? (evt.span * cellWidth - (index > 0 ? 5 : 6)) : 0;
                            let top = marginTop + idx * eventItemLineHeight;
                            let eventItem = <DnDEventItem
                                {...this.props}
                                key={evt.eventItem.id}
                                eventItem={evt.eventItem}
                                isStart={isStart}
                                isEnd={isEnd}
                                isInPopover={false}
                                left={left}
                                width={width}
                                top={top}
                                leftIndex={index}
                                rightIndex={index + evt.span}
                                multipleEvents={false}
                                headerItem={headerItem}
                            />
                            eventList.push(eventItem);
                        }
                    });
                }
                if (addMore > 0) {
                    let left = index * cellWidth + (index > 0 ? 2 : 3);
                    let width = cellWidth - (index > 0 ? 5 : 6);
                    let top = marginTop + headerItem.addMoreIndex * eventItemLineHeight;
                    let addMoreItem = <AddMore
                        {...this.props}
                        key={headerItem.time}
                        headerItem={headerItem}
                        number={headerItem.addMore}
                        left={left}
                        width={width}
                        top={top}
                        clickAction={this.onAddMoreClick}
                    />;
                    //eventList.push(addMoreItem); // On désactive l'ajout du bouton pour afficher toutes les réservations
                }
                if (headerItem.summary !== undefined) {
                    let top = isTop ? 1 : rowHeight - eventItemLineHeight + 1;
                    let left = index * cellWidth + (index > 0 ? 2 : 3);
                    let width = cellWidth - (index > 0 ? 5 : 6);
                    let key = `${slotId}_${headerItem.time}`;
                    let summary = <Summary
                        key={key}
                        schedulerData={schedulerData}
                        summary={headerItem.summary}
                        left={left}
                        width={width}
                        top={top}
                    />;
                    eventList.push(summary);
                }
            }
        });

        return (
            <tr>
                <td style={{ width: rowWidth }}>
                    {
                        connectDropTarget(
                            <div
                                ref={this.eventContainerRef}
                                className="event-container"
                                style={{
                                    height: config.eventItemLineHeight + 2
                                }}
                            >
                                {selectedArea}
                                {eventList}
                            </div>
                        )
                    }
                </td>
            </tr>
        );
    }
}

export default ResourceEvents
