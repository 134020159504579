import React from 'react';

import { Form, Icon, Input } from 'semantic-ui-react';
import ClientCaptcha from "react-client-captcha";

const Captcha = (props) => {
    const { onChange, onEnterPress, error } = props;

    const [refreshCaptcha, setRefreshCaptcha] = React.useState(1);
    const [resCaptcha, setResCaptcha] = React.useState('');
    const [inputCaptcha, setInputCaptcha] = React.useState('');

    const handleCaptchaGuessChange = ({target: { value }}) => {
        setInputCaptcha(value.substring(0, 5));

        if (onChange) {
            onChange(resCaptcha, value.substring(0, 5))
        }
    }

    const handleCaptchaKeyDown = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            onEnterPress();
        } 
    }


    const handleCaptchaChange = (code) => { setResCaptcha(code) }

    const handleResetCaptcha = () => { setRefreshCaptcha(Math.random()) }

    React.useEffect(() => {
        setInputCaptcha('');

        if (onChange) {
            onChange(resCaptcha, '');
        }
    }, [resCaptcha, onChange]);

    return (
        <Form style={{ display: 'flex', alignItems: 'center' }}>
            <ClientCaptcha
                captchaCode={handleCaptchaChange}
                charsCount={5}
                width={130}
                retry={false}
                key={refreshCaptcha}
                backgroundColor='#32aecb'
                fontColor='#FFF'
                containerClassName='captcha'
            />
            <Icon 
                style={{ color: "#5270a9", marginLeft: 10, marginRight: 10 }}
                link
                name='refresh'
                size='large'
                onClick={handleResetCaptcha} 
            />
            <Form.Field
                style={{ width: 135, marginRight: 10, marginBottom: 0 }}
            >
                <Input
                    input={{ style: { borderRadius: 20 }}}
                    value={inputCaptcha}
                    placeholder='Saisir le captcha'
                    onChange={handleCaptchaGuessChange}
                    onKeyDown={handleCaptchaKeyDown}
                />
            </Form.Field>
            {error && (
                <span
                    style={{
                        fontWeight: 'bold',
                        fontSize: '1.2em',
                        color: '#e9453a',
                    }}
                >
                    Mauvais Captcha
                </span>
            )}
        </Form>
    );
};

export default Captcha;