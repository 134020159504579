import _ from 'lodash';
import React from 'react';

import {
    Popup as PopupSemantic
} from 'semantic-ui-react';

const Popup = (props) => {
    const {
        resourceViewItem,
        ...rest
    } = props;

    // Où rendre la Popup ; besoin de ça à cause de l'encapsulation du style
    const [mountNode, setMountNode] = React.useState(null);

    React.useEffect(() => {
        if (_.isNil(mountNode)) {
            const node = document.getElementById('JVS_publication_ligne');
            
            if (!_.isNil(node)) {
                setMountNode(node);
            }
        }
    }, [mountNode]);

    React.useEffect(() => {
        const node = document.getElementById('JVS_publication_ligne');
        
        if (!_.isNil(node)) {
            setMountNode(node);
        }
    }, []);

    if (_.isNil(mountNode)) {
        return null;
    }

    return (
        <PopupSemantic
            mountNode={mountNode}
            positionFixed
            popperModifiers={{
                preventOverflow: {
                    enabled: false,
                    padding: 0
                }
            }}
            {...rest}
        />
    );
};

export default Popup;