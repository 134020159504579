import {
    GET_ALL_TYPES_RESERVATIONS
} from '../constants/actionsTypes';

const initialState = {
    typesReservationsList: [],
    currentPage: 0,
    lastPage: 0,
};

export default function typesReservationsReducer(state = initialState, action) {
    
    switch (action.type) {
        case GET_ALL_TYPES_RESERVATIONS:
            return {
                ...state,
                typesReservationsList: action.typesReservationsList.data,
            };
        default:
            return state;
    }
}
