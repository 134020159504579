import Axios from 'axios';
import {
    GET_ALL_TYPES_SALLES
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getAllTypesSalles = () => (dispatch) => {
    Axios.get(URL_API + '/api/typesSalles?perPage=50').then(({ data }) => {
        dispatch({
            type: GET_ALL_TYPES_SALLES,
            typesSallesList: data,
        });
    });
};