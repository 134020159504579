import React from 'react';
import _ from 'lodash';

import Types from '360-types';

import { Grid } from 'semantic-ui-react';

import { ReactComponent as CalendrierSvg } from '../../assets/calendrier.svg';
import { ReactComponent as ReserverSvg } from '../../assets/mairie.svg';
import { ReactComponent as CoordonneesSvg } from '../../assets/coordonnees.svg';
import { ReactComponent as InfoComplementairesSvg } from '../../assets/aide.svg';

const ModalPreReservationResume = (props) => {
    const {
        debut,
        fin,
        salle,
        infosComplementaires,
        reservant,
    } = props;

    const {
        nomComplet,
        adresse,
        telephone,
        email
    } = reservant || {};

    return (
        <Grid className='resume'>
            <Grid.Row>
                <Grid.Column>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <label>
                                    <CalendrierSvg />
                                    Période de pré-réservation :
                                </label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <label>du </label><label className='value'>{debut ? debut.format('LL') : "-"}</label>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='right'>
                                <label>de </label><label className='value'>{debut ? debut.format('LT') : "-"}</label>
                            </Grid.Column>
                            <Grid.Column width={6} />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <label>au </label><label className='value'>{fin ? fin.format('LL') : "-"}</label>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='right'>
                                <label>à </label><label className='value'>{fin ? fin.format('LT') : "-"}</label>
                            </Grid.Column>
                            <Grid.Column width={6} />
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <label>
                                    <ReserverSvg />
                                    Vous souhaitez réserver :
                                </label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <label className='value'>{salle || "-"}</label>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <label>
                                    <CoordonneesSvg />
                                    Vos coordonnées :
                                </label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <label className='value'>{nomComplet !== '' ? nomComplet : "-"}</label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <label className='value'>{adresse || "-"}</label>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <label>tél. :</label><label className='value'>{telephone || "-"}</label>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <label>courriel :</label><label className='value'>{email || "-"}</label>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            {!_.isEmpty(infosComplementaires) && (
                <Grid.Row>
                    <Grid.Column>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <label>
                                        <InfoComplementairesSvg />
                                        Informations complémentaires :
                                    </label>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <p style={{ textAlign: 'justify', fontSize: '1.25rem', color: '#40a7e1', wordBreak: 'break-all' }}>
                                        {infosComplementaires}
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )}
        </Grid>
    );
};

ModalPreReservationResume.propTypes = {
    debut: Types.object,
    fin: Types.object,
    salle: Types.string,
    reservant: Types.object,
}

export default ModalPreReservationResume;