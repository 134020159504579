import { 
    GET_ALL_TYPE_RESERVANT_SALLE, 
    GET_TYPE_RESERVANT_BY_SALLE,
    GET_SALLE_BY_TYPE_RESERVANT,
} from '../constants/actionsTypes';

const initialState = {
    typeReservantBySalle: [],
    salleByTypeReservant: [],
    allTypeReservantSalle: [],
    currentPage: 0,
    lastPage: 0,
};

export default function typeReservantSalleReducer(state = initialState, action) {

    switch (action.type) {
        case GET_TYPE_RESERVANT_BY_SALLE:
            return {
                ...state,
                typeReservantBySalle: action.typeReservantBySalle
            }
        case GET_ALL_TYPE_RESERVANT_SALLE:
            return {
                ...state,
                allTypeReservantSalle: action.allTypeReservantSalle.data
            }
        case GET_SALLE_BY_TYPE_RESERVANT:
            return {
                ...state,
                salleByTypeReservant: action.salleByTypeReservant
            }
        default:
            return state;
    }
}
