import {
    GET_STATUT_WEB_EN_ATTENTE
} from '../constants/actionsTypes';

const initialState = {
    statutWebEnAttente: {},
    currentPage: 0,
    lastPage: 0,
};

export default function statutsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATUT_WEB_EN_ATTENTE:
            return {
                ...state,
                statutWebEnAttente: action.statutWebEnAttente[0],
            };
        default:
            return state;
    }
}
