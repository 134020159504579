import Axios from 'axios';
import { 
    GET_ALL_TYPE_RESERVANT_SALLE, 
    GET_TYPE_RESERVANT_BY_SALLE,
    GET_SALLE_BY_TYPE_RESERVANT,
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getTypeReservantBySalle = (idSalle, idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/typeReservantSalles/salle/'+idSalle+'?idEntite='+idEntite)
    .then(({ data }) => {
        dispatch({
            type: GET_TYPE_RESERVANT_BY_SALLE,
            typeReservantBySalle: data,
        });
    });
};

export const getSalleByTypeReservant = (idTypeReservant, idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/typeReservantSalles/typeReservant/'+idTypeReservant+'?idEntite='+idEntite)
    .then(({ data }) => {
        dispatch({
            type: GET_SALLE_BY_TYPE_RESERVANT,
            salleByTypeReservant: data,
        });
    });
};

export const getAllTypeReservantSalle = (idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/typeReservantSalles?perPage=200&idEntite='+idEntite)
    .then((data) => {
        dispatch({
            type: GET_ALL_TYPE_RESERVANT_SALLE,
            allTypeReservantSalle: data
        })
    })
}