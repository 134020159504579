import {
    GET_ALL_TYPES_SALLES
} from '../constants/actionsTypes';

const initialState = {
    typesSallesList: [],
    currentPage: 0,
    lastPage: 0,
};

export default function typesSallesReducer(state = initialState, action) {
    
    switch (action.type) {
        case GET_ALL_TYPES_SALLES:
            return {
                ...state,
                typesSallesList: action.typesSallesList.data,
            };
        default:
            return state;
    }
}
