import React from 'react';

import { Grid, Icon } from 'semantic-ui-react';

const EventItemPopoverResume = (props) => {
    const {
        startHour,
        endHour,
        id,
        onClick,
        rightGutter,
        interventionServiceTechnique,
        rowStyle,
    } = props;
    const { webdev } = window;
    
    const handleClick = () => {
        if (onClick) {
            onClick(null, false, id);
        }
    }

    return (
        <Grid
            key={id}
            {...webdev && {
                className: 'ant-popover-reservation',
                onClick: handleClick,
            }}
        >
            <Grid.Row style={rowStyle}>
                {rightGutter && (<Grid.Column width={1} style={{ padding: 0 }} />)}
                <Grid.Column width={rightGutter || interventionServiceTechnique ? 6 : 8}>
                    <div>
                        <span
                            style={{
                                fontSize: '0.8rem',
                            }}
                        >
                            De
                        </span>
                        <span
                            style={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                marginLeft: '0.4rem',
                            }}
                        >
                            {startHour}
                        </span>
                    </div>
                </Grid.Column>
                <Grid.Column width={rightGutter || interventionServiceTechnique ? 6 : 8}>
                    <div>
                        <span
                            style={{
                                fontSize: '0.8rem',
                            }}
                        >
                            à
                        </span>
                        <span
                            style={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                marginLeft: '0.4rem',
                            }}
                        >
                            {endHour}
                        </span>
                    </div>
                </Grid.Column>
                {rightGutter && (
                    <Grid.Column width={2} style={{ padding: 0 }}>
                        {( interventionServiceTechnique) && (
                            <Icon name='warning sign' />
                        )}
                    </Grid.Column>
                )}
            </Grid.Row>
        </Grid>
    )
}

export default EventItemPopoverResume;