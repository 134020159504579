import React from 'react'

import Button from './Button';
import {
    COULEUR_FOND_VIOLET,
} from '../config/colors';

const MenuBarButton = (props) => {
    const {
        active,
        buttonText,
        onClick,
        imageSvgComponent,
        ...rest
    } = props;

    return (
        <Button
            {...active ? { className: 'btnSelected' } : null}
            onClick={onClick}
            style={{
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: `2px solid ${COULEUR_FOND_VIOLET}`,
                borderRadius: '5px',
                color: `${COULEUR_FOND_VIOLET}`,
                display: 'flex',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                height: '2.7rem',
                justifyContent: 'center',
                marginLeft: '0.5rem',
                marginRight: '0.5rem',
            }}
            content={
                <>
                    {imageSvgComponent && (
                        <div
                            style={{
                                marginRight: '0.5rem',
                            }}
                        >
                            {imageSvgComponent}
                        </div>
                    )}
                    {buttonText}
                </>
            }
            {...rest}
        />
    );
}

export default MenuBarButton;