import _ from 'lodash';
import React from 'react';

import Types from '360-types';

import {
    Header
} from 'semantic-ui-react';

import { ReactComponent as PlacesAssisesSvg } from '../../assets/placeassisesvert.svg';
import { ReactComponent as CapaciteSvg } from '../../assets/capacitevert.svg';
import { ReactComponent as HorlogeSvg } from '../../assets/clock.svg';
import Superficie from '../../assets/superficie.png';
import { COULEUR_FOND_VIOLET } from '../../config/colors';

const SalleDescriptionPictos = (props) => {
    const {
        superficie,
        heureDebut,
        heureFin,
        placesAssises,
        placesTotales,
    } = props;

    const renderSuperficie = React.useMemo(() => {
        const libelle = !superficie || superficie === 0
            ? "-"
            : superficie + " m²"

        return (
            <div>
                <div style={{ marginTop: -4, paddingBottom: 4 }}><img style={{ height: 18.5 }} src={Superficie} /></div>
                <Header
                    as='h4'
                    style={{
                        margin: 0,
                        color: COULEUR_FOND_VIOLET
                    }}
                >
                    {libelle}
                </Header>
            </div>
        )
    }, [superficie]);

    const renderPlacesTotales = React.useMemo(() => {
        const libelle = !placesTotales || placesTotales === 0
            ? "-"
            : placesTotales + (placesTotales === 1 ? ' personne' : ' personnes')

        return (
            <div>
                <div><CapaciteSvg /></div>
                <Header
                    as='h4'
                    style={{
                        margin: 0,
                        color: COULEUR_FOND_VIOLET
                    }}
                >
                    {libelle}
                </Header>
            </div>
        )
    }, [placesTotales]);

    const renderPlacesAssises = React.useMemo(() => {
        const libelle = !placesAssises
            ? "-"
            : placesAssises + (placesAssises <= 1 ? ' place assise' : ' places assises')

        return (
            <div>
                <div><PlacesAssisesSvg /></div>
                <Header
                    as='h4'
                    style={{
                        margin: 0,
                        color: COULEUR_FOND_VIOLET
                    }}
                >
                    {libelle}
                </Header>
            </div>
        )
    }, [placesAssises]);

    const renderHoraires = React.useMemo(() => {
        const libelle = _.isEmpty(heureDebut) && _.isEmpty(heureFin)
            ? "-"
            : `${heureDebut} - ${heureFin}`;

        return (
            <div>
                <div>
                    <HorlogeSvg style={{ width: '1.3rem' }} />
                </div>
                <Header
                    as='h4'
                    style={{
                        margin: 0,
                        color: COULEUR_FOND_VIOLET
                    }}
                >
                    {libelle}
                </Header>
            </div>
        )
    }, [heureDebut, heureFin]);

    return (
        <>
            {renderHoraires}
            {renderSuperficie}
            {renderPlacesTotales}
            {renderPlacesAssises}
        </>
    );
};

SalleDescriptionPictos.propTypes = {
    superficie: Types.number,
    placesAssises: Types.number,
    placesTotales: Types.number,
}

SalleDescriptionPictos.defaultProps = {
    superficie: null,
    placesAssises: null,
    placesTotales: null,
}

export default SalleDescriptionPictos;
