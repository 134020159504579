/** Couleurs réservations */
export const COULEUR_INDISPONIBILITE = 'rgba(128,128,128,0.4)';
export const COULEUR_RESERVATION = 'rgb(22,74,110)';

/** Couleurs weekend */
export const COULEUR_WEEKEND_HEADER = '#FFF8F0';
export const COULEUR_WEEKEND_BODY = 'rgba(255,255,255,0)';

/** Couleur zone de sélection */
export const COULEUR_ZONE_DE_SELECTION = 'rgba(78,215,242,0.5)';

/** Couleur Groupes type Salle */
export const COULEUR_TYPE_SALLE = '#EEEEEE';

/** Couleurs aujourd'hui */
export const COULEUR_BACKGROUND_TODAY = 'rgb(22,74,110)';
export const COULEUR_TEXTE_TODAY = 'white';
export const COULEUR_BACKGROUND = '#F4F4F5';

/** Couleurs de la charte graphique */
export const COULEUR_FOND_VIOLET = '#5270A9';
export const COULEUR_BLEU = '#40A7E1';
export const COULEUR_BLEU_FONCE = '#32AFCB';
export const COULEUR_BLEU_CLAIR = '#3CD5DF';
export const COULEUR_BLEU_CLAIR_TRANSPARENT = '#3cd5df1a';
export const COULEUR_BLEU_CLAIR_DOCUMENTS = '#D5EEF4';
export const COULEUR_VERT = '#8FC746';
export const COULEUR_VERT_CLAIR = '#42D1A1';

/** COULEURS DE LA MAQUETTE */
export const COULEUR_1 = '#eef0f2'; // gris clair
export const COULEUR_2 = '#3cd6de'; // bleu ciel
export const COULEUR_3 = '#40a7e1'; // bleu clair
export const COULEUR_4 = '#43d1a1'; // turquoise
export const COULEUR_5 = '#5471a9'; // bleu violet
export const COULEUR_6 = '#33aecb'; // bleu
export const COULEUR_7 = '#90c647'; // vert pomme
export const COULEUR_8 = '#e0c435'; // jaune orange
export const COULEUR_9 = '#f99121'; // orange
export const COULEUR_10 = '#e9453a'; // rouge

// export const COULEUR_HACHURE_BLEU = repeating-linear-gradient(
//     '-45deg',
//     '#606dbc',
//     '#606dbc 10px',
//     '#465298 10px',
//     '#465298 20px',
//   );