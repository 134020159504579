import Axios from 'axios';
import {
    GET_ALL_TYPE_RESERVANT
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getAllTypeReservant = (config) => (dispatch) => {
    Axios.get(URL_API + '/api/typeReservant').then(({ data }) => {
        dispatch({
            type: GET_ALL_TYPE_RESERVANT,
            typeReservantList: data,
        });
    });
};