import moment from 'moment';
import {
    GET_ALL_DOCUMENTS_SALLES,
    DOWNLOAD_DOCUMENTS,
} from '../constants/actionsTypes';

const initialState = {
    documents: [],
    zipFile: null,
    currentPage: 0,
    lastPage: 0,
};

export default function docInfoReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_DOCUMENTS_SALLES:
            return {
                ...state,
                documents: action.documents.data.data,
            };
        case DOWNLOAD_DOCUMENTS:
            const zipName = `documents_planning_${new moment().format('DDMMYYYYHHmmss')}.zip`;
            const zipFile = new File([action.zip.data], zipName, { type: 'application/zip' });
            const zipURL = URL.createObjectURL(zipFile);
            const downloadLink = document.createElement('a');
            downloadLink.href = zipURL;
            downloadLink.setAttribute('download', zipName);
            downloadLink.click();
            URL.revokeObjectURL(zipURL);
            downloadLink.remove();
            return { ...state };
        default:
            return state;
    }
}
