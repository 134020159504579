import {
    ADD_RESERVANT, GET_ALL_RESERVANTS
} from '../constants/actionsTypes';

const initialState = {
    reservantsList: [],
    currentPage: 0,
    lastPage: 0,
};

export default function reservantsReducer(state = initialState, action) {

    switch (action.type) {
            case GET_ALL_RESERVANTS:
                return {
                    ...state,
                    reservantsList: action.reservantsList.data
                }
            case ADD_RESERVANT:
                return {
                    ...state,
                };
        default:
            return state;
    }
}
