import _ from 'lodash';
import React from 'react';
import moment from 'moment'

import {
    Form,
    Header,
    Grid,
    Container,
    List,
} from 'semantic-ui-react';

import { SalleDescription } from '../Salle';
import DatePicker from '../DatePicker/DatePicker';

const ModalPreReservationDemande = (props) => {
    const {
        allTypeReservantSalle,
        configDatabase,
        dateDebut,
        dateFin,
        defaultResource,
        fromPlanning,
        getAllTypeReservant,
        getRangePlannings,
        getTypeReservantBySalle,
        getSalleById,
        getSalleByTypeReservant,
        idEntite,
        onChange,
        salleByTypeReservant,
        sallesList,
        setDisplayCreneauDispo,
        typeReservant: typeReservantProps,
        typeReservantList,
        typeReservantBySalle,
    } = props;

    /** Champs */
    const [debut, setDebut] = React.useState(null);
    const [fin, setFin] = React.useState(null);
    const [typeReservant, setTypeReservant] = React.useState(0);
    const [searchTypeReservant, setSearchTypeReservant] = React.useState(null);
    const [resourceId, setResourceId] = React.useState(0);
    const [searchSalle, setSearchSalle] = React.useState(null);
    /** Options des selects */
    const [optionsTypesReservantFiltered, setOptionsTypesReservantFiltered] = React.useState([]);
    const [optionsSallesFiltered, setOptionsSallesFiltered] = React.useState([]);
    /** Autres */
    const [focusedColumn, setFocusedColumn] = React.useState(null);
    const [scrollingWithArrows, setScrollingWithArrows] = React.useState(false);
    const [descriptionSalleToDisplay, setDescriptionSalleToDisplay] = React.useState(0);

    const currentSalleSelected = optionsSallesFiltered?.find(({value}) => value === resourceId);
    const { text: nomSalleSelected } = currentSalleSelected || {};
    const currentTypeReservantSelected = optionsTypesReservantFiltered?.find(({value}) => value === typeReservant);
    const { text: typeReservantSelected } = currentTypeReservantSelected || {};
    const currentSalleToDisplay = optionsSallesFiltered?.find(({value}) => value === descriptionSalleToDisplay);
    const {
        capaciteAccueilAssis,
        capaciteAccueil,
        heureDebut,
        heureFin,
        description,
        superficie
    } = currentSalleToDisplay || {};

    const getNextElementId = (elementArray, id) => {
        const currentIndex = elementArray.findIndex(({ value }) => value === id);

        // prochain élément non désactivé
        const nextElement = elementArray.find(({ disabled }, index) => index > currentIndex && !disabled)

        if (nextElement === undefined) {
            return id;
        }

        return nextElement.value;
    }

    const getPreviousElementId = (elementArray, id) => {
        const reversedArray = [...elementArray].reverse();

        const currentIndex = reversedArray.findIndex(({ value }) => value === id);

        // élément d'avant non désactivé
        const nextElement = reversedArray.find(({ disabled }, index) => index > currentIndex && !disabled);

        if (nextElement === undefined) {
            return id;
        }

        return nextElement.value;
    }

    const handleColumnFocus = ({ target: { name: nameEvent }}, { name: nameData } = {}) => {
        const nomChamp = nameEvent || nameData;

        if (nomChamp === 'salle' && resourceId === 0 && optionsSallesFiltered?.length > 0) {
            handleSalleChange(optionsSallesFiltered[0].value, true)
        }
        
        if (nomChamp === 'typeReservant' && typeReservant === 0 && optionsTypesReservantFiltered?.length > 0) {
            handleTypeReservantChange(optionsTypesReservantFiltered[0].value, true)
        }

        if (nomChamp === 'debut' || nomChamp === 'fin') {
            setFocusedColumn('date');
        } else {
            setFocusedColumn(nomChamp);
        }

    }

    const handleKeyDown = (event) => {
        const { target: { name }, key } = event;

        if (focusedColumn === null && key !== 'Shift') {
            setFocusedColumn(name);
        } else {
            let array;

            if (name === 'salle') {
                array = optionsSallesFiltered.filter(
                    ({ text }) => searchSalle === null || text === '' || text.toLowerCase().includes(searchSalle.toLowerCase()
                ));
            } else {
                if (name === 'typeReservant') {
                    array = optionsTypesReservantFiltered.filter(
                        ({ text }) => searchTypeReservant === null || text === '' || text.toLowerCase().includes(searchTypeReservant.toLowerCase()
                    ));;
                }
            }

            const nombreElement = array?.length;
    
            let nextValue = name === 'salle' ? resourceId : typeReservant;
    
            if (key === 'ArrowUp' || key ==='ArrowDown') {
                if (nombreElement > 0) {
    
                    if (key === 'ArrowUp') {
                        nextValue = getPreviousElementId(array, nextValue);
                    }
    
                    if (key === 'ArrowDown') {
                        nextValue = getNextElementId(array, nextValue);
                    }

                    setScrollingWithArrows(true);

                    document.getElementById(name + nextValue).scrollIntoView({ block: "center" });
    
                    if (name === 'salle') {
                        setResourceId(nextValue);
                        setDescriptionSalleToDisplay(nextValue); 
                    }
    
                    if (name === 'typeReservant') {
                        setTypeReservant(nextValue);
                    }
                }
            }
    
            if (key === 'Enter' || key === 'Tab') {
                if (name === 'salle') {
                    handleSalleChange(nextValue);
                }
    
                if (name === 'typeReservant') {
                    handleTypeReservantChange(nextValue);
                }
    
                setFocusedColumn(null);
            }
        }
    }

    const handleDateChange = (name, value) => {
        if (!_.isEmpty(value)) {
            if (name === 'debut') {
                setDebut(value)

                if (onChange) {
                    onChange('startDate', value);
                }
            }

            if (name === 'fin') {
                setFin(value)

                if (onChange) {
                    onChange('endDate', value);
                }
            }
        }
    }

    const handleSearchChange = (event) => {
        const { target: { name, value } } = event;
        
        const val = value === '' ? null : value;

        if (name === 'salle') {
            setSearchSalle(val);
            handleSalleChange(0);
        }
        
        if (name === 'typeReservant') {
            setSearchTypeReservant(val);
            handleTypeReservantChange(0);
        }
    }

    const handleSalleOver = (id) => () => {
        if (!scrollingWithArrows) {
            setDescriptionSalleToDisplay(id);
        }
    }

    const handleSalleClick = (id) => () => {
        setFocusedColumn(null);
        setSearchSalle(null);
        handleSalleChange(id);
    }

    const handleSalleChange = (value, fromFocus = false) => {
        if (value !== 0) {
            setSearchSalle(null);
            getTypeReservantBySalle(value, idEntite, configDatabase) // SUITE: USEEFFECT typeReservantBySalle
            getSalleById(value, configDatabase) // SUITE : USEEFFECT salleById 
        } else {
            if (!fromFocus && typeReservant !== 0) {
                resetOptionsTypeReservant();
            }
        }

        /** Recherche si le créneau est dispo */
        if (debut && fin && value !== 0) {
            setDisplayCreneauDispo(false);
            getRangePlannings(
                debut.format('YYYY-MM-DD'),
                moment(fin).add(1, 'days').format('YYYY-MM-DD'), //on clone pour pas modifier par adresse
                idEntite,
                configDatabase
            );
            // SUITE USEEFFECT searchedPlannings
        }

        setResourceId(value);
        setDescriptionSalleToDisplay(value);

        if (onChange) {
            onChange('resourceId', value);
        }
    }

    const handleTypeReservantClick = (id) => () => {
        setFocusedColumn(null);
        setSearchTypeReservant(null);
        handleTypeReservantChange(id);
    }

    /**
     * Gestion de l'affichage du fomulaire entre les particuliers et les organisations 
     * Gestion dynamique des options des salles en fonction du type de réservant
     */
    const handleTypeReservantChange = (value, fromFocus = false) => {
        if (value !== 0) {
            setSearchTypeReservant(null);

            if (getSalleByTypeReservant) {
                getSalleByTypeReservant(value, idEntite, configDatabase) // SUITE: USEEFFECT salleByTypeReservant

                if (value !== typeReservant)
                resetOptionsSalle();
            }
        } else {
            if (!fromFocus && resourceId !== 0) {
                resetOptionsSalle();
            }
        }

        setTypeReservant(value);

        if (onChange) {
            onChange('typeReservant', value);
            onChange('isOrganisation', value !== 4) // 4 = Particulier
        }
    }

    /** Fonction de filtrage pour les type de réservants en fonction de la salle selectionnée */
    const filterOptionsReservants = () => {
        /** Tableau qui stocke les types de réservants filtrés */
        let typesReservantsFiltered = [];
        
        if (typeReservantList) {
            let typeReservantIdList = [];
            
            /** On stocke tous les liens de la salle séléctionnée avec les type de réservants */
            if (resourceId > 0) {
                typeReservantIdList = typeReservantBySalle?.map(
                    ({ identifiantTypeReservant: idTypeRes, reservable }) => ({ idTypeRes, isRes: reservable }),
                );
            }

            /** Récupération des types en vérifiant si ils sont acceptés pour la salle voulue */
            typeReservantList.forEach(({ identifiant, libelle }) => {
                let disabled = false
    
                /** On parcourt le tableau des liens */
                typeReservantIdList.forEach(({ idTypeRes, isRes }) => {
                    /** Si le type de réservant est présent dans la liste de lien et qu'il n'est pas réservable */
                    if (idTypeRes === identifiant && !isRes) {
                        /** On désactive pour qu'il apparaissent quand même mais pas sélectionnable */
                        disabled = true;
                    }
                })
    
                /** Dans le cas où le type sélectionné ne correspond pas avec la nouvelle salle sélectionnée, on reset le champs type de reservation */
                if (disabled && typeReservant === identifiant) {
                    setTypeReservant(0);
                }
    
                typesReservantsFiltered.push(
                    {
                        value: identifiant,
                        text: disabled ? libelle + ' (non disponible pour cette salle)' : libelle,
                        disabled: disabled
                    }
                )
            })
        }

        /** On tri le résultat pour faire remonter les types disponibles en haut */
        typesReservantsFiltered = typesReservantsFiltered.sort((a, b) => {
            return (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1;
        })

        setOptionsTypesReservantFiltered([
            {
                key: 0,
                value: 0,
                text: '',
            },
            ...typesReservantsFiltered
        ]);
    }

    /** Fonction qui permet de reset les options du champ type de réservant quand le choix de la salle est neutre */
    const resetOptionsTypeReservant = () => {
        /** Tableau qui stocke les types de réservants filtrés */
        let typesReservantsFiltered = [];

        if (typeReservantList) {
                /** Récupération des types en vérifiant si ils sont acceptés pour la salle voulue */
                typeReservantList.forEach(({ identifiant, libelle }) => {
                    /** Dans le cas où le type sélectionné ne correspond pas avec la nouvelle salle sélectionnée, on reset le champs type de reservation */
                    if (typeReservant === identifiant) {
                        setTypeReservant(0);
                    }
                    
                    typesReservantsFiltered.push(
                        {
                            value: identifiant,
                            text: libelle,
                        }
                );
            });
        }

        /** On tri le résultat pour faire remonter les types disponibles en haut */
        typesReservantsFiltered = typesReservantsFiltered.sort(function (a, b) {
            return (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1;
        });

        setOptionsTypesReservantFiltered([
            {
                key: 0,
                value: 0,
                text: '',
            },
            ...typesReservantsFiltered
        ]);
        setTypeReservant(0);
        
        if (onChange) {
            onChange('typeReservant', 0);
        }
    }

    const filterOptionsSalles = () => {
        let optionsSelectSalles = [];

        if (sallesList) {
            /** On parcourt les salles */
            sallesList.forEach(({ identifiant, nomSalle, reservationWeb, ...restSalle }) => {
                let disabled = false
    
                let affichageNom = nomSalle;
                let salleDisabledByTypeRes = false;
    
                /** On commence par désactiver les salles en fonction du type de réservant */
                salleByTypeReservant.forEach(({ identifiantSalle, reservable }) => {
                    if (identifiantSalle === identifiant && !reservable) {
                        salleDisabledByTypeRes = true;
                        affichageNom = nomSalle + ' (indisponible pour ce type de réservant)'
                    }
                })
    
                /** ENSUITE désactivation des salles non réservables */
    
                /** On stocke tous les liens entre la salle actuelle et les types de réservants */
                const typeReservantSalle = allTypeReservantSalle.filter(x => x.identifiantSalle === identifiant);
    
                /** On stocke tous les paramètres isReservable */
                const isReservableList = typeReservantSalle.map(x => x.reservable);
    
                let allTypeResDisabled = false;
    
                /** SI  tous les types de réservants sont à false*/
                if ((isReservableList.every((x) => !x) && isReservableList.length === typeReservantList.length) || !reservationWeb) {
                    /** On change le nom et on désactive */
                    affichageNom = nomSalle + ' (non réservable)'
                    allTypeResDisabled = true
                }
    
                disabled = allTypeResDisabled || salleDisabledByTypeRes
    
                /** On ajoute à la liste */
                optionsSelectSalles.push({
                    key: identifiant,
                    value: identifiant,
                    text: affichageNom,
                    disabled,
                    ...restSalle,
                });
            });
    
        }

        /** On tri le résultat pour faire remonter les salles disponibles en haut */
        optionsSelectSalles = optionsSelectSalles.sort((a, b) => {
            return (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1;
        });

        setOptionsSallesFiltered([
            {
                key: 0,
                value: 0,
                text: '',
            },
            ...optionsSelectSalles
        ]);
    }

    /** Fonction qui permet de reset les options du champ salle quand le type de réservant est neutre
     *  Seulement les salles qui ne peuvent pas être réservé seront désactivée
     */
    const resetOptionsSalle = () => {
        let optionsSelectSalles = [];

        if (sallesList) {
            /** On parcourt les salles */
            sallesList.forEach(salle => {
                const { identifiant, nomSalle, reservationWeb, ...restSalle } = salle;
    
                /** On stocke tous les liens entre la salle actuelle et les types de réservants */
                const typeReservantSalle = allTypeReservantSalle.filter(x => x.identifiantSalle === identifiant);
    
                /** On stocke tous les paramètres isReservable */
                const isReservableList = typeReservantSalle.map(x => x.reservable);
    
                let affichageNom = nomSalle;
                let disabled = false;
    
                /** SI  tous les types de réservants sont à false*/
                if (!reservationWeb || (isReservableList.every((x) => x === false) && isReservableList.length === typeReservantList.length)) {
                    /** On change le nom et on désactive */
                    affichageNom += ' (non réservable)'
                    disabled = true
                }
    
                /** On ajoute à la liste */
                optionsSelectSalles.push({
                    key: identifiant,
                    value: identifiant,
                    text: affichageNom,
                    disabled,
                    ...restSalle
                });
            });
        }

        /** On tri le résultat pour faire remonter les salles disponibles en haut */
        optionsSelectSalles = optionsSelectSalles.sort((a, b) => {
            return (a.disabled === b.disabled) ? 0 : a.disabled ? 1 : -1;
        });

        setOptionsSallesFiltered([
            {
                key: 0,
                value: 0,
                text: '',
            },
            ...optionsSelectSalles
        ]);

        // Si on vient pas du planning, ou qu'on en vient mais qu'on a changé manuellement de salle
        // on reset les salles
        if (_.isNil(fromPlanning) || (!_.isNil(fromPlanning) && fromPlanning !== resourceId)) {
            setResourceId(0);
            setDescriptionSalleToDisplay(0);

            if (onChange) {
                onChange('resourceId', 0);
            }
        }

    }

    /** Initialisation des states avec les valeurs par défault issues des props */
    React.useEffect(() => {
        if (defaultResource && defaultResource !== 0) {
            setResourceId(defaultResource);
            setDescriptionSalleToDisplay(defaultResource);

            if (getSalleById) {
                getSalleById(defaultResource, configDatabase);
            }
        } else {
            setResourceId(0);
            setDisplayCreneauDispo(false);
        }
    }, [
        defaultResource,
        configDatabase,
        getSalleById,
        setDisplayCreneauDispo
    ]);

    React.useEffect(() => {
        if (typeReservantProps && typeReservantProps !== 0) {
            setTypeReservant(typeReservantProps)
        } else {
            setTypeReservant(0);
        }
    }, [typeReservantProps]);

    React.useEffect(() => {
        if (dateDebut) {
            setDebut(dateDebut);
        } else {
            setDebut(null);
            setDisplayCreneauDispo(false);
        }
    }, [dateDebut, setDisplayCreneauDispo]);

    React.useEffect(() => {
        if (dateFin) {
            setFin(dateFin);
        } else {
            setFin(null);
            setDisplayCreneauDispo(false);
        }
    }, [dateFin, setDisplayCreneauDispo]);

    /** On initialise les options du type de réservant et le captcha en fonction de la salle */
    React.useEffect(() => {
        if (getTypeReservantBySalle) {
            if (resourceId !== 0) {
                getTypeReservantBySalle(resourceId, idEntite);
            } else {
                getAllTypeReservant();
            }
        }

        if (debut !== null && fin !== null) {
            if (getSalleById) {
                getSalleById(resourceId)
            }

            if (debut && fin && getRangePlannings) {
                getRangePlannings(
                    debut.format('YYYY-MM-DD'),
                    moment(fin).add(1, 'days').format('YYYY-MM-DD'), // on clone pour pas modif par adresse
                    idEntite,
                );
            }
        }
    }, [
        debut,
        fin,
        getAllTypeReservant,
        getTypeReservantBySalle,
        getSalleById,
        getRangePlannings,
        idEntite,
        resourceId,
    ])
    
    /** Initialisation des options des selects */
    React.useEffect(() => {
        filterOptionsReservants()
    }, [typeReservantBySalle, typeReservantList]);

    React.useEffect(() => {
        filterOptionsSalles()
    }, [salleByTypeReservant, sallesList, allTypeReservantSalle])

    /** on désactive le mouseover le temps du scroll avec les flèches */
    React.useEffect(() => {
        const timer = setTimeout(() => {
            setScrollingWithArrows(false);
        }, 250);

        return () => clearTimeout(timer);
    }, [scrollingWithArrows]);

    React.useEffect(() => {
        if (focusedColumn === 'salle' && resourceId !== 0) {
            document.getElementById(focusedColumn + resourceId).scrollIntoView({ behavior: 'smooth', block: "center" });
        }

        if (focusedColumn === 'typeReservant' && typeReservant !== 0) {
            document.getElementById(focusedColumn + typeReservant).scrollIntoView({ behavior: 'smooth', block: "center" });
        }
    }, [focusedColumn]);

    const salles = optionsSallesFiltered.filter(
        ({ text }) => searchSalle === null
            || text === ''
            || text.toLowerCase().includes(searchSalle.toLowerCase()
    ));

    const reservants = optionsTypesReservantFiltered.filter(
        ({ text }) => searchTypeReservant === null
            || text === ''
            || text.toLowerCase().includes(searchTypeReservant.toLowerCase()
    ));

    return (
        <Grid className='activable' style={{ marginBottom: 0 }}>
            <Grid.Row className={focusedColumn === 'typeReservant' ? 'active' : null}>
                <Grid.Column>
                    <Form.Input
                        name='typeReservant'
                        label='Type de réservant'
                        required
                        icon={{
                            name: 'caret down',
                            link: true,
                            onClick: () => { handleColumnFocus({ target: { name: 'typeReservant' }}) } 
                        }}
                        placeholder='Quel type de réservant êtes-vous ?'
                        onFocus={handleColumnFocus}
                        onKeyDown={handleKeyDown}
                        onChange={handleSearchChange}
                        value={searchTypeReservant || typeReservantSelected || ''}
                    />
                    {focusedColumn === 'typeReservant' && (
                        <Container
                            fluid
                            style={{
                                display: 'flex',
                                justifyItems: 'center',
                                marginTop: '-0.5em',
                                padding: '0 2px',
                            }}
                        >
                            <List link>
                                {reservants.map(({value, text, disabled}) => (
                                    <List.Item
                                        id={'typeReservant' + value}
                                        key={value}
                                        disabled={disabled}
                                        active={typeReservant === value}
                                        onClick={handleTypeReservantClick(value)}
                                        {...typeReservant !== value && { as: 'a' }}
                                    >
                                        <Header as='h5'>
                                            {text}
                                        </Header>
                                    </List.Item>
                                ))}
                            </List>
                        </Container>
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                className={focusedColumn === 'date' ? 'active' : null}
            >
                <Grid.Column>
                    <DatePicker
                        debut={debut}
                        fin={fin}
                        onChange={handleDateChange}
                        onFocus={handleColumnFocus}
                        focused={focusedColumn === 'date'}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={focusedColumn === 'salle' ? 'active' : null}>
                <Grid.Column>
                    <Form.Input
                        name='salle'
                        label='Salle'
                        required
                        icon={{
                            name: 'caret down',
                            link: true,
                            onClick: () => { handleColumnFocus({ target: { name: 'salle' }}) } 
                        }}
                        placeholder='Sélectionner une salle'
                        onFocus={handleColumnFocus}
                        onKeyDown={handleKeyDown}
                        onChange={handleSearchChange}
                        value={searchSalle || nomSalleSelected || ''}
                    />
                    {focusedColumn === 'salle' && (
                        <Container
                            fluid
                            style={{
                                display: 'flex',
                                justifyItems: 'center',
                                marginTop: '-0.5em',
                                padding: '0 2px',
                            }}
                        >
                            <List
                                link
                                style={{
                                    height: 210,
                                    width: 375,
                                    marginRight: '1em',
                                }}
                            >
                                {salles.map(({key, value, text, disabled}) => (
                                    <List.Item
                                        id={'salle' + value}
                                        key={key}
                                        disabled={disabled}
                                        active={resourceId === value}
                                        onMouseOver={handleSalleOver(value)}
                                        onClick={handleSalleClick(value)}
                                        {...resourceId !== value && { as: 'a' }}
                                    >
                                        <Header as='h5'>
                                            {text}
                                        </Header>
                                    </List.Item>
                                ))}
                            </List>
                            <div style={{ flex: 1 }}>
                                {descriptionSalleToDisplay > 0 && (
                                    <SalleDescription
                                        identifiant={descriptionSalleToDisplay}
                                        identifiantEntite={idEntite}
                                        configDatabase={configDatabase}
                                        download={false}
                                        description={description}
                                        heureDebut={heureDebut}
                                        heureFin={heureFin}
                                        superficie={superficie}
                                        placesTotales={capaciteAccueil}
                                        placesAssises={capaciteAccueilAssis}
                                    />
                                )}
                            </div>
                        </Container>
                    )}
                    {focusedColumn !== 'salle' && resourceId !== 0 && (
                        <Container fluid style={{padding: '0 20px'}}>
                            <SalleDescription
                                identifiant={resourceId}
                                identifiantEntite={idEntite}
                                configDatabase={configDatabase}
                                description={description}
                                heureDebut={heureDebut}
                                heureFin={heureFin}
                                superficie={superficie}
                                placesTotales={capaciteAccueil}
                                placesAssises={capaciteAccueilAssis}
                            />
                        </Container>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ModalPreReservationDemande;
