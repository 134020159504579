import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    Input,
    Table,
    Checkbox,
    Label,
    Modal,
    Form,
    Loader,
    Dimmer,
} from 'semantic-ui-react';

import { ReactComponent as DocumentSvg } from '../../assets/listedocument.svg';
import { ReactComponent as DocumentHeaderSvg } from '../../assets/documents_white.svg';
import { ReactComponent as TelechargerSvg } from '../../assets/telecharger.svg';

import {
    downloadDocuments,
    getAllDocumentsSalles,
} from '../../store/actions/docInfoAction';

import {
    COULEUR_VERT,
    COULEUR_VERT_CLAIR,
    COULEUR_BLEU,
    COULEUR_BLEU_FONCE,
    COULEUR_BLEU_CLAIR,
    COULEUR_FOND_VIOLET,
} from '../../config/colors';
import Button from '../Button';

const DocumentsContent = (props) => {
    const {
        idEntite,
        documentsList,
        sallesList,
        typesSallesList,
        downloadDocuments,
        getAllDocumentsSalles,
    } = props;

    // Texte de filtrage
    const [filterText, setFilterText] = React.useState('');
    // Flag pour la coche d'entête de colonne
    const [allItemsChecked, setAllItemsChecked] = React.useState(false);
    // Tableau des couleurs et des salles
    const [typesSallesColors, setTypesSallesColors] = React.useState([]);
    // Tableau des salles filtrées selon celles présentes dans les documents
    const [sallesDocumentsRows, setSallesDocumentsRows] = React.useState([]);
    // Tableau des salles affichées (avec prise en compte de la saisie dans le champ de recherche)
    const [sallesDocumentsRowsFiltered, setSallesDocumentsRowsFiltered] = React.useState([]);
    // Tableau contenant les éléments cochés
    const [checkedItems, setCheckedItems] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    // UseEffect pour aller rechercher les documents
    React.useEffect(() => {
        // On récupère la liste des ids de salles
        const sallesIdList = sallesList !== undefined
            ? sallesList.map((salle) => salle.identifiant)
            : [];
        // Type des documents à rechercher (constante DOI_TYPE)
        const typeDocumentMetier = 'DOCSAL';

        // On va recherche tous les documents des salles récupérées des props (si on a des salles)
        if (sallesIdList.length > 0) {
            getAllDocumentsSalles(idEntite, sallesIdList.join(), typeDocumentMetier, undefined);
        }
    }, [getAllDocumentsSalles, idEntite, sallesList]);

    // Fonction qui permet d'associer les types de salles et les couleurs
    React.useEffect(() => {
        // Tableau contenant les couleurs du planning
        const colors = [
            COULEUR_VERT_CLAIR,
            COULEUR_VERT,
            COULEUR_BLEU,
            COULEUR_BLEU_FONCE,
            COULEUR_BLEU_CLAIR,
        ];
        let typesSallesColors = [];

        // On parcourt les types de salles 
        typesSallesList.forEach((typeSalle, index) => {
            const groupColor = colors[index % colors.length];
            // Pour chaque type de salle on attribue une couleur
            typesSallesColors.push({
                identifiantTypeSalle: typeSalle.identifiant,
                color: groupColor,
            });
        });
        setTypesSallesColors(typesSallesColors);
    }, [typesSallesList]);

    // UseEffect pour construire les lignes de la table (nom salle, type, nb documents)
    React.useEffect(() => {
        let tableRows = [];
        // Tableau contenant la liste des ids de salles présent dans les documents
        const allSallesListIdFromDocument = documentsList !== undefined
            ? documentsList.map(({ identifiantEnregistrementOrigine }) => identifiantEnregistrementOrigine)
            : [];
        // On récupère la liste des ids de salles présent dans les documents */
        const sallesIdListFromDocument = allSallesListIdFromDocument.filter(
            (value, index, idList) => idList.indexOf(value) === index
        );

        // Si on a bien des salles et qu'on a récupéré des documents associés à celles-ci
        if (sallesList !== undefined && allSallesListIdFromDocument.length > 0 && sallesIdListFromDocument.length > 0) {

            // On parcourt la liste des idSalles des documents pour compter les occurences de chaque salle (= nb de docs)
            sallesIdListFromDocument.forEach(idSalle => {
                // On récupère les objets salle, typeSalle, typeSalleColor et le nb de documents de la salle
                const salle = sallesList.find(({ identifiant }) => identifiant === idSalle);

                if (salle !== undefined) {
                    const {
                        nomSalle,
                        identifiantTypesSalle,
                    } = salle;
                    const typeSalle = typesSallesList.find(({ identifiant }) => identifiant === identifiantTypesSalle);

                    if (typeSalle !== undefined) {
                        const {
                            identifiant,
                            libelle,
                        } = typeSalle;
                        const typeSalleColor = typesSallesColors.find(({ identifiantTypeSalle }) => identifiantTypeSalle === identifiant);
                        const salleNbDoc = allSallesListIdFromDocument.filter(id => id === idSalle).length;

                        if (typeSalleColor !== undefined && salleNbDoc > 0) {
                            const {
                                color,
                            } = typeSalleColor;
                            // On ajoute la ligne dans le tableau
                            tableRows.push({
                                idSalle,
                                nomSalle: nomSalle,
                                typeSalle: libelle,
                                typeSalleColor: color,
                                nbDoc: salleNbDoc,
                            });
                        }
                    }
                }
            });
        }
        // On met à jour la liste des salles dans le tableau
        setSallesDocumentsRows(tableRows);
    }, [documentsList, sallesList, typesSallesColors, typesSallesList]);

    // Mise à jour des noeuds filtrés utilisés pour l'affichage (intialisés avec les noeuds de base, le champ de recherche étant vide)
    React.useEffect(() => {
        setSallesDocumentsRowsFiltered(sallesDocumentsRows);
    }, [sallesDocumentsRows]);

    // Gestion de la modification du texte (→ filtrage des libellés)
    const handleFilterTextChange = React.useCallback(({ target: { value } }) => {
        // Mise à jour du texte
        setFilterText(value !== null ? value : '');

        // Si le texte saisi est vide on met toutes les lignes, sinon on filtre
        if (value === null || value === undefined || value === '') {
            setSallesDocumentsRowsFiltered(sallesDocumentsRows);
        } else {
            const sallesDocumentsRowsFiltered = sallesDocumentsRows.reduce((rowsList, salleDocumentRow) => {

                if (salleDocumentRow.nomSalle.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1
                    || salleDocumentRow.typeSalle.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) {
                    rowsList.push(salleDocumentRow);
                }
                return rowsList;
            }, []);
            setSallesDocumentsRowsFiltered(sallesDocumentsRowsFiltered);
        }
    }, [sallesDocumentsRows]);

    // Gestion du cochage/décochage d'un élément
    const handleCheckedItemsChange = React.useCallback((event, data) => {
        // On récupère les éléments cochés et l'info cochée/décochée
        const {
            checked,
            name: idSalleClicked,
        } = data;
        let checkedRows = checkedItems.slice(checkedItems.lenth);
        // On récupère la ligne concernée par le clic sur la coche
        const rowSalleDocumentClicked = sallesDocumentsRowsFiltered.find(({ idSalle }) => idSalle === parseInt(idSalleClicked));

        if (rowSalleDocumentClicked !== undefined) {
            // On ajoute/retire la ligne dans le tableau des lignes cochées
            if (checked) {
                checkedRows.push(rowSalleDocumentClicked);
            } else {
                checkedRows = checkedRows.filter((checkedRow) => checkedRow.idSalle !== rowSalleDocumentClicked.idSalle);
            }
            // On met à jour la liste des lignes cochées
            setCheckedItems(checkedRows);

            // Si on a (pas) toutes les lignes cochées, on coche (décoche) celle d'entête de colonne
            if (checkedRows.length < sallesDocumentsRowsFiltered.length) {
                setAllItemsChecked(false);
            } else {
                setAllItemsChecked(true);
            }
        }
    }, [checkedItems, sallesDocumentsRowsFiltered]);

    // Gestion du cochage/décochage de la coche d'entête de colonne
    const handleAllCheckItemsChange = React.useCallback((event, data) => {
        const {
            checked,
        } = data;
        setAllItemsChecked(checked);

        // On ajoute/retire toutes les lignes
        if (checked) {
            setCheckedItems(sallesDocumentsRowsFiltered);
        } else {
            setCheckedItems([]);
        }
    }, [sallesDocumentsRowsFiltered]);

    // Gestion du clic sur le bouton download (→ téléchargement des éléments présents dans checkedItem)
    const handleClickDownload = () => {
        if (checkedItems.length > 0) {
            const idSalleList = checkedItems.map(({ idSalle }) => idSalle).join();
            if (idSalleList?.length > 0) {
                setLoading(true);

                downloadDocuments(idEntite, idSalleList)
                    .finally(() => { setLoading(false) });
            }
        }
    }

    return (
        <>
            <Modal.Header>
                <DocumentHeaderSvg />
                <span style={{ marginLeft: '0.5rem' }}>
                    Recherche de documents
                </span>
            </Modal.Header>
            <Modal.Content>
                <Form>
                    {/* Champ de saisie pour la recherche d'une salle ou d'un type de salle */}
                    <Input
                        className='searchInput'
                        transparent
                        value={filterText}
                        onChange={handleFilterTextChange}
                        placeholder='Saisir une salle ou un type de salle'
                        icon={{
                            name: 'search',
                            size: 'large',
                            style: {
                                color: `${COULEUR_FOND_VIOLET}`,
                                marginRight: '0.5rem',
                            }
                        }}
                    />
                    {/* Affichage de la table avec les salles */}
                    {sallesDocumentsRowsFiltered !== null
                        && sallesDocumentsRowsFiltered !== undefined
                        && sallesDocumentsRowsFiltered?.length > 0
                        ? (
                            <div className='scrollableTable'>
                                <Table
                                    verticalAlign='middle'
                                    size='small'
                                >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell textAlign='center'>
                                                <Checkbox
                                                    checked={allItemsChecked}
                                                    onChange={handleAllCheckItemsChange}
                                                />
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Salles disponibles
                                        </Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>
                                                Type de salle
                                        </Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>
                                                Document(s)
                                        </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {sallesDocumentsRowsFiltered.map((salle) => {
                                            const {
                                                idSalle,
                                                nomSalle,
                                                typeSalle,
                                                typeSalleColor,
                                                nbDoc,
                                            } = salle;
                                            const isChecked = checkedItems.findIndex((checkedRaw) => checkedRaw.idSalle === idSalle) > -1;

                                            return (
                                                <Table.Row
                                                    key={idSalle}
                                                    verticalAlign='middle'
                                                >
                                                    <Table.Cell textAlign='center'>
                                                        <Checkbox
                                                            name={idSalle.toString()}
                                                            checked={isChecked}
                                                            onChange={handleCheckedItemsChange}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell style={{ fontWeight: 'bold' }}>
                                                        {nomSalle}
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center'>
                                                        <Label
                                                            className='typeSalleLabel'
                                                            content={typeSalle}
                                                            style={{
                                                                backgroundColor: `${typeSalleColor}`,
                                                            }}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center'>
                                                        <DocumentSvg />
                                                        <span className='textNbDoc'>
                                                            {nbDoc}
                                                        </span>
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        ) : (
                            <>
                                <div style={{ textAlign: 'center' }}>
                                    Aucun document à afficher
                                </div>
                            </>
                        )
                    }
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Dimmer active={loading} inverted>
                    <Loader size='big' />
                </Dimmer>
                <Button
                    disabled={loading || checkedItems?.length === 0}
                    positive
                    onClick={handleClickDownload}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.2rem',
                    }}
                >
                    <div style={{ marginRight: '0.5rem' }}>
                        <TelechargerSvg
                            fill="white"
                        />
                    </div>
                    Télécharger
                </Button>
            </Modal.Actions>
        </>
    );
};

export default connect(
    (state) => ({
        documentsList: state.docInfoReducer?.documents,
    }),
    (dispatch) => bindActionCreators({
        getAllDocumentsSalles: getAllDocumentsSalles,
        downloadDocuments: downloadDocuments,
    }, dispatch),
)(DocumentsContent)
