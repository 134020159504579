import _ from 'lodash';
import React from 'react';

import {
    Form,
    List,
    Header,
    Grid,
    Label,
    Container,
} from 'semantic-ui-react';

import DatePicker from '../DatePicker/DatePicker';
import { ReactComponent as CapaciteSvg } from '../../assets/capacitebleu.svg';
import { ReactComponent as PlacesAssisesSvg } from '../../assets/placeassisesbleu.svg';
import { ReactComponent as PlacesDeboutSvg } from '../../assets/placesdebout.svg';

const ModalDisponibiliteCriteres = (props) => {
    const {
        activites,
        defaultActivite,
        defaultCapacite,
        defaultDebut,
        defaultFin,
        defaultPlacesAssises,
        defaultPlacesDebouts,
        defaultTypeReservant,
        defaultTypeSalle,
        onChange,
        typesSalle,
        typesReservant,
    } = props;
    
    /** state de champs */
    const [debut, setDebut] = React.useState(defaultDebut);
    const [fin, setFin] = React.useState(defaultFin);
    const [typeReservant, setTypeReservant] = React.useState(
        !_.isNil(defaultTypeReservant)
            ? defaultTypeReservant
            : null
    );
    const [activite, setActivite] = React.useState(defaultActivite || 0);
    const [typeSalle, setTypeSalle] = React.useState(defaultTypeSalle || 0);
    const [capacite, setCapacite] = React.useState(defaultCapacite || '');
    const [placesAssises, setPlacesAssises] = React.useState(defaultPlacesAssises || '');
    const [placesDebouts, setPlacesDebouts] = React.useState(defaultPlacesDebouts || '');
    /** states de gestion */
    const [activitesList, setActivitesList] = React.useState([]);
    const [typesSalleList, setTypesSalleList] = React.useState([]);
    const [typesReservantList, setTypesReservantList] = React.useState([]);
    const [searchActivite, setSearchActivite] = React.useState(null);
    const [searchTypeReservant, setSearchTypeReservant] = React.useState(null);
    const [searchTypeSalle, setSearchTypeSalle] = React.useState(null);
    const [focusedColumn, setFocusedColumn] = React.useState(null);

    const currentTypeReservantSelected = typesReservantList?.find(({value}) => value === typeReservant);
    const { text: typeReservantSelected } = currentTypeReservantSelected || {};
    const currentTypeSalleSelected = typesSalleList?.find(({value}) => value === typeSalle);
    const { text: typeSalleSelected } = currentTypeSalleSelected || {};
    const currentActiviteSelected = activitesList?.find(({value}) => value === activite);
    const { text: activiteSelected } = currentActiviteSelected || {};

    const filteredActivites = _.filter(activitesList, ({ text }) => searchActivite === null
        || searchActivite === ''
        || text === ''
        || text.toLowerCase().includes(searchActivite.toLowerCase())
    );
    const filteredTypesReservant = _.filter(typesReservantList, ({ text }) => searchTypeReservant === null
        || searchTypeReservant === ''
        || text === ''
        || text.toLowerCase().includes(searchTypeReservant.toLowerCase())
    );
    const filteredTypesSalle = _.filter(typesSalleList, ({ text }) => searchTypeSalle === null
        || searchTypeSalle === ''
        || text === ''
        || text.toLowerCase().includes(searchTypeSalle.toLowerCase())
    );

    const getNextElementId = (elementArray, id) => {
        const currentIndex = elementArray.findIndex(({ value }) => value === id);

        // prochain élément non désactivé
        const nextElement = elementArray.find(({ disabled }, index) => index > currentIndex && !disabled)

        if (nextElement === undefined) {
            return id;
        }

        return nextElement.value;
    }

    const getPreviousElementId = (elementArray, id) => {
        const reversedArray = [...elementArray].reverse();

        const currentIndex = reversedArray.findIndex(({ value }) => value === id);

        // élément d'avant non désactivé
        const nextElement = reversedArray.find(({ disabled }, index) => index > currentIndex && !disabled);

        if (nextElement === undefined) {
            return id;
        }

        return nextElement.value;
    }

    const isColumnFocused = (columnName) => {
        if (columnName === 'typeSalleCapacite') {
            return _.includes(['capacite', 'placesAssises', 'placesDebouts', 'typeSalle'], focusedColumn);
        }

        if (columnName === 'typeReservantActivite') {
            return _.includes(['typeReservant', 'activite'], focusedColumn);
        }
    }

    const handleColumnFocus = ({ target: { name: nameEvent }}, { name: nameData } = {}) => {
        const nomChamp = nameEvent || nameData;

        if (nomChamp === 'debut' || nomChamp === 'fin') {
            setFocusedColumn('date');
        } else {
            setFocusedColumn(nomChamp);
        }

    }

    const handleKeyDown = (event) => {
        const { target: { name }, key } = event;

        if (focusedColumn === null && key !== 'Shift') {
            setFocusedColumn(name);
        } else {
            let array = null;
            let nextValue = null;

            if (name === 'typeReservant') {
                array = filteredTypesReservant;
                nextValue = typeReservant;
            } else if (name === 'typeSalle') {
                array = filteredTypesSalle;
                nextValue = typeSalle;
            } else {
                array = filteredActivites;
                nextValue = activite;
            }

            const nombreElement = array?.length;
    
            if (key === 'ArrowUp' || key ==='ArrowDown') {
                if (nombreElement > 0) {
    
                    if (key === 'ArrowUp') {
                        nextValue = getPreviousElementId(array, nextValue);
                    }
    
                    if (key === 'ArrowDown') {
                        nextValue = getNextElementId(array, nextValue);
                    }

                    document.getElementById(name + nextValue).scrollIntoView({ block: "center" });
    
                    if (name === 'typeReservant') {
                        setTypeReservant(nextValue);
                    }

                    if (name === 'typeSalle') {
                        setTypeSalle(nextValue);
                    }

                    if (name === 'activite') {
                        setActivite(nextValue);
                    }
                }
            }
    
            if (key === 'Enter' || key === 'Tab') {    
                if (name === 'typeReservant') {
                    handleTypeReservantChange(nextValue);
                }

                if (name === 'typeSalle') {
                    handleTypeSalleChange(nextValue);
                }

                if (name === 'activite') {
                    handleActiviteChange(nextValue);
                }
    
                setFocusedColumn(null);
            }
        }
    }

    const handleDateChange = (name, value) => {
        if (!_.isEmpty(value)) {
            if (name === 'debut') {
                setDebut(value)

                if (onChange) {
                    onChange('debut', value);
                }
            }

            if (name === 'fin') {
                setFin(value)

                if (onChange) {
                    onChange('fin', value);
                }
            }
        }
    }

    const handleSearchChange = (event) => {
        const { target: { name, value } } = event;
        
        if (name === 'typeReservant') {
            setSearchTypeReservant(value);
            handleTypeReservantChange(0);
        }

        if (name === 'activite') {
            setSearchActivite(value);
            handleActiviteChange(0);
        }

        if (name === 'typeSalle') {
            setSearchTypeSalle(value);
            handleTypeSalleChange(0);
        }
    }

    const handleTypeReservantClick = (id) => () => {
        setFocusedColumn(null);
        setSearchTypeReservant(null);
        handleTypeReservantChange(id);
    }

    const handleTypeReservantChange = (value) => {
        if (value !== 0) {
            setSearchTypeReservant(null);
        }

        setTypeReservant(value);

        if (onChange) {
            onChange('typeReservant', value);
        }
    }

    const handleActiviteClick = (id) => () => {
        setFocusedColumn(null);
        setSearchActivite(null);
        handleActiviteChange(id);
    }

    const handleActiviteChange = (value) => {
        if (value !== 0) {
            setSearchActivite(null);
        }

        setActivite(value);

        if (onChange) {
            onChange('activite', value);
        }
    }

    const handleTypeSalleClick = (id) => () => {
        setFocusedColumn(null);
        setSearchTypeSalle(null);
        handleTypeSalleChange(id);
    }

    const handleTypeSalleChange = (value) => {
        if (value !== 0) {
            setSearchTypeSalle(null);
        }

        setTypeSalle(value);

        if (onChange) {
            onChange('typeSalle', value);
        }
    }

    const handleChange = ({ target: { name, value }}) => {
        let val = value === '' ? null : _.toFinite(value);

        if (name === 'capacite') {
            setCapacite(val);
        }

        if (name === 'placesAssises') {
            setPlacesAssises(val);
        }

        if (name === 'placesDebouts') {
            setPlacesDebouts(val);
        }

        if (onChange) {
            onChange(name, val);
        }
    }

    React.useEffect(() => {
        setActivitesList([
            {
                key: 'all',
                value: 0,
                text: 'Toutes les activités',
            },
            ..._.sortBy(_.map(activites, ({ identifiant, libelle }) => ({
                key: 'activite'+identifiant,
                value: identifiant,
                text: libelle,
            })), ['text'])
        ]);
    }, [activites]);

    React.useEffect(() => {
        setTypesReservantList([
            {
                key: 'all',
                value: 0,
                text: 'Tout type de réservant',
            },
            ..._.sortBy(_.map(typesReservant, ({ identifiant, codeUnique, libelle }) => ({
                key: 'typeReservant'+identifiant,
                value: identifiant,
                text: libelle,
            })), ['text'])
        ]);
    }, [typesReservant]);

    React.useEffect(() => {
        setTypesSalleList([
            {
                key: 'all',
                value: 0,
                text: 'Toutes les salles',
            },
            ..._.sortBy(_.map(typesSalle, ({ identifiant, libelle }) => ({
                key: 'typeSalle'+identifiant,
                value: identifiant,
                text: libelle
            })), ['text'])
        ]);
    }, [typesSalle]);

    React.useEffect(() => {
        // permet la gestion de chaine vide en valeur de recherche
        setSearchTypeReservant(null);
        setSearchActivite(null);
        setSearchTypeSalle(null);
    }, [focusedColumn]);

    return (
        <Grid className='activable' style={{ marginBottom: 0 }}>
            <Grid.Row className={focusedColumn === 'date' ? 'active' : null}>
                <Grid.Column>
                    <DatePicker
                        debut={debut}
                        fin={fin}
                        onChange={handleDateChange}
                        onFocus={handleColumnFocus}
                        focused={focusedColumn === 'date'}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: 0 }}>
                <Grid.Column>
                    <label style={{ fontSize: '1.25em' }}>Autres critères de recherche</label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={isColumnFocused('typeReservantActivite') ? 'active' : null} style={{ paddingTop: 0 }}>
                <Grid.Column width={8}>
                    <Form.Input
                        name='typeReservant'
                        label='Type de réservant'
                        required
                        icon={{
                            name: 'caret down',
                            link: true,
                            onClick: () => { handleColumnFocus({ target: { name: 'typeReservant' }}) } 
                        }}
                        placeholder='Renseigner une nature'
                        onFocus={handleColumnFocus}
                        onKeyDown={handleKeyDown}
                        onChange={handleSearchChange}
                        value={!_.isNil(searchTypeReservant) ? searchTypeReservant : (typeReservantSelected || '')}
                    />
                    {focusedColumn === 'typeReservant' && (
                        <Container
                            fluid
                            style={{
                                display: 'flex',
                                justifyItems: 'center',
                                marginTop: '-0.5em',
                                padding: '0 2px',
                            }}
                        >
                            <List
                                link
                                style={{ height: 150 }}
                            >
                                {_.map(filteredTypesReservant, ({value, text}) => (
                                    <List.Item
                                        id={'typeReservant' + value}
                                        key={value}
                                        active={typeReservant === value}
                                        onClick={handleTypeReservantClick(value)}
                                        {...typeReservant !== value && { as: 'a' }}
                                    >
                                        <Header as='h5'>
                                            {text}
                                        </Header>
                                    </List.Item>
                                ))}
                            </List>
                        </Container>
                    )}
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form.Input
                        name='activite'
                        label='Pour quelle activité ?'
                        icon={{
                            name: 'caret down',
                            link: true,
                            onClick: () => { handleColumnFocus({ target: { name: 'activite' }}) } 
                        }}
                        placeholder='Renseigner une activité'
                        onFocus={handleColumnFocus}
                        onKeyDown={handleKeyDown}
                        onChange={handleSearchChange}
                        value={!_.isNil(searchActivite) ? searchActivite : (activiteSelected || '')}
                    />
                    {focusedColumn === 'activite' && (
                        <Container
                            fluid
                            style={{
                                display: 'flex',
                                justifyItems: 'center',
                                marginTop: '-0.5em',
                                padding: '0 2px',
                            }}
                        >
                            <List
                                link
                                style={{ height: 150 }}
                            >
                                {_.map(filteredActivites, ({value, text}) => (
                                    <List.Item
                                        id={'activite' + value}
                                        key={value}
                                        active={activite === value}
                                        onClick={handleActiviteClick(value)}
                                        {...activite !== value && { as: 'a' }}
                                    >
                                        <Header as='h5'>
                                            {text}
                                        </Header>
                                    </List.Item>
                                ))}
                            </List>
                        </Container>
                    )}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={isColumnFocused('typeSalleCapacite') ? 'active' : null}>
                <Grid.Column width={8} style={{ paddingBottom: 0 }}>
                    <Form.Input
                        name='typeSalle'
                        label='Type de salle'
                        icon={{
                            name: 'caret down',
                            link: true,
                            onClick: () => { handleColumnFocus({ target: { name: 'typeSalle' }}) } 
                        }}
                        placeholder='Renseigner un type de salle'
                        onFocus={handleColumnFocus}
                        onKeyDown={handleKeyDown}
                        onChange={handleSearchChange}
                        value={!_.isNil(searchTypeSalle) ? searchTypeSalle : (typeSalleSelected || '')}
                    />
                    {focusedColumn === 'typeSalle' && (
                        <Container
                            fluid
                            style={{
                                display: 'flex',
                                justifyItems: 'center',
                                marginTop: '-0.5em',
                                padding: '0 2px',
                            }}
                        >
                            <List link>
                                {_.map(filteredTypesSalle, ({value, text}) => (
                                    <List.Item
                                        id={'typeSalle' + value}
                                        key={value}
                                        active={typeSalle === value}
                                        onClick={handleTypeSalleClick(value)}
                                        {...typeSalle !== value && { as: 'a' }}
                                    >
                                        <Header as='h5'>
                                            {text}
                                        </Header>
                                    </List.Item>
                                ))}
                            </List>
                        </Container>
                    )}
                </Grid.Column>
                <Grid.Column width={7} style={{ paddingBottom: 0 }}>
                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid
                            name='capacite'
                            label='Capacité'
                            labelPosition='left'
                            value={capacite}
                            onChange={handleChange}
                            onFocus={handleColumnFocus}
                        >
                            <Label basic style={{
                                borderRadius: '25px 0 0 25px',
                                borderRight: 0
                            }}>
                                <CapaciteSvg />
                            </Label>
                            <input style={{ borderRadius: '0 25px 25px 0' }}/>
                        </Form.Input>
                        <Form.Input
                            fluid
                            name='placesAssises'
                            label='Places assises'
                            labelPosition='left'
                            value={placesAssises}
                            onChange={handleChange}
                            onFocus={handleColumnFocus}
                        >
                            <Label basic style={{
                                borderRadius: '25px 0 0 25px',
                                borderRight: 0
                            }}>
                                <PlacesAssisesSvg />
                            </Label>
                            <input style={{ borderRadius: '0 25px 25px 0' }}/>
                        </Form.Input>
                        <Form.Input
                            fluid
                            name='placesDebouts'
                            label='Places debouts'
                            labelPosition='left'
                            value={placesDebouts}
                            onChange={handleChange}
                            onFocus={handleColumnFocus}
                        >
                            <Label basic style={{
                                borderRadius: '25px 0 0 25px',
                                borderRight: 0
                            }}>
                                <PlacesDeboutSvg />
                            </Label>
                            <input style={{ borderRadius: '0 25px 25px 0' }}/>
                        </Form.Input>
                    </Form.Group>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};

export default ModalDisponibiliteCriteres;