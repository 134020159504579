import {
    GET_ALL_SALLES,
    GET_ARRAY_SALLE_BY_ID,
    GET_SALLE_BY_ID,
    GET_SALLES_DISPONIBLES,
    GET_SALLES_TYPE_RESERVATION,
} from '../constants/actionsTypes';

const initialState = {
    sallesList: [],
    sallesDisponibles: [],
    salleById: {},
    sallesTypesReservationsList: [],
    currentPage: 0,
    lastPage: 0,
};

export default function sallesReducer(state = initialState, action) {
    
    switch (action.type) {
        case GET_ALL_SALLES:
            return {
                ...state,
                sallesList: action.sallesList.data,
            };
        case GET_ARRAY_SALLE_BY_ID:
            return {
                ...state,
                sallesList: action.sallesList?.data || [],
            };
        case GET_SALLE_BY_ID:
            return {
                ...state,
                salleById: action.salleById
            };
        case GET_SALLES_DISPONIBLES:
            return {
                ...state,
                sallesDisponibles: action.sallesDisponibles
            }
        case GET_SALLES_TYPE_RESERVATION:
            return {
                ...state,
                sallesTypesReservationsList: action.sallesTypesReservationsList,
            }
        default:
            return state;
    }
}
