import _ from 'lodash';
import React from 'react';

import Types from '360-types';

import {
    Grid,
    Header,
    Label,
} from 'semantic-ui-react';

import { COULEUR_BLEU_CLAIR, COULEUR_FOND_VIOLET } from '../../config/colors';

const SallePhotoList = (props) => {
    const {
        photos,
    } = props;

    const [randomizedPhotos, setRandomizedPhotos] = React.useState();
    const [current, setCurrent] = React.useState(0);
    
    const handlePhotoClick = () => {
        const newTab = window.open('_blank');

        newTab.document.write(`
            <html>
                <head>
                </head>
                <body style="margin:0;background:#111;display:flex;justify-content:center;align-items:center;">
                    <img src="${randomizedPhotos[current].url}" alt="${randomizedPhotos[current].nom}" style="max-height:100%;">
                </body>
            </html>
        `);

        // nom de l'onglet
        newTab.document.title = randomizedPhotos[current].nom;

        // permet d'enlever le loading infini sur l'onglet
        newTab.document.close();
    }

    const handlePhotoChange = (index) => () => {
        setCurrent(index);
    }

    const renderPastilles = React.useMemo(() => {
        if (_.isEmpty(randomizedPhotos) || _.size(randomizedPhotos) < 2) {
            return null;
        }
        
        const style = {
            marginLeft: 7,
            marginRight: 7,
        };

        return _.map(randomizedPhotos, (p, index) => (
            <Label
                key={index}
                as='a'
                onClick={handlePhotoChange(index)}
                circular
                empty
                size={index === current ? 'mini' : 'medium'}
                basic={index === current}
                style={index !== current
                    ? {
                        ...style,
                        border: '1px transparent',
                        backgroundColor: COULEUR_FOND_VIOLET
                    } : {
                        ...style,
                        fontSize: '0.5rem',
                        borderWidth: 3,
                        borderColor: COULEUR_BLEU_CLAIR
                    } 
                }
            />
        ))
    }, [randomizedPhotos, current]);

    React.useEffect(() => {
        setCurrent(0);
        setRandomizedPhotos(_.slice(_.shuffle(photos), 0, 3))
    }, [photos]);

    if (_.isEmpty(randomizedPhotos)) {
        return null;
    }

    return (
        <Grid>
            <Grid.Row columns={1} style={{ paddingBottom: 0, paddingTop: '2rem' }}>
                <Grid.Column style={{ overflow: 'hidden', textAlign: 'center' }}>
                    <Header as='h4'> </Header> {/* il y a un espace insécable */}
                    <img
                        style={{
                            cursor: 'pointer',
                            height: 100,
                            maxWidth: 'none',
                            margin: '0 calc(-100% + 10px)',
                        }}
                        onClick={handlePhotoClick}
                        src={randomizedPhotos[current].url}
                        alt={randomizedPhotos[current].nom}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} style={{ paddingTop: 10, paddingBottom: 0 }}>
                <Grid.Column
                    style={{    
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {renderPastilles}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

SallePhotoList.propTypes = {
    photos: Types.array,
}

SallePhotoList.defaultProps = {
    photos: null,
}

export default SallePhotoList;