import _ from 'lodash';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Axios from 'axios';

import Types from '360-types';
import {
    Dimmer,
    Grid,
    Header,
    Loader,
} from 'semantic-ui-react';

import SallePhotoList from './SallePhotoList';
import SalleDescriptionPictos from './SalleDescriptionPictos';
import { ReactComponent as TelechargerSvg } from '../../assets/telecharger.svg';

import {
    downloadDocuments,
    getAllDocumentsSalles,
} from '../../store/actions/docInfoAction';
import {
    COULEUR_FOND_VIOLET,
    COULEUR_BLEU_CLAIR,
} from '../../config/colors';
import { URL_API } from '../../config/url_api';

const SalleDescription = (props) => {
    const {
        identifiant,
        identifiantEntite,
        configDatabase,
        documentsList,
        download,
        downloadDocuments,
        getAllDocuments,
        nom,
        description,
        heureDebut,
        heureFin,
        placesTotales,
        placesAssises,
        superficie,
    } = props;

    const [downloadLoading, setDownloadLoading] = React.useState(false);
    const [photos, setPhotos] = React.useState([]);

    const heureDebutFormatted = _.isEmpty(heureDebut)
        ? ''
        : `${heureDebut.substr(0, 2)}h${heureDebut.substr(2, 2)}`

    const heureFinFormatted = _.isEmpty(heureFin)
        ? ''
        : `${heureFin.substr(0, 2)}h${heureFin.substr(2, 2)}`

    const handleDescriptifDownload = () => {
        setDownloadLoading(true);

        downloadDocuments(identifiantEntite, identifiant, configDatabase).finally(() => {
            setDownloadLoading(false);
        })
    }

    React.useEffect(() => {
        if (!_.isNil(identifiant) && identifiant > 0) {
            Axios.get(URL_API + '/api/salles/'+identifiant+'/images?identifiantEntite='+identifiantEntite)
            .then((data) => {
                setPhotos(data?.data);
            });
        }
    }, [identifiant, configDatabase, identifiantEntite]);

    React.useEffect(() => {
        if (download) {
            getAllDocuments(identifiantEntite, identifiant, 'DOCSAL');
        }
    }, [
        download,
        getAllDocuments,
        identifiant,
        identifiantEntite
    ]);

    const shouldDisplayDownload = download && !_.isEmpty(
        _.filter(
            documentsList,
            ({ identifiantEnregistrementOrigine }) => identifiantEnregistrementOrigine === identifiant
    ));

    const hasNoData = _.isEmpty(description)
        && _.isEmpty(heureDebutFormatted)
        && _.isEmpty(heureFinFormatted)
        && (_.isNil(superficie) || superficie === 0)
        && (_.isNil(placesAssises) || placesAssises === 0)
        && (_.isNil(placesTotales) || placesTotales === 0)

    if (hasNoData && shouldDisplayDownload && _.isEmpty(photos)) {
        return (
            <Grid>
                <Grid.Row style={{ paddingRight: 10 }}>
                    <Grid.Column width={12}>
                        <Grid>
                            {nom && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Header as='h2' style={{ color: COULEUR_BLEU_CLAIR }}>
                                            {nom}
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <Grid.Row>
                                <Grid.Column textAlign='justified'>
                                    <Header as='h4' style={{ color: COULEUR_FOND_VIOLET }}>
                                        Aucune information n'a été renseigné pour cette salle
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Dimmer.Dimmable
                        as={Grid.Column}
                        textAlign='center'
                        width={4}
                        style={{ cursor: 'pointer' }}
                        onClick={handleDescriptifDownload}
                    >
                        <Dimmer inverted active={downloadLoading}>
                            <Loader />
                        </Dimmer>
                        <div>
                            <TelechargerSvg
                                fill={`${COULEUR_FOND_VIOLET}`}
                            />
                            <Header
                                as='h4'
                                style={{
                                    margin: 0,
                                    color: COULEUR_FOND_VIOLET
                                }}
                            >
                                Télécharger le descriptif complet
                            </Header>
                        </div>
                    </Dimmer.Dimmable>
                </Grid.Row>
            </Grid>
        )
    }

    return (
        <Grid>
            <Grid.Row
                style={{
                    ...!_.isEmpty(photos) && { height: 165 },
                    paddingRight: 10
                }}
            >
                <Grid.Column width={_.isEmpty(photos) ? 16 : 12}>
                    <Grid>
                        {nom && (
                            <Grid.Row>
                                <Grid.Column>
                                    <Header as='h2' style={{ color: COULEUR_BLEU_CLAIR }}>
                                        {nom}
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column textAlign='justified'>
                                <Header as='h4' style={{ color: COULEUR_FOND_VIOLET }}>
                                    {hasNoData
                                        ? "Aucune information n'a été renseigné pour cette salle"
                                        :_.isEmpty(description)
                                            ? 'Aucun descriptif disponible'
                                            : 'Descriptif de la salle'
                                    }
                                    {description && (
                                        <Header.Subheader style={{ color: COULEUR_FOND_VIOLET }}>
                                            {description}
                                        </Header.Subheader>
                                    )}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                {!_.isEmpty(photos) && (
                    <Grid.Column width={4} textAlign='center'>
                        <SallePhotoList photos={photos} />
                    </Grid.Column>
                )}
            </Grid.Row>
            {!hasNoData && (
                <Grid.Row textAlign='center' style={{ paddingRight: 10 }}>
                    <Grid.Column
                        width={(shouldDisplayDownload || downloadLoading) ? 12 : 16}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center'
                        }}
                    >
                        <SalleDescriptionPictos
                            superficie={superficie}
                            heureDebut={heureDebutFormatted}
                            heureFin={heureFinFormatted}
                            placesAssises={placesAssises}
                            placesTotales={placesTotales}
                        />
                    </Grid.Column>
                    {shouldDisplayDownload
                        ? (
                            <Dimmer.Dimmable
                                as={Grid.Column}
                                width={4}
                                style={{ cursor: 'pointer' }}
                                onClick={handleDescriptifDownload}
                            >
                                <Dimmer inverted active={downloadLoading}>
                                    <Loader />
                                </Dimmer>
                                <div>
                                    <TelechargerSvg
                                        fill={`${COULEUR_FOND_VIOLET}`}
                                    />
                                    <Header
                                        as='h4'
                                        style={{
                                            margin: 0,
                                            color: COULEUR_FOND_VIOLET
                                        }}
                                    >
                                        Télécharger le descriptif complet
                                    </Header>
                                </div>
                            </Dimmer.Dimmable>
                        ) : (<Grid.Column width={4} />)
                    }
                </Grid.Row>
            )}
        </Grid>
    );
};

SalleDescription.propTypes = {
    identifiant: Types.number,
    nom: Types.string,
    download: Types.bool,
    downloadDocuments: Types.func,
    description: Types.string,
    getAllDocuments: Types.func,
    placesTotales: Types.number,
    placesAssises: Types.number,
    superficie: Types.number,
}

SalleDescription.defaultProps = {
    identifiant: null,
    nom: null,
    download: true,
    downloadDocuments: null,
    description: null,
    getAllDocuments: null,
    placesTotales: null,
    placesAssises: null,
    superficie: null,
};

export default connect(
    (state) => ({
        documentsList: state.docInfoReducer?.documents,
    }),
    (dispatch) => bindActionCreators({
        getAllDocuments: getAllDocumentsSalles,
        downloadDocuments: downloadDocuments,
    }, dispatch),
)(SalleDescription)