import Axios from 'axios';
import {
    GET_ALL_SALLES,
    GET_ARRAY_SALLE_BY_ID,
    GET_SALLE_BY_ID,
    GET_SALLES_DISPONIBLES,
    GET_SALLES_TYPE_RESERVATION,
} from '../constants/actionsTypes';
import { URL_API } from '../../config/url_api';

export const getAllSalles = (idEntite) => (dispatch) => {
    Axios.get(URL_API + '/api/salles/publication?perPage=50&idEntite='+idEntite)
    .then((data) => {
        dispatch({
            type: GET_ALL_SALLES,
            sallesList: data,
        });
    });
};

export const getArraySalleById = (idSallesList) => (dispatch) => {
    Axios.get(`${URL_API}/api/salles/search?identifiant=${idSallesList}&perPage=50`)
    .then(({ data }) => {
        dispatch({
            type: GET_ARRAY_SALLE_BY_ID,
            sallesList: data,
        })
    })
}

export const getSalleById = (id) => (dispatch) => {
    Axios.get(URL_API + '/api/salles/' + id).then(({ data }) => {
        dispatch({
            type: GET_SALLE_BY_ID,
            salleById: data,
        })
    })
}

export const getSallesDisponibles = (
    identifiantsSalles,
    dateDebut,
    dateFin,
    typeSalle,
    typeReservant,
    activite,
    nbPersonnes,
    nbPlacesAssises,
    nbPlacesDebouts,
    idEntite,
) => async (dispatch) => {    
    const data = await Axios.get(
        URL_API + '/api/salles/getSallesDisponibles?'
        + 'identifiantsSalles=' + identifiantsSalles + '&'
        + 'dateDebut=' + dateDebut + '&'
        + 'dateFin=' + dateFin + '&'
        + 'typeSalle=' + typeSalle + '&'
        + 'typeReservant=' + typeReservant + '&'
        + 'activite=' + activite + '&'
        + 'nbPersonnes=' + nbPersonnes + '&'
        + 'nbPlacesAssises=' + nbPlacesAssises + '&'
        + 'nbPlacesDebouts=' + nbPlacesDebouts + '&'
        + 'idEntite=' + idEntite 
    );

    dispatch({
        type: GET_SALLES_DISPONIBLES,
        sallesDisponibles: data?.data
    });

    return data?.data;
}

export const getListSalleTypesReservations = (listIdentifiantsSalles) => (dispatch) => {
    Axios.get(
        `${URL_API}/api/typeReservationSalles/search?perPage=200&identifiantSalles=${listIdentifiantsSalles}`,
    ).then(({ data }) => {
        dispatch({
            type: GET_SALLES_TYPE_RESERVATION,
            sallesTypesReservationsList: data.data,
        });
    }
    );
}