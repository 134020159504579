import _ from 'lodash';
import React from 'react';

import Types from '360-types';

import {
    Dropdown,
    Form,
    Icon,
} from 'semantic-ui-react';

import { isSameDay } from 'react-dates';

const hours = _.times(24, (t) => ({
    key: t,
    value: t,
    text: t,
}));

const minutes = [
    {
        key: 'minute0',
        value: 0,
        text: '00',
    },
    {
        key: 'minute15',
        value: 15,
        text: '15',
    },
    {
        key: 'minute30',
        value: 30,
        text: '30',
    },
    {
        key: 'minute45',
        value: 45,
        text: '45',
    },
];

const HourPicker = (props) => {
    const {
        date,
        dateMin,
        dateMax,
        title,
        onChange,
    } = props;

    const [availableHours, setAvailableHours] = React.useState(hours);
    const [availableMinutes, setAvailableMinutes] = React.useState(minutes);

    const handleHourChange = (e, { name, value }) => {
        if (onChange) {
            onChange({ name, value })
        }
    }

    React.useEffect(() => {
        let optionsHours = [...hours];
        let optionsMinutes = [...minutes];

        // on retire les heures avant l'heure de dateMin
        if (!_.isNil(dateMin) && isSameDay(date, dateMin)) {
            const minHour = dateMin.hour();

            // si c'est une heure d'avant ou que c'est la même heure MAIS que le créneau de 45 est sélectionné
            // on retire l'option, car pas d'autres créneau sur l'heure
            // exemple : si dateMin = 12h45, on veut pas donner la possibilité de "12" heures car pas de créneau entre 12h45 et 13h00
            optionsHours = _.filter(optionsHours, ({ value }) => value > minHour || (value === dateMin.hour() && dateMin.minute() < 45));

            // si même heure on retire les créneaux d'avant
            if (minHour === date.hour()) {
                // même principe que pour les heures
                optionsMinutes = _.filter(optionsMinutes, ({ value }) => value > dateMin.minute())
            }
        }

        // on retire les heures après l'heure de dateMax
        if (!_.isNil(dateMax) && isSameDay(date, dateMax)) {
            const maxHour = dateMax.hour();

            optionsHours = _.filter(optionsHours, ({ value }) => value < maxHour || (value === dateMax.hour() && dateMax.minute() > 0));

            // si même heure on retire les créneaux d'après
            if (maxHour === date.hour()) {
                optionsMinutes = _.filter(optionsMinutes, ({ value }) => value < dateMax.minute())
            }
        }

        setAvailableHours(optionsHours);
        setAvailableMinutes(optionsMinutes);
    }, [date, dateMin, dateMax]);

    const commonProps = {
        disabled: _.isNil(date),
        icon: null,
        inline: true,
        scrolling: true,
    }

    return (
        <Form.Field className='HourPicker'>
            <label style={{ display: 'block', fontSize: 18 }}>{title}</label>
            <div className='Hour'>
                <Icon
                    name='clock outline'
                    size='large'
                    style={{ marginRight: 10 }}
                />
                <Dropdown
                    name='hour'
                    options={availableHours}
                    onChange={handleHourChange}
                    value={_.isNil(date) ? 0 : date.get('hour')}
                    {...commonProps}
                />
                <label> : </label>
                <Dropdown
                    name='minute'
                    options={availableMinutes}
                    onChange={handleHourChange}
                    value={_.isNil(date) ? 0 : date.get('minute')}
                    {...commonProps}
                />
            </div>
        </Form.Field>
    );
};

HourPicker.propTypes = {
    /** Moment object to handle hour / minute. */
    date: Types.object,

    /** Moment object. */
    dateMin: Types.object,

    /** Moment object. */
    dateMax: Types.object,

    /** Title to display above. */
    title: Types.string,

    /** */
    onChange: Types.func,
}

export default HourPicker;
