import _ from 'lodash';
import React from 'react';

import { Grid } from 'semantic-ui-react';

import { ReactComponent as CalendrierSvg } from '../../assets/calendrier.svg';

const ModalDisponibiliteResultsCriteres = (props) => {
    const {
        activite,
        capacite,
        debut,
        fin,
        placesAssises,
        placesDebouts,
        typeReservant,
        typeSalle,
    } = props;
    const { webdev } = window;

    const criteres = [
        (
            <Grid.Column width={6}>
                <label>Type de réservant : </label>
                <label className='value'>{!_.isNil(typeReservant) ? typeReservant.libelle : 'Tout type de réservant'}</label>
            </Grid.Column>
        ), (
            <Grid.Column width={10}>
                <label>Activité : </label>
                <label className='value'>{!_.isNil(activite) ? activite.libelle : 'Toutes les activités'}</label>
            </Grid.Column>
        ), (
            <Grid.Column width={6}>
                <label>Type de salle : </label>
                <label className='value'>{!_.isNil(typeSalle) ? typeSalle.libelle : 'Toutes les salles'}</label>
            </Grid.Column>
        )
    ];

    if (!_.isNil(capacite)) {
        criteres.push((
            <Grid.Column width={3}>
                <label>Capacité : </label>
                <label className='value'>{capacite}</label>
            </Grid.Column>
        ))
    }

    if (!_.isNil(placesAssises)) {
        criteres.push((
            <Grid.Column width={3}>
                <label>{placesAssises > 1 ? 'Places assises :' : 'Place assise :'} </label>
                <label className='value'>{placesAssises}</label>
            </Grid.Column>
        ))
    }

    if (!_.isNil(placesDebouts)) {
        criteres.push((
            <Grid.Column width={4}>
                <label>{placesDebouts > 1 ? 'Places debouts :' : 'Place debout :'} </label>
                <label className='value'>{placesDebouts}</label>
            </Grid.Column>
        ))
    }

    const nombreCriteres = _.size(criteres);

    return (
        <Grid className="resume compact">
            <Grid.Row style={{ alignItems: 'flex-end', paddingBottom: '0.25rem' }}>
                <Grid.Column style={{ width: 'auto' }}>
                    <label>
                        <CalendrierSvg />
                        {webdev
                            ? 'Période de réservation :'
                            : 'Période de pré-réservation :'
                        }                        
                    </label>
                </Grid.Column>
                <Grid.Column style={{ width: 'auto' }}>
                    <label>du </label>
                    <label className='value'>{debut ? debut.format('LL') : "-"}</label>
                    <label> - </label>
                    <label className='value'>{debut ? debut.format('LT') : "-"}</label>
                </Grid.Column>
                <Grid.Column style={{ width: 'auto' }}>
                    <label>au </label>
                    <label className='value'>{debut ? fin.format('LL') : "-"}</label>
                    <label> - </label>
                    <label className='value'>{fin ? fin.format('LT') : "-"}</label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row
                style={{
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem'
                }}
            >
                {criteres[0]}
                {criteres[1]}
            </Grid.Row>
            <Grid.Row 
                style={{
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem'
                }}
            >
                {criteres[2]}
                {nombreCriteres > 3 && criteres[3]}
                {nombreCriteres > 4 && criteres[4]}
                {nombreCriteres > 5 && criteres[5]}
            </Grid.Row>
        </Grid>
    );
};

export default ModalDisponibiliteResultsCriteres;