import React from 'react';

import {
    Row,
} from 'antd';
import {
    Grid,
    Header,
    Icon,
} from 'semantic-ui-react';

import Popup from './Popup/Popup';
import {
    COULEUR_BLEU_CLAIR,
    COULEUR_FOND_VIOLET,
    COULEUR_INDISPONIBILITE,
} from '../config/colors';

const Legend = () => {
    const { webdev } = window;

    const buildOption = (texte, color, striped) => {
        if (webdev) {
            return (
                <Grid.Column>
                    <div
                        style={{
                            display: 'flex',
                            float: 'left',
                            height: '2rem',
                            width: '2rem',
                            marginRight: '0.5rem',
                            background: striped ?
                                `-webkit-repeating-linear-gradient(135deg,  
                                    ${color},
                                    ${color} 4.5px,
                                    #FFFFFF 4.5px, 
                                    #FFFFFF 9px`
                                : `${color}`,
                        }}
                    />
                    <div
                        style={{
                            fontWeight: 'bold',
                            color: `${COULEUR_FOND_VIOLET}`,
                        }}
                    >
                        {texte}
                    </div>
                </Grid.Column>
            );
        }

        return (
            <Row
                align='middle'
                justify='space-around'
                style={{
                    marginRight: '1rem',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        float: 'left',
                        height: '2rem',
                        width: '2rem',
                        marginRight: '0.5rem',
                        background: striped ?
                            `-webkit-repeating-linear-gradient(135deg,  
                                ${color},
                                ${color} 4.5px,
                                #FFFFFF 4.5px, 
                                #FFFFFF 9px`
                            : `${color}`,
                    }}
                />
                <div
                    style={{
                        fontWeight: 'bold',
                        color: `${COULEUR_FOND_VIOLET}`,
                    }}
                >
                    {texte}
                </div>
            </Row>
        );
    };

    const optionIndisponible = buildOption(
        'Indisponible',
        COULEUR_INDISPONIBILITE,
        false,
    );
    const optionReserve = buildOption(
        'Réservé',
        COULEUR_BLEU_CLAIR,
        false,
    );
    const optionPartiellementDisponible = buildOption(
        'Créneau partiellement disponible',
        COULEUR_BLEU_CLAIR,
        true,
    );

    if (!webdev) {
        return (
            <Row
                justify='end'
            >
                {optionIndisponible}
                {optionReserve}
                {optionPartiellementDisponible}
            </Row>
        );    
    }

    return (
        <Popup
            hoverable
            trigger={(
                <Header as='h3' floated='right' style={{ color: "#5270a9" }}>
                    <Icon name='help circle' />
                </Header>
            )}
            content={(
                <Grid style={{ width: 300 }}>
                    <Grid.Row>
                        {optionIndisponible}
                    </Grid.Row>
                    <Grid.Row style={{ padding: 0 }}>
                        {optionReserve} 
                    </Grid.Row>
                    <Grid.Row>
                        {optionPartiellementDisponible}
                    </Grid.Row>
                </Grid>
            )}
            position='left center'
        />
    );
};

export default Legend;