import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import Types from '360-types';

import {
    DayPickerRangeController,
    isInclusivelyAfterDay,
    isInclusivelyBeforeDay,
    isSameDay,
} from 'react-dates';

import CalendarNavIcon from './CalendarNavIcon';
import CalendarDateSelector from './CalendarDateSelector';

const Calendar =(props) => {
    const {
        dateDebut,
        dateFin,
        focusedInput,
        onChange,
    } = props;

    const [debut, setDebut] = React.useState(dateDebut);
    const [fin, setFin] = React.useState(dateFin);

    const handleChange = (data) => {
        const {
            startDate,
            endDate
        } = data;

        if (focusedInput === 'startDate') {
            setDebut(startDate);

            if (onChange) {
                onChange(startDate);
            }
        } else {
            setFin(endDate);

            if (onChange) {
                onChange(endDate);
            }
        }

    }

    // Determine si le jour donné en paramètre doit être grisé ou pas
    // /!\ la fonction est appelé des centaines de fois par render,
    // attention à pas faire de calcul trop couteux
    const isOutsideRange = (day) => {
        if (focusedInput === 'startDate') {
            if (!_.isNil(fin)) {
                const finFirstCreneauOfDay = isSameDay(day, moment(fin)) && fin.hour() === 0 && fin.minute() === 0;
    
                return !isInclusivelyAfterDay(day, moment()) || finFirstCreneauOfDay;
            }
        }

        if (focusedInput === 'endDate') {
            if (!_.isNil(debut)) {
                const debutLastCreneauOfDay = isSameDay(day, moment(debut)) && debut.hour() === 23 && debut.minute() === 45;

                return isInclusivelyBeforeDay(day, moment(debut).subtract(1, 'day')) || debutLastCreneauOfDay;
            }
        }

        return !isInclusivelyAfterDay(day, moment())
    };

    React.useEffect(() => { setDebut(dateDebut) }, [dateDebut]);
    React.useEffect(() => { setFin(dateFin) }, [dateFin]);
    
    return (
        <DayPickerRangeController
            numberOfMonths={2}
            transitionDuration={0}
            hideKeyboardShortcutsPanel
            noBorder
            navPrev={<CalendarNavIcon direction='left' />}
            navNext={<CalendarNavIcon direction='right' />}
            isOutsideRange={isOutsideRange}
            renderMonthElement={CalendarDateSelector}
            minimumNights={0}
            focusedInput={focusedInput}
            onDatesChange={handleChange}
            startDate={debut}
            endDate={fin}
        />
    );
};

Calendar.propTypes = {
    /** Moment object */
    dateDebut: Types.object,

    /** Moment object */
    dateFin: Types.object,

    /** Which one of the start or end field is focused. */
    focusedInput: Types.oneOf(['startDate', 'endDate']),

    /** */
    onChange: Types.func,
}

Calendar.defaultProps = {
    focusedInput: 'startDate'
}

export default Calendar;
